import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Loginimage from "../src/images/login.png";
import Logo from "../src/images/MCRC_logo.png";
import ViewIcon from "../src/images/loginViewIcon.png";
import CloseEyeIcon from "../src/images/loginCloseIcon.png";
import ProductMaster from "./Pages/ProductMaster";
import axios from "axios";
import { LOGIN, forgetPassword } from "../src/Utilities/URLCONSTANTS.js";
import { MOBILE_NUMBER, USER_PROFILE, retrieveData, storeData } from "./Utils";
import { json, useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { Button, Modal } from "react-bootstrap";
import Loader from "./Utilities/Loader.js";
import { GetApiHeaders } from "./Utilities/NetworkUtilities.js";

const Login = () => {
  const nav = useNavigate();

  // ReactSession.setStoreType("localStorage");
  const [loaderImage, setLoaderImage] = useState(
    require("./images/cs_loader.gif")
  );
  const [mobileOrEmail, setmobileOrEmail] = useState();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState("");
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showMsg, setShowMsg] = useState("false");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMobileNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    const limitedInput = input.slice(0, 10);
    const validInput = /^[6-9]/.test(limitedInput) ? limitedInput : "";
    setmobileOrEmail(validInput);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const WithStaticData = (e) => {
    const validUsername = "9848123456";
    const validPassword = "empover1";
    if (mobileOrEmail == validUsername && password == validPassword) {
      console.log(mobileOrEmail, password);
      storeData(MOBILE_NUMBER, validUsername);
      setmobileOrEmail("");
      setPassword("");
      setError("");
      setLoggedIn(true);
    } else {
      setError("Invalid Mobile Number or password");
    }
  };
  const withAPIData = async (e) => {
    console.log(mobileOrEmail, password);
    try {
      const apiUrl = LOGIN;
      const dataToSend = {
        userID: mobileOrEmail, //"9542489540",// 7732097657
        password: Base64.encode(password), //Base64.encode("Empover@Admin123")//password//"RW1wb3ZlckBBZG1pbjEyMw=="
      };
      const response = await axios.post(apiUrl, dataToSend);
      console.log("responseapi", response);
      localStorage.setItem("userRole", response.data.response);
      if (response.data.statusCode == 200) {
        if (response.data.response != null) {
          if (
            response.data.response.mobileNumber != null &&
            response.data.response.mobileNumber != ""
          ) {
            sessionStorage.setItem(
              "MOBILE_NUMBER",
              response.data.response.mobileNumber
            );
            sessionStorage.setItem(
              "USER_PROFILE",
              JSON.stringify(response.data.response)
            );
          }
          console.log("response:", mobileOrEmail, password);
          setmobileOrEmail("");
          setPassword("");
          setError("");
          setLoggedIn(true);
        } else {
          setError("No Data Available!");
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mobileNumberPattern =
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([6-9]\d{9})$/;
    const passwordPattern = /^(?=.*[a-zA-Z0-9]).{8,}$/;

    if (!mobileOrEmail) {
      setError("Please Enter Mobile Number");
      return;
    }

    if (mobileOrEmail.length !== 10) {
      setError("Please Enter 10 Digit Mobile Number");
      return;
    }

    if (!mobileNumberPattern.test(mobileOrEmail)) {
      setError("Invalid Mobile Number");
      return;
    }

    if (!password) {
      setError("Please Enter password");
      return;
    }

    if (password.length < 8) {
      setError("Password Should be 8 characters");
      return;
    }

    if (!passwordPattern.test(password)) {
      setError("Invalid Password");
      return;
    }

    // If all conditions pass, call the API function
    withAPIData();
  };

  if (loggedIn) {
    window.location.href = "/popup";
  }
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleModalOpen = (item, index) => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setShowPopup(false);
  };
  const handleMobileNbr = (e) => {
    const Input = e.target.value;
    console.log(Input);
    if (Input.length > 0) {
      setErrorMobileNumber("");
    }
    let result = Input.replace(/[^0-9]/g, ""); 

    if (result.length > 10) {
      result = result.substr(0, 10);
    }

    if (/^[6-9]/.test(result)) {
      setMobileNumber(result);
    } else {
      setMobileNumber(""); 
    }
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleForgetPassword = async () => {
    const forgetApi = forgetPassword;
    const postData = {
      mobileNumber: mobileNumber,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userId: "",
      mobileNumber: mobileNumber,
      deviceType: "web",
      deviceId: "",
    };
    try {
      setIsLoading(true);
      const response = await axios.post(forgetApi, postData, { headers });
      console.log(response, "fpassword");
      setsubmitResponse(response.data.message);
      setShowModal(false);
      setShowResponsePopup(true);
      setIsLoading(false);
      setIsSubmitted(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    window.history.pushState(null, null);
    const handleBackButton = (event) => {
      event.preventDefault();
      return false;
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
  return (
    <body className="bgImage">
      <div className="container">
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className=" row justify-content-center ">
            <div className="col-md-10 col-12">
              <div className=" card shadow-md login_card">
                <div className="card-body">
                  <div className="row mt-md-3 mt-0">
                    <div className="col-lg-6 m-auto">
                      <div className="w-100 border-r-2-md mb-5">
                        <img
                          src={Loginimage}
                          alt="LoginImage"
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <div className="d-flex justify-content-center align-items-center">
                          <img src={Logo} alt="MCRC_logo" className="logo" />
                        </div>
                        <span
                          className="font-weight-600 d-flex align-items-center justify-content-center py-3"
                          style={{ fontSize: 16 }}
                        ></span>

                        <form className="px-md-4 px-4" onSubmit={handleSubmit}>
                          {error && (
                            <div className="alert alert-danger">{error}</div>
                          )}
                          <div className="form-group">
                            <label
                              for="exampleInputEmail1"
                              className="text-sm font-weight-600"
                            >
                              Mobile Number{" "}
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">+91</span>
                              </div>
                              <input
                                type="tel"
                                className="form-control"
                                id=""
                                aria-describedby="Mobile Number"
                                placeholder="Mobile Number"
                                value={mobileOrEmail}
                                onChange={handleMobileNumberChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              for="exampleInputPassword1"
                              className="text-sm font-weight-600"
                            >
                              Password
                            </label>
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id=" "
                              placeholder="Password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <span>
                              {showPassword ? (
                                <img
                                  src={ViewIcon}
                                  onClick={handleTogglePassword}
                                  alt="closeEyeIcon"
                                  className="passIcon "
                                  style={{ width: 27, height: 13 }}
                                />
                              ) : (
                                <img
                                  src={CloseEyeIcon}
                                  onClick={handleTogglePassword}
                                  alt="viewIcon"
                                  className="passIcon"
                                  style={{ width: 27, height: 14 }}
                                />
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label className="font-weight-bold">
                              <input
                                type="checkbox"
                                className="leading-loose text-pink-600"
                              />
                              <span className="text-sm ml-1">Remember Me </span>
                            </label>
                            <label className="">
                              <span
                                className="font-weight-600 text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModalOpen()}
                              >
                                Forgot Password?
                              </span>
                            </label>
                          </div>
                          <button
                            type="submit"
                            style={{ height: 45 }}
                            className="btn btn-primary w-100 sign_in_button mt-3"
                          >
                            Sign In
                          </button>
                        </form>
                        <div className="mt-5"></div>
                      </div>
                      <div>
                        <Modal
                          show={showModal}
                          onHide={handleModalClose}
                          centered
                          backdrop="static"
                          keyboard={false}
                          size="md"
                        >
                          <Modal.Header className="border-0 d-flex justify-content-center">
                            <Modal.Title> </Modal.Title>
                            <Button
                              variant="link"
                              className="close btn btn-danger close_btn"
                              onClick={handleClose}
                            >
                              X
                            </Button>
                          </Modal.Header>
                          <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                            <div className="d-flex justify-content-center mb-3 font-weight-600">
                              <span className="modal_title">
                                {" "}
                                Forgot Password{" "}
                              </span>
                            </div>
                            <div>
                              <label
                                for="exampleInputEmail1"
                                className="text-sm font-weight-600"
                              >
                                Registered Mobile Number
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <div className="input-group input-group w-100">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">+91</span>
                                </div>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id=""
                                  aria-describedby="Mobile Number"
                                  placeholder="Mobile Number"
                                  value={mobileNumber}
                                  onChange={handleMobileNbr}
                                />
                              </div>

                              <br />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary"
                                className="btnn"
                                onClick={() => handleForgetPassword()}
                              >
                                {" "}
                                Submit
                              </Button>
                              <div style={{ width: "20px" }}></div>
                              <Button
                                variant="danger"
                                className="btnn"
                                onClick={handleClose}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal.Body>
                          {isLoading && (
                            <Loader
                              loading={isLoading}
                              message={"Fetching Data. Please Wait..!"}
                              loderimage={loaderImage}
                            />
                          )}
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          show={showResponsePopup}
                          centered
                          backdrop="static"
                          keyboard={false}
                          size="ml"
                        >
                          <Modal.Header className="border-0 d-flex justify-content-center">
                            <Modal.Title>
                              {" "}
                              <span className="modal_title">
                                Forgot Password
                              </span>
                            </Modal.Title>
                            <Button
                              variant="link"
                              className="close btn btn-danger close_btn"
                              onClick={closeWarning}
                            >
                              X
                            </Button>
                          </Modal.Header>
                          <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                            <div className="d-flex justify-content-center mb-3 font-weight-600">
                              {showMsg ? (
                                <p style={{ color: "green" }}>
                                  {submitResponse}
                                </p>
                              ) : null}
                            </div>
                            <div className="d-flex justify-content-center">
                              <Button
                                variant="primary"
                                className="d-flex justify-content-center cancel_button"
                                onClick={handleresponse}
                              >
                                Okay
                              </Button>
                              &nbsp;&nbsp;
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Login;
