import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Header from "./Header";
import Logout from "./Logout";
import { strings } from "../Utilities/strings";
import Loader from "../Utilities/Loader";
import * as XLSX from "xlsx";
import "@fortawesome/fontawesome-free/css/all.min.css";


// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';

// for Sidebar And Navbar
import Search from "../images/search.png";
import { Link } from "react-router-dom";
import Logo from "../images/corteva-logo.png";
import Menu from "../images/Menu.png";
import CentralLogo from "../images/CentralLogo.png";
import FarmLogo from "../images/FarmLogo.png";
import Tracking from "../images/Tracking.png";
import Notification from "../images/notification.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
//import UnitMeasureMaster from './Pages/UnitMeasureMaster';
//import CategoryMaster from './CategoryMaster';
import { useContext } from "react";
import { CategoryContext } from "./CategoryContext";
import Login from "../Login";
import {
  addProduct,
  getCategory,
  getProducts,
  getUnitMeasureMaster,
} from "../Utilities/URLCONSTANTS";
//  import { useSession } from 'react-client-session';
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
//ProductMaster
const ProductMaster = ({ }) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  //  const  categories = CategoryMaster;
  const [id, setId] = useState("");
  const [productMake, setProductMake] = useState("");
  const [productModel, setProductModel] = useState("");
  const [name, setName] = useState("");
  const [minimumStockShouldBe, setminimumStockShouldBe] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categorydropdowndata, setcategorydropdowndata] = useState([]);
  const [code, setCode] = useState("");
  const [uim, setUim] = useState("");
  const [unitdropdowndata, setunitdropdowndata] = useState([]);
  const [uimName, setUimName] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [Errorcode, setErrorcode] = useState("");
  const [Errorname, setErrorname] = useState("");
  const [errorProductMake, setErrorProductMake] = useState("");
  const [erorUimCost, setErorUimCost] = useState("");
  const [errorProductModel, setErrorProductModel] = useState("");
  const [errorproductModel, setproductModel] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [errorminimumStockShouldBe, setErrorminimumStockShouldBe] =
    useState("");
  const [errorcategoryID, setErrorcategoryID] = useState("");
  const [erroruim, setErroruim] = useState("");
  const [statusArray, setStatusArray] = useState([
    { value: "", name: "Select Status" },
    { value: "1", name: "Active" },
    { value: "0", name: "In-Active" },
  ]);

  const [buttonText, setButtonText] = useState("Submit");
  //const [sno, setSno] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Product Mater"
  );
  // const { session, setSession } = useSession();
  const [showMsg, setShowMsg] = useState("false");
  ///modal popup
  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState({
    id: "",
    categoryID: "",
    code: "",
    name: "",
    uim: "",
    unitCost: "",
    minimumStockShouldBe: "",
    status: "",
    productModel: "",
    productMake: "",
  });

  const [data, setData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState("");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.productCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productCategoryName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.productMake.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productModel.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.uim.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.unitCost
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.minimumStockShouldBe
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const pageNumbers = [];
  //
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "product Code",
        "Name",
        "product Make",
        "product Model",
        "product Category Name",
        "uim Name",
        "unit Cost",
        "minimumStockShouldBe",
        "Status",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        // item.id,
        index + 1,
        item.productCode,
        item.name,
        item.productMake,
        item.productModel,
        item.productCategoryName,
        item.uimName,
        item.unitCost,
        item.minimumStockShouldBe,
        item.status === "1" ? "Active" : "InActive",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "ProductMaster.xlsx");
  };

  const handleCancel = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(false);
  };
  const handleClose = () => {
    //yes
    setShowPopup(false);
    setShowModal(false);
  };

  // ...more items
  const handleNameChange = (e) => {
    const input = e.target.value;
    // const result = input.replace(/[^a-z_\s]/gi, "");
    setName(input);
    if (input) {
      setErrorname("");
    }
  };
  const handleproductMakeChange = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setProductMake(result);
    if (result) {
      setErrorProductMake("");
    }
  };
  const handleproductModelChange = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setProductModel(result);
    if (result) {
      setErrorProductModel("");
    }
  };
  const handleCodeChange = (e) => {
    const inputCode = e.target.value;
    setCode(inputCode);
    if (inputCode) {
      setErrorcode("");
    }
  };
  const handleCategoryIdChange = (e) => {
    const inputCategory = e.target.value.replace(/\D/g, "");
    setCategoryID(inputCategory);
    if (inputCategory) {
      setErrorcategoryID("");
    }
  };
  const handleuimChange = (e) => {
    setUim(e.target.value);
    if (e.target.value) {
      setErroruim("");
    }
  };

  const handleunitCostChange = (e) => {
    const inputunitCost = e.target.value;

    // Check if the input is a valid number and not negative
    if (/^\d*$/.test(inputunitCost)) {
      setUnitCost(inputunitCost);
      setErorUimCost(""); // Clear error message if valid
    } else {
      setErorUimCost("Not Allowed Negative Numbers "); // Set error for invalid input
    }
  };

  // const handleStatus = (e) => {
  //   alert(JSON.stringify(e.target));
  //   setStatus(e.target.value);
  //   if (status.length > 0) {
  //     setErrorStatus('')
  //     alert("status.length",status.length);
  //   }
  //   alert("status.length:::",status.length);
  // }
  const handleStatus = (e) => {
    const inputuim = e.target.value;
    //alert("inputuim::"+inputuim);
    if (!isNaN(inputuim)) {
      setStatus(inputuim);
    }
    if (inputuim) {
      setErrorStatus("");
    }
  };
  const handleminimumStockShouldBeChange = (e) => {
    setminimumStockShouldBe(e.target.value);
    if (e.target.value) {
      setErrorminimumStockShouldBe("");
    }
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleSubmit = async (modalData) => {
    if (code === "") {
      setErrorcode("Please Enter  Code");
    }
    if (name === "") {
      setErrorname("Please Enter aName");
    }
    if (productMake === "") {
      setErrorProductMake("Please Enter  Product Make");
    }
    if (productModel === "") {
      setErrorProductModel("Please Enter  Product Model");
    }
    if (minimumStockShouldBe === "") {
      setErrorminimumStockShouldBe("Please Enter  Minimum Stock ");
    }
    if (categoryID === "") {
      setErrorcategoryID("Please Select category Name");
    }
    if (uim === "") {
      setErroruim("Please Select UIM");
    }
    if (unitCost === "") {
      setErorUimCost("Please Enter Unit Cost");
    }
    if (status === "") {
      setErrorStatus("Please Select status");
    }
    try {
      console.log(JSON.stringify(modalData));
      // Destructure the properties from the item object

      // Check if required fields are not empty
      if (
        code !== "" &&
        name !== "" &&
        productMake !== "" &&
        productModel !== "" &&
        minimumStockShouldBe !== "" &&
        categoryID !== "" &&
        uim !== "" &&
        status !== ""
      ) {
        const apiUrl = addProduct;
        const headers = await GetApiHeaders();
        const dataToSend = {
          Id: { buttonText } == "Submit" ? "" : id.toString(),
          categoryID: categoryID.toString(),
          code: code,
          name: name,
          productMake: productMake,
          productModel: productModel,
          uim: uim.toString(),
          unitCost: unitCost.toString(),
          minimumStockShouldBe: minimumStockShouldBe.toString(),
          status: status,
        };
        // const headers = {
        //   "Content-Type": "application/json",
        //   "userId": "1",
        //   "mobileNumber": "9704164746",
        // };
        // Make the POST request using Axios with headers
        setIsLoading(true);
        const response = await axios.post(apiUrl, dataToSend, { headers });
        setsubmitResponse(response.data.response);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);

        // Handle the response data as needed
        console.log("Response:", response.data);

        // Proceed with data insertion if the API call was successful
        const newId = String(Date.now());
        const newItem = {
          id: newId,
          categoryID: categoryID,
          code: code,
          name: name,
          productMake: productMake,
          productModel: productModel,
          uim: uim,
          unitCost: unitCost,
          minimumStockShouldBe: minimumStockShouldBe,
          status: status,
        };

        setErrorcode("");
        setErrorname("");
        setproductModel("");
        setErrorProductMake("");
        setproductModel("");
        setErrorStatus("");
        setErrorcategoryID("");
        setErroruim("");

        setIsSubmitted(true);
        setData((prevData) => [...prevData, newItem]);
      } else {
        setIsSubmitted(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitted(false);
      //  handleUpdate();
    }
  };
  const handleUpdate = () => {
    // Check if required fields are not empty
    if (
      id !== "" &&
      categoryID !== "" &&
      code !== "" &&
      name !== "" &&
      productMake !== "" &&
      productModel !== "" &&
      uim !== "" &&
      unitCost !== "" &&
      minimumStockShouldBe !== "" &&
      status !== ""
    ) {
      setErrorcode("");
      setErrorname("");
      setproductModel("");
      setErrorProductMake("");
      setproductModel("");
      setErrorStatus("");
      setErrorcategoryID("");
      setErroruim("");
      // setData(prevData => {
      //   const newData = prevData.map(item => {
      //     if (item.id === modalData.id) {
      //       return {
      //         ...item,
      //         id: item.id,
      //         roleName: roleName,
      //         description: description,
      //         status: modalData.status
      //       };
      //     }
      //     return item;
      //   });

      //   return newData;
      // });
      setIsUpdated(true);
    } else {
      // Handle error if required fields are empty
      setIsUpdated(false);
      // setError("Please Enter Mandatory Fields");
    }
  };

  const fetchData = async () => {
    const apiUrl = getProducts;
    const headers = await GetApiHeaders();

    // const headers = {
    //   "Accept": "application/json",
    //   "Content-Type": "application/json",
    //   "userId": "1",
    //   "mobileNumber": "9704164746",
    // };
    setIsLoading(true);
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("main table response ::;;", JSON.stringify(response));
        setData(response.data.response.ProductMaster);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
    fetchCategoryDropdownData();
    fetchUnitDropdownData();
  }, [modalData]);

  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setName("");
    setproductModel("");
    setProductMake("");
    setCode("");
    setUim("");
    setUnitCost("");
    setminimumStockShouldBe("");
    setStatus("");
    setModalData({
      id: "",
      code: "",
      name: "",
      productMake: "",
      productModel: "",
      uim: "",
      unitCost: "",
      minimumStockShouldBe: "",
      status: "",
    });
  };

  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);

    setShowWarningPopup(false);
    window.location.reload();
  };

  const fetchUnitDropdownData = () => {
    const apiUrl = getUnitMeasureMaster;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userId: "1",
      mobileNumber: "9704164746",
    };

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("response", response);
        setunitdropdowndata(response.data.response.uimMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchCategoryDropdownData = async () => {
    const apiUrl = getCategory;
    const headers = await GetApiHeaders();
    // const headers = {
    //   "Accept": "application/json",
    //   "Content-Type": "application/json",
    //   "userId": "1",
    //   "mobileNumber": "9704164746",
    // };

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("response", response);
        setcategorydropdowndata(response.data.response.productCategory);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const updatedItems = (item) => {
    setModalData(item);

    setId(item.id);
    setName(item.name);
    setProductModel(item.productModel);
    setProductMake(item.productMake);
    setminimumStockShouldBe(item.minimumStockShouldBe);
    setCategoryID(item.productCategoryId);
    setCode(item.productCode);
    setUim(item.uim);
    setUnitCost(item.unitCost);
    setStatus(item.status);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric or decimal
      if (!isNaN(aValue) && !isNaN(bValue)) {
        // Convert to float for proper decimal handling
        const aNumericValue = parseFloat(aValue);
        const bNumericValue = parseFloat(bValue);

        return sortConfig.direction === "asc"
          ? aNumericValue - bNumericValue
          : bNumericValue - aNumericValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });


  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Masters</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text"> Product Master</h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add Product Master"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="🔍 Type to Search..."
                            value={searchTerm}
                            onChange={(e) => handleFilter(e.target.value)}
                            style={{
                              height: "36px",
                              width: "160px",
                              paddingRight: "30px",
                            }}
                            className="mr-2"
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                              }}
                              onClick={() => handleFilter("")}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                        &nbsp;
                        {/* Add the Excel export button */}
                        <button
                          className="btn btn-secondary buttons-excel buttons-html5"
                          onClick={exportToExcel}
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body  ">
                          <div
                            className="table-responsive"
                            style={{
                              maxHeight: "400px", // Set the desired max height for vertical scrolling
                              overflowX: "auto", // Enable horizontal scrolling
                              overflowY: "auto", // Enable vertical scrolling if needed
                            }}
                          >
                            <Table className="table table-bordered Content_data_table table-striped w-100">
                              <Thead>
                                <Tr>
                                  <Th className="text-center">S.No</Th>
                                  <Th className="text-center" onClick={() => handleSort("productCode")}>
                                    Code
                                    <i
                                      className={`fa ${sortConfig.key === "productCode"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("name")}>
                                    Name
                                    <i
                                      className={`fa ${sortConfig.key === "name"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("productMake")}>
                                    Product Make
                                    <i
                                      className={`fa ${sortConfig.key === "productMake"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("productModel")}>
                                    Product Model
                                    <i
                                      className={`fa ${sortConfig.key === "productModel"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("productCategoryName")}>
                                    Category Name
                                    <i
                                      className={`fa ${sortConfig.key === "productCategoryName"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("uimName")}>
                                    Unit Measure
                                    <i
                                      className={`fa ${sortConfig.key === "uimName"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("unitCost")}>
                                    Unit Cost
                                    <i
                                      className={`fa ${sortConfig.key === "unitCost"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("minimumStockShouldBe")}>
                                    Minimum Stock Should Be
                                    <i
                                      className={`fa ${sortConfig.key === "minimumStockShouldBe"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("status")}>
                                    Status
                                    <i
                                      className={`fa ${sortConfig.key === "status"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity ml-2`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center">Actions</Th>
                                </Tr>
                              </Thead>

                              <Tbody>
                                {sortedItems.length === 0 ? (
                                  <Tr>
                                    <Td
                                      colSpan={19}
                                      className="text-center"
                                      style={{ padding: 195, fontSize: 18 }}
                                    >
                                      Data is not available
                                    </Td>
                                  </Tr>
                                ) : (
                                  currentItems != undefined &&
                                  currentItems != null &&
                                  sortedItems.map((item, index) => (
                                    <Tr>
                                      <Td className="text-left">{(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                      <Td>{item.productCode}</Td>
                                      <Td>{item.name}</Td>
                                      <Td>{item.productMake}</Td>
                                      <Td>{item.productModel}</Td>
                                      <Td>{item.productCategoryName}</Td>
                                      <Td>{item.uimName}</Td>
                                      <Td className="text-right">
                                        {item.unitCost}
                                      </Td>
                                      <Td className="text-right">
                                        {item.minimumStockShouldBe}
                                      </Td>
                                      <Td className="text-center">
                                        {item.status === "1" ? (
                                          <span style={{ color: "#2CA800" }}>
                                            Active
                                          </span>
                                        ) : (
                                          <span style={{ color: "#FF0000" }}>
                                            In Active
                                          </span>
                                        )}
                                      </Td>
                                      <Td className="text-center">
                                        <div
                                          style={{ width: 20, height: 20 }}
                                          onClick={() => updatedItems(item)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faEdit}
                                            className="plus_icon mr-1 text-primary text-center"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit Product Master"
                                          />
                                          {/* Add button content here */}
                                        </div>
                                      </Td>
                                    </Tr>
                                  ))
                                )}
                              </Tbody>
                              {isLoading && (
                                <Loader
                                  loading={isLoading}
                                  message={"Fetching Data. Please Wait..!"}
                                  loderimage={loaderImage}
                                />
                              )}
                            </Table>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger  close_btn"
                                onClick={handleCancel_new}
                              >
                                {" "}
                                {/*closeWarning */}X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  Product Master
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Code <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="code"
                                      value={code}
                                      onChange={handleCodeChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {Errorcode}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Name <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Name"
                                      value={name}
                                      inputMode="text"
                                      onChange={handleNameChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {Errorname}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Product Make{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="product Make"
                                      value={productMake}
                                      onChange={handleproductMakeChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorProductMake}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputName"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Product Model{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="product Model"
                                      value={productModel}
                                      inputMode="text"
                                      onChange={handleproductModelChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorProductModel}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Category Name{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-control p-2"
                                    value={categoryID}
                                    onChange={handleCategoryIdChange}
                                  >
                                    <option value="">
                                      Select Category Name{" "}
                                    </option>
                                    {categorydropdowndata.map((item) =>
                                      item.status == 1 ? (
                                        <option
                                          key={item.productCategoryId}
                                          value={item.productCategoryId}
                                        >
                                          {item.name}
                                        </option>
                                      ) : null
                                    )}
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorcategoryID}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Unit Measure{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      id="dropdown"
                                      type="dropdown"
                                      className="form-control p-2"
                                      value={uim}
                                      onChange={handleuimChange}
                                    >
                                      <option value="">
                                        {" "}
                                        Select Unit Measure
                                      </option>
                                      {unitdropdowndata.map((item) =>
                                        item.status == 1 ? (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ) : null
                                      )}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {erroruim}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Unit Cost{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder="Unit Cost"
                                      value={unitCost}
                                      onChange={handleunitCostChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {erorUimCost}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-md font-weight-600 mt-1"
                                  >
                                    Minimum Stock ShouldBe{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="minimum Stock ShouldBe"
                                      value={minimumStockShouldBe}
                                      inputMode="text"
                                      onChange={
                                        handleminimumStockShouldBeChange
                                      }
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorminimumStockShouldBe}{" "}
                                  </span>
                                </div>
                                {error && (
                                  <span
                                    style={{ color: "red" }}
                                    className="error-message"
                                  >
                                    {error}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-6 mt-2">
                                <label
                                  for="Input"
                                  class="form-label text-md font-weight-600"
                                >
                                  Status <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="input-group input-group w-100">
                                  <select
                                    className="form-select p-2 mb-4"
                                    value={status}
                                    onChange={handleStatus}
                                  >
                                    <option value="" disabled selected>
                                      Select Status
                                    </option>
                                    <option value="1">Active</option>
                                    <option value="0">In-Active</option>
                                  </select>
                                </div>
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {errorStatus}{" "}
                                </span>
                              </div>
                              {/* <div className='col-md-6 pl-0'>
                                  <label for="Input" class="form-label text-md font-weight-600 mt-1">
                                    Status
                                  </label>
                                  <div className="input-group input-group w-100">
                                  
                                     <select
                                      className="form-select p-2 mb-4"
                                      value={status}
                                      onChange={handleStatus}
                                    >
                                      {statusArray.map((item)=>(
                                        <option key={item.value} value={item.value}>{item.name}</option>
                                      ))}</select>
                                    {/*  <option value="-1" disabled selected>Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">No</option>
                                    </select> 
                                  </div>
                                  <span style={{ color: 'red' }}>{errorStatus}</span>
                                </div> */}

                              {/* <div className='row'>
                                {isSubmitted ? (
                                  <p style={{ color: 'green' }} >{strings.recordSubmitted}.</p>
                                ) : null}
                                {isUpdated ? (
                                  <p style={{ color: 'green' }} >{strings.updatedSuccessfully}</p>
                                ) : null}
                              </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => {
                                    setModalData({
                                      id: id,
                                      categoryID: categoryID,
                                      code: code,
                                      name: name,
                                      uim: uim,
                                      unitCost: unitCost,
                                      minimumStockShouldBe:
                                        minimumStockShouldBe,
                                      status: status,
                                      productModel: productModel,
                                      productMake: productMake,
                                    });
                                    handleSubmit(modalData);
                                    // setTimeout(() => {
                                    //   console.log(JSON.stringify(modalData));
                                    //   handleSubmit(modalData)
                                    // }, 500);
                                  }}
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>

                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Product Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Product Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                    {/* Pagination */}
                    <div
                      className="d-flex justify-content-between"
                      style={{ borderRadius: 5, paddingTop: 20 }}
                    >
                      <div>
                        Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                        to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp; of {totalItems} entries
                      </div>
                      <div>
                        <div className="div-button text-center">
                          <button
                            className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                              }`}
                            //  className='borderleft-radius-5'
                            // className='ul-pagination'
                            onClick={() => handlePageClick(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            Prev
                          </button>
                          <button
                            //  className='ul-pagination page-item'
                            //   style={{
                            //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                            //     color: (currentPage == 1) ? 'white' : 'black'
                            //   }}
                            className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                              }`}
                            key={1}
                            onClick={() => handlePageClick(1)}
                            disabled={currentPage === 1}
                          >
                            {1}
                          </button>
                          {pageNumbers.map((number) => (
                            <button
                              className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                }`}
                              //className='ul-pagination page-item'
                              //   style={{
                              //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                              //     color: (currentPage == number) ? 'white' : 'black'
                              //   }}
                              key={number}
                              onClick={() => handlePageClick(number)}
                              disabled={currentPage === number}
                            >
                              {number}
                            </button>
                          ))}
                          {Math.ceil(totalItems / itemsPerPage) > 1 &&
                            currentPage !==
                            Math.ceil(totalItems / itemsPerPage) - 1 &&
                            currentPage !==
                            Math.ceil(totalItems / itemsPerPage) && (
                              <button
                                className="ul-pagination page-item"
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                }}
                              >
                                ...
                              </button>
                            )}
                          {Math.ceil(totalItems / itemsPerPage) > 1 && (
                            <button
                              className={`ul-pagination page-item ${currentPage ===
                                Math.ceil(totalItems / itemsPerPage)
                                ? "active"
                                : ""
                                }`}
                              // className='ul-pagination page-item'
                              // style={{
                              //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                              //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                              // }}
                              key={Math.ceil(totalItems / itemsPerPage)}
                              onClick={() =>
                                handlePageClick(
                                  Math.ceil(totalItems / itemsPerPage)
                                )
                              }
                              disabled={
                                currentPage ===
                                Math.ceil(totalItems / itemsPerPage)
                              }
                            >
                              {Math.ceil(totalItems / itemsPerPage)}
                            </button>
                          )}
                          <button
                            className={`borderright-radius-5 ${currentPage ===
                              Math.ceil(totalItems / itemsPerPage)
                              ? "active"
                              : ""
                              }`}
                            //className='borderright-radius-5'
                            onClick={() => handlePageClick(currentPage + 1)}
                            disabled={indexOfLastItem >= totalItems}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

        <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
      {/* {!isUserLoggedIn && <Login />} */}
    </body>
  );
};

export default ProductMaster;
