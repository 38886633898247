import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FaCalendar } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
library.add(faClock);



export const getNewListWithModifyChecked = (index, arr) => {
  // Your initial array

  // Check if the index is valid
  if (index >= 0 && index < arr.length) {
    // Modify the 'checked' value at the specified index
    arr[index].checked = !arr[index].checked;

  }

  // Return the modified array
  return arr;
}
export const extractTimeFromDate = (dateTimeString) => {
  const dateObject = new Date(dateTimeString);
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const timeOnly = dateObject.toLocaleTimeString('en-US', options);
  return timeOnly;
};


export const TimeInput = ({ value, onClick, openDatePicker }) => {
  const handleTimeIconClick = () => {
    alert("clickDate")
    openDatePicker();
  };
  return (
    <div className="input-group" >
      <input
        type="text"
        className="form-control"
        value={value}
        onClick={onClick}
        readOnly />

      <div className="input-group-append" onClick={handleTimeIconClick}>
        <span className="input-group-text">
          <FontAwesomeIcon icon="clock" />
        </span>
      </div>
    </div>
  );
}


export const CustomDatePickerInputforReviewwdwork = ({ value, onClick }) => (
  <div>
    <input className='form-control col-12'
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      style={{ cursor: 'pointer', padding: '5px 10px', background: 'white', width: "140px" }}
    />
    <FaCalendar style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
      onClick={onClick} />

  </div>
);

export const CustomDatePickerInput = ({ value, onClick, placeholder }) => (
  <div>
    <input className='form-control col-12'
      type="text"
      value={value}
      onClick={onClick}
      placeholder={placeholder}
      readOnly
      style={{ cursor: 'pointer', padding: '5px 10px', background: 'white' }}
    />
    <FaCalendar style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
      onClick={onClick} />

  </div>
);

export const CustomTimePickerInput = ({ value, onClick }) => (
  <div>
    <input className='form-control w-full'
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      style={{ cursor: 'pointer', padding: '5px 10px', background: 'white' }}
    />
    <FaClock style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
      onClick={onClick} />
  </div>
);

export const CustomTimePickerInputforFireWatch = ({ value, onClick }) => (
  <div>
    <input className='form-control w-full'
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      style={{ cursor: 'pointer', padding: '5px 10px', background: 'white', width: "140px" }}
    />
    <FaClock style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
      onClick={onClick} />
  </div>
);


export const gridSorting = (items, sortConfig) => {
  return [...items].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });
};
