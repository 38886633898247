import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import Loader from "../Utilities/Loader";
import * as XLSX from "xlsx";
import "@fortawesome/fontawesome-free/css/all.min.css";

//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { MultiSelect } from "react-multi-select-component";
import {
  FarmOperation_getMasters,
  FarmOperationAcceptHistory,
  RmhistoryGet,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import moment from "moment";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from "../../src/Utilities/utils";
import { extractDateAsDDMMYYYY } from "../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

//ProductMaster

const FarmOperationsReports = ({ }) => {
  const [thCount, setThCount] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    code: "",
    name: "",
  });
  const [typeOfOperation, setTypeOfOperation] = useState([]);
  const [typeOfMainLocation, setTypeOfMainLocation] = useState([]);
  const [typeOfSubLocation, setTypeOfSubLocation] = useState([]);
  const [operations, setOpeartions] = useState("");
  const [ActivityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOpetion, setSelectedOption] = useState([]);
  const [typeOfOperationData, setTypeOfOperationData] = useState("");
  const [selectedSubSiteMaster, setSelectedSubSiteMaster] = useState([]);
  const [selectedMainSiteMaster, setSelectedMainSiteMaster] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [popupText, setPopupText] = useState(
    "Do you want to close Spray Service"
  );
  const [data, setData] = useState([]);
  const [updateHistoryData, setUpdateHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [wmy, setwmy] = useState("");
  const [WeekSelected, setWeekSelected] = useState("no");
  const [monthSelected, setmonthSelected] = useState("no");
  const [yearSelected, setYearSelected] = useState("no");
  const [allSelected, setAllSelected] = useState("yes");
  const [subSite, setSubSite] = useState("");
  const [mainSite, setMainSite] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter((item) => {
        return (
          (item.serverRequestId &&
            item.serverRequestId
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.location &&
            item.location
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.requesterName &&
            item.requesterName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          ((typeof item.requesterMobileNo === "string" ||
            typeof item.requesterMobileNo === "number") &&
            item.requesterMobileNo
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.mainSiteName &&
            item.mainSiteName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.subSiteName &&
            item.subSiteName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.cropFunctionName &&
            item.cropFunctionName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.typeOfOperationName &&
            item.typeOfOperationName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.requiredDate &&
            item.requiredDate
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.status &&
            item.status
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.acceptorName &&
            item.acceptorName
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.approvedOrRejectedDate &&
            item.approvedOrRejectedDate
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.submittedDate &&
            item.submittedDate
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.completedDate &&
            item.completedDate
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.reOpenedTime &&
            item.reOpenedTime
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
        );
      });
      setFilteredData(filteredResults);
      handlePageClick(1);
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
      setIsLoading(false);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Req ID",
        "Requester Name",
        "Requester Mobile Number",
        "Acceptor Name",
        "Sub Function Name",
        "Requested Date",
        "Required Date",
        "Main Site",
        "Sub Site",
        "Plot No/Location",
        "Plot Acreage",
        "Planted Area",
        "Main Crop Function",
        "Type of Operation",
        "Category",
        "Completed Date",
        "Manpower Usage",
        "Start Time",
        "End Time",
        "Machine Hours",
        "Chemical",
        "Quantity Used",
        "UOM",
        "Fertilizers",
        "Quantity Used",
        "UOM",
        "Requester Remarks",
        "Acceptor Remarks",
      ],
    ];

    filteredData.forEach((item, index) => {
      if (item.updatedWorks && item.updatedWorks.length > 0) {
        item.updatedWorks.forEach((updatedWork) => {
          const chemicals =
            updatedWork.chemicals.length > 0
              ? updatedWork.chemicals
              : [{ name: "", dosage: "", uom: "" }];

          const fertilizers =
            updatedWork.fertilizers.length > 0
              ? updatedWork.fertilizers
              : [{ name: "", dosage: "", uom: "" }];

          const maxLength = Math.max(chemicals.length, fertilizers.length);

          for (let i = 0; i < maxLength; i++) {
            const chemical = chemicals[i] || { name: "", dosage: "", uom: "" };
            const fertilizer = fertilizers[i] || {
              name: "",
              dosage: "",
              uom: "",
            };

            const row = [
              index + 1,
              item.serverRequestId || "",
              item.requesterName || "",
              item.requesterMobileNo || "",
              item.acceptorName || "",
              item.subFunctionName || "",
              item.submittedDate || "",
              item.requiredDate || "",
              item.mainSiteName || "",
              item.subSiteName || "",
              item.plotNoLocationName || "",
              item.plotAcreageName || "",
              item.plantedArea || "",
              item.functionName || "",
              updatedWork.typeOfOperationName || "",
              item.categoryName || "",
              item.completedDate || "",
              updatedWork.manpowerRequired || "",
              updatedWork.startTime || "",
              updatedWork.endTime || "",
              updatedWork.machineHoursRequired || "",
              chemical.name || "",
              chemical.dosage || "",
              chemical.uom || "",
              fertilizer.name || "",
              fertilizer.dosage || "",
              fertilizer.uom || "",
              item.requesterRemarks || "",
              item.acceptorRemarks || "",
            ];
            data.push(row);
          }
        });
      } else {
        const row = [
          index + 1,
          item.serverRequestId || "",
          item.requesterName || "",
          item.requesterMobileNo || "",
          item.acceptorName || "",
          item.subFunctionName || "",
          item.submittedDate || "",
          item.requiredDate || "",
          item.mainSiteName || "",
          item.subSiteName || "",
          item.plotNoLocationName || "",
          item.plotAcreageName || "",
          item.plantedArea || "",
          item.functionName || "",
          "", // Type of Operation
          item.categoryName || "",
          item.cropFunctionName || "",
          item.completedDate || "",
          "", // Manpower Usage
          "", // Start Time
          "", // End Time
          "", // Machine Hours
          "", // Chemical
          "", // Dosage
          "", // UOM
          "", // Fertilizer
          "", // Fertilizer Dosage
          "", // Fertilizer UOM
          item.requesterRemarks || "",
          item.acceptorRemarks || "",
        ];
        data.push(row);
      }
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Update Activity Report");
    XLSX.writeFile(wb, "FOReports.xlsx");
  };

  const handleWMYChange = (e) => {
    $(".date_show_hide").hide();
    let chk_val = e.target.value;
    setwmy(chk_val);
    if (chk_val === 'M' || chk_val === 'Y' || chk_val === 'W' || chk_val === 'All') {
      setSelectedMainSiteMaster([]);
      setSelectedSubSiteMaster([]);
      setTypeOfOperationData([]);
    }

    if (chk_val == "W") {
      setWeekSelected("yes");
      setmonthSelected("no");
      setYearSelected("no");
      setAllSelected("no");
    } else if (chk_val == "M") {
      setWeekSelected("no");
      setmonthSelected("yes");
      setYearSelected("no");
      setAllSelected("no");
    } else if (chk_val == "Y") {
      setWeekSelected("no");
      setmonthSelected("no");
      setYearSelected("yes");
      setAllSelected("no");
    } else if (chk_val == "All") {
      setwmy("");
      setWeekSelected("no");
      setmonthSelected("no");
      setYearSelected("no");
      setAllSelected("yes");
      $(".date_show_hide").show();
    }
  };

  const handleTypeOfOeration = (e) => {
    const selectTractorType = e.target.value;
    setOpeartions(selectTractorType);
  };

  function extractTimeFromDate(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const timeOnly = dateObject.toLocaleTimeString("en-US", { hour12: true });
    return timeOnly;
  }

  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    // fetchData();
    // fetchDataReports();
    fetchTypeOfOperationDropdownData();
  }, []);

  const fetchTypeOfOperationDropdownData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("responseLocation", response);
        setTypeOfOperation(response.data.response.operationMaster);
        setTypeOfMainLocation(response.data.response.mainSiteMaster);
        setTypeOfSubLocation(response.data.response.subSiteMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  ////Data table api IntigrationN

  const fetchData = async () => {
    const apiUrl = FarmOperationAcceptHistory;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios
      .post(apiUrl, { headers })
      .then((response) => {
        setIsLoading(false);
        setData(response.data.response.rmHistory);
        const respData = response.data.response.updateHistory;
        const activityType = [
          ...new Set(respData.map((item) => item.operationMaster)),
        ];
        setActivityData(activityType);
        setLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchDataReports();
  }, []);

  const fetchDataReports = async () => {
    const fetchApi = FarmOperationAcceptHistory;
    const headers = await GetApiHeaders();

    try {
      const response = await axios.post(fetchApi, { headers });
      console.log("update Activity", response);
      setData(response.data.response.activitiesList);
    } catch (err) {
      console.log("err", err);
    }
  };

  let commaSeparatedStrings;
  if (Array.isArray(selectedSubSiteMaster)) {
    commaSeparatedStrings = selectedSubSiteMaster.join(",");
  } else if (typeof selectedSubSiteMaster === "string") {
    commaSeparatedStrings = selectedSubSiteMaster;
  } else {
    commaSeparatedStrings = "";
    console.error("Error: a is not an array");
  }

  let commaSeparatedString;
  if (Array.isArray(selectedOpetion)) {
    commaSeparatedString = selectedOpetion.join(",");
  } else if (typeof selectedOpetion === "string") {
    commaSeparatedString = selectedOpetion;
  } else {
    commaSeparatedString = "";
    console.error("Error: a is not an array");
  }



  const handleSubmit = async (event) => {
    let formattedStartDate = startDate;
    let formattedEndDate = endDate;

    if (startDate) {
      formattedStartDate = startDate.toISOString().split("T")[0];
    }
    if (endDate) {
      formattedEndDate = endDate.toISOString().split("T")[0];
    }
    event.preventDefault();
    const reqObj = {
      activityTypeMainLocation: mainSite,
      activityTypeSubLocation: subSite,
      activityType: typeOfOperationData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    const apiUrl = FarmOperationAcceptHistory;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios
      .post(apiUrl, reqObj, { headers })
      .then((response) => {
        if (
          response.data &&
          response.data.response &&
          response.data.response.updateHistory
        ) {
          setData(response.data.response.updateHistory);
          const respData = response.data.response.updateHistory;
          let allDataArray = [];
          response.data.response.updateHistory.forEach((item) => {
            allDataArray.push(...item.updatedWorks);
          });
          setActivityData(allDataArray);
          setIsLoading(false);
        } else {
          console.error(
            "Unexpected or missing data in response:",
            response.data
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  let arrayUpdateHistoryData = updateHistoryData;
  let arrayUpdatedWork = ActivityData;
  let allArrayData = updateHistoryData.concat(ActivityData);

  console.log(allArrayData, "all arrray data");
  console.log(ActivityData, "update data");





  const handleChangeMainsite = (mainSite) => {
    const labelNames = mainSite.map((item) => item.label).join(", ");
    setSelectedMainSiteMaster(mainSite);
    setMainSite(labelNames);
    const filteredSubSites = typeOfSubLocation.filter(subSite =>
      subSite.parentCode === mainSite[0]?.value
    );
    setTypeOfSubLocation(filteredSubSites);
  };



  const handleChangeSubsite = (subSite) => {
    const labelNames = subSite.map((item) => item.label).join(", ");
    console.log("valueArray", labelNames);
    setSelectedSubSiteMaster(subSite);
    setSubSite(labelNames);
  };

  const handleChangeTypeofOperations = (typeOfOperation) => {
    const labelNames = typeOfOperation.map((item) => item.label).join(", ");
    console.log("valueArray", labelNames);
    setSelectedOption(typeOfOperation);
    setTypeOfOperationData(labelNames);
  };





  const handleChange = (e) => {
    // debugger
    const { name, value } = e.target.value;

    if (name === "selectedStartDt") {
      setSelectedStartDate(value);
    } else if (name === "selectedEndDt") {
      setSelectedEndDate(value);
    }
  };

  ///for colspan count
  const tableId = "Content_data_table";
  useEffect(() => {
    const table = document.getElementById(tableId);
    if (table) {
      const theadRow = table.querySelector("thead tr");
      const thCount = theadRow ? theadRow.querySelectorAll("th").length : 0;
      setThCount(thCount);
    } else {
      console.warn(`Table with id '${tableId}' not found.`);
    }
  }, [tableId]);

  const handlePrint = () => {
    const printWindow = window.open();
    const printContent = document.querySelector(".print-table");
    // Check if the printContent is available
    if (printContent) {
      printWindow.document.write(
        "<html><head>" +
        '<meta charset="utf-8" />' +
        '<link rel="icon" href="/favicon.ico" />' +
        '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
        '<meta name="theme-color" content="#000000" />' +
        '<meta name="description" content="Web site created using create-react-app" />' +
        "<title>MCRC</title>" +
        '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
        '<script defer src="/static/js/bundle.js"></script>' +
        "<style>" +
        "@media print {" +
        "  table {" +
        "    border-collapse: collapse;" +
        "    width: 100%;" +
        "    margin-bottom: 10px;" +
        "  }" +
        "  th, td {" +
        "    border: 1px solid #ddd;" +
        "    text-align: left;" +
        "  }" +
        "}" +
        "</style>" +
        "</head><body>"
      );

      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write("</body></html>");

      // Delay the print function to ensure styles are applied
      printWindow.setTimeout(() => {
        printWindow.print();
        printWindow.close();
      });
    } else {
      // Handle the case where printContent is not found
      alert("print is not available");
    }
  };

  const Sendmail = async (event) => {
    let formattedStartDate = startDate;
    let formattedEndDate = endDate;

    if (startDate) {
      formattedStartDate = startDate.toISOString().split("T")[0];
    }
    if (endDate) {
      formattedEndDate = endDate.toISOString().split("T")[0];
    }

    event.preventDefault();
    const reqObj = {
      activityTypeMainLocation: selectedMainSiteMaster,
      activityTypeSubLocation: subSite,
      activityType: commaSeparatedString,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    const apiUrl = RmhistoryGet;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios.post(apiUrl, reqObj, { headers }).then((response) => {
      setData(response.data.response.rmHistory);
      setIsLoading(false);
    });
  };

  const resetData = () => {
    setwmy("w");
    setSelectedOption([]);
    setSelectedMainSiteMaster([]);
    setSelectedSubSiteMaster([]);
    setStartDate(new Date());
    setEndDate(new Date());
  };
  const toggleDetails = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };
  console.log("selectedRowIndex", selectedRowIndex);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };


  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div
                className="container-fluid"
                style={{ backgroundColor: "#F7F8FA" }}
              >
                <div
                  className="card border-0"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="card-body pt-4 pl-1">
                    <h5 className="Roles_text">Update Activity Reports</h5>
                    <div className="mt-4">
                      <div className="d-flex gap-1 status_card">
                        <div
                          className="card flex-grow-1"
                          style={{
                            border: "1px solid #0000001A",
                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ padding: "20px 20px" }}
                          >
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-3 col-12">
                                  <label className="form-label font-weight-bold mt-1 mr-1">
                                    Select Date Duration :
                                  </label>

                                  <div
                                    className="btn-group"
                                    id="filter-btn"
                                    role="group"
                                    aria-label="Basic radio toggle button group"
                                    onChange={handleWMYChange}
                                  >
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio1"
                                      autocomplete="off"
                                      checked={WeekSelected === "yes"}
                                      value="W"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio1"
                                    >
                                      Week
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio2"
                                      autocomplete="off"
                                      checked={monthSelected === "yes"}
                                      value="M"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio2"
                                    >
                                      Month
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio3"
                                      autocomplete="off"
                                      checked={yearSelected === "yes"}
                                      value="Y"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio3"
                                    >
                                      Year
                                    </label>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio"
                                      id="btnradio4"
                                      autocomplete="off"
                                      checked={allSelected === "yes"}
                                      value="All"
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      for="btnradio4"
                                    >
                                      All
                                    </label>
                                  </div>
                                </div>
                                {/* //Main Site */}
                                <div className="col-md-3 col-12">
                                  <label className="form-label  font-weight-bold mt-1">
                                    Main Site
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>


                                  <MultiSelect
                                    options={typeOfMainLocation.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={selectedMainSiteMaster}
                                    onChange={handleChangeMainsite}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems: "Select Main Site",
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected?.map(
                                          (selectedItem, index) => (
                                            <span key={index}>
                                              {selectedItem?.code}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>

                                {/* //Sub site */}

                                <div
                                  className="col-md-3 col-12"
                                  style={{ position: "relative" }}
                                >
                                  <label className="form-label  font-weight-bold mt-1">
                                    Sub Site
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>



                                  <MultiSelect
                                    options={typeOfSubLocation.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={selectedSubSiteMaster}
                                    onChange={handleChangeSubsite}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems: "Select Sub Site",
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected?.map((selectedItem, index) => (
                                          <span key={index}>{selectedItem?.code}</span>
                                        ))}
                                      </div>
                                    )}
                                  />

                                </div>

                                <div className="col-md-3 col-12">
                                  <label className="form-label  font-weight-bold mt-1">
                                    Type Of Operation
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <MultiSelect
                                    options={typeOfOperation.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={selectedOpetion}
                                    onChange={handleChangeTypeofOperations}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems:
                                        "Select Type Of Operations",
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected?.map(
                                          (selectedItem, index) => (
                                            <span key={index}>
                                              {selectedItem?.code}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                                <div
                                  className="col-sm-3 col-12  date_show_hide"
                                  style={{ marginLeft: "25%" }}
                                >
                                  <div class="mb-3">
                                    <label
                                      for="startdate"
                                      className="form-label font-weight-bold mt-1"
                                    >
                                      From Date{" "}
                                      <span style={{ color: "red" }}>*</span>{" "}
                                    </label>
                                    <DatePicker
                                      className="form-control w-100"
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                      }}
                                      dateFormat="dd-MMM-yyyy"
                                      customInput={<CustomDatePickerInput />}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3 col-12  date_show_hide">
                                  <div class="mb-3">
                                    <label
                                      className="form-label  font-weight-bold mt-1"
                                      for="enddate"
                                    >
                                      To Date{" "}
                                      <span style={{ color: "red" }}>*</span>{" "}
                                    </label>
                                    <DatePicker
                                      showIcon
                                      className="form-control w-100"
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      dateFormat="dd-MMM-yyyy"
                                      minDate={startDate}
                                      customInput={<CustomDatePickerInput />}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mt-2 d-flex w-full justify-content-center gap-2">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  style={{
                                    paddingLeft: "3rem",
                                    paddingRight: "3rem",
                                  }}
                                >
                                  Submit
                                </button>
                                <button
                                  class="btn btn-danger"
                                  onClick={resetData}
                                  style={{
                                    paddingLeft: "3rem",
                                    paddingRight: "3rem",
                                  }}
                                >
                                  Clear All
                                </button>

                                <button
                                  className="btn btn-secondary buttons-excel buttons-html5"
                                  style={{
                                    paddingLeft: "3rem",
                                    paddingRight: "3rem",
                                  }}
                                  onClick={Sendmail}
                                >
                                  Email
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 className="Roles_text mt-4">Reports</h5>
                    <hr />
                    <div className="my-2 d-flex w-full justify-content-end gap-2">
                      <button
                        className="btn btn-secondary buttons-excel buttons-html5"
                        onClick={exportToExcel}
                      >
                        Download Excel
                      </button>

                      <button
                        className="btn btn-primary"
                        style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                        onClick={handlePrint}
                      >
                        Print
                      </button>
                    </div>

                    <div
                      className="card flex-grow-1"
                      style={{
                        border: "1px solid #0000001A",
                        boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ padding: "10px 10px" }}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ paddingLeft: 25, paddingBottom: 20 }}
                        >
                          <div className="d-flex align-items-center">
                            <label className="mr-2">Show </label> &nbsp;&nbsp;
                            <select
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                            &nbsp;&nbsp;
                            <label> entries</label>
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginRight: "2%" }}
                          >
                            {/* Search Input */}
                            <input
                              type="search"
                              placeholder="🔍 Type to Search..."
                              value={searchTerm}
                              onChange={(e) => handleFilter(e.target.value)}
                              style={{ height: "36px" }}
                              className="mr-1"
                            />{" "}
                            &nbsp;
                          </div>
                        </div>
                        <div
                          className="print-table"
                          style={{ overflowX: "auto" }}
                        >
                          <Table
                            className="table table-bordered Content_data_table table-striped"
                            id="Content_data_table"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">S.No</th>
                                <th className="text-center" onClick={() => handleSort("serverRequestId")}>
                                  Request ID{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "serverRequestId"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("requesterName")}>
                                  Requester Name{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "requesterName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>

                                <th className="text-center" onClick={() => handleSort("requesterMobileNo")}>
                                  Requester Mobile No{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "requesterMobileNo"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("mainSiteName")}>
                                  Main Site{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "mainSiteName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("subSiteName")}>
                                  Sub Site{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "subSiteName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("plotNoLocationName")}>
                                  Plot No/Location{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "plotNoLocationName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("plotAcreageName")}>
                                  Plot Acrage{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "plotAcreageName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-center" onClick={() => handleSort("plantedArea")}>
                                  Planted Area{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "plantedArea"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </th>
                                <th className="text-left"> Action</th>
                              </tr>
                            </thead>

                            <tbody style={{ height: "10px" }}>
                              {currentItems && currentItems.length > 0 ? (
                                sortedItems.map((item, index) => {
                                  var requiredDate = moment(
                                    item.requiredDate
                                  ).format("DD-MMM-YYYY ");

                                  var submittedDate = moment(
                                    item.submittedDate
                                  ).format("DD-MMM-YYYY ");
                                  var parsedDate = moment(
                                    item.completedDate,
                                    "DD-MM-YYYY"
                                  );
                                  if (parsedDate.isValid()) {
                                    var completedDate =
                                      parsedDate.format("DD-MMM-YYYY");
                                    console.log(
                                      "Formatted Date:",
                                      completedDate
                                    );
                                  } else {
                                    var completedDate = "";
                                  }

                                  var parsedReOpenedTime = moment(
                                    item.reOpenedTime
                                  );
                                  if (parsedReOpenedTime.isValid()) {
                                    var reOpenedTime =
                                      parsedReOpenedTime.format(
                                        "DD-MMM-YYYY  "
                                      );
                                  } else {
                                    var reOpenedTime = " ";
                                  }
                                  var parsedRejectedDate = moment(
                                    item.reOpenedTime
                                  );
                                  if (parsedRejectedDate.isValid()) {
                                    var rejectedDate =
                                      parsedRejectedDate.format(
                                        "DD-MMM-YYYY  "
                                      );
                                  } else {
                                    var rejectedDate = " ";
                                  }
                                  return (
                                    <React.Fragment>
                                      <tr>
                                        <td className="text-center">
                                          {" "}
                                          {(currentPage - 1) * itemsPerPage +
                                            index +
                                            1}{" "}
                                        </td>
                                        <td className="text-left">
                                          {item.serverRequestId}
                                        </td>
                                        <td className="text-left">
                                          {" "}
                                          {item.requesterName}
                                        </td>
                                        <td className="text-left">
                                          {item.requesterMobileNo}
                                        </td>
                                        <td className="text-left">
                                          {item.mainSiteName}
                                        </td>
                                        <td className="text-left">
                                          {item.subSiteName}
                                        </td>
                                        <td className="text-left">
                                          {item.plotNoLocationName}
                                        </td>
                                        <td className="text-left">
                                          {item.plotAcreageName}
                                        </td>
                                        <td className="text-left">
                                          {item.plantedArea}
                                        </td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faEye}
                                              className="plus_icon mr-1 text-primary"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="View"
                                              onClick={() =>
                                                toggleDetails(index)
                                              }
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                      {selectedRowIndex === index &&
                                        item.updatedWorks &&
                                        item.updatedWorks.map((updatedWork) => {
                                          return (
                                            <tr>

                                              <td
                                                style={{ padding: 24 }}
                                                colSpan="22"
                                              >
                                                <div className="request-card">
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Main Crop/Function
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {" "}
                                                        {item.functionName}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Team
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.cropFunctionName}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Actual Crop in Field
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.actualCropName}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Type of Operation
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.categoryName}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Acitivity
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {
                                                          item.typeOfOperationName
                                                        }
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Required Date
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {requiredDate}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Required Hours
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {
                                                          updatedWork.requiredHours
                                                        }
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Approved/Rejected By
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.acceptorName}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Approved/Rejected Date
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {rejectedDate}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Work Submitted Date
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {submittedDate}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Work Completed Date
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {completedDate}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Activity Re-opened Date
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {reOpenedTime}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Manpower usage
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {
                                                          updatedWork.manpowerRequired
                                                        }
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Start Time
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {updatedWork.startTime}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        End Time
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {updatedWork.endTime}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Machine Required Hours
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {
                                                          updatedWork.machineHoursRequired
                                                        }
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Requester Remarks
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.requesterRemarks}
                                                      </span>
                                                    </span>
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Acceptor Remarks
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.acceptorRemarks}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 14,
                                                      width: "100%",
                                                    }}
                                                    className="ProductNameForRandM d-flex"
                                                  >
                                                    <span
                                                      className=" mt-3"
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "45%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Status
                                                      </span>
                                                      <span> :&nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "55%",
                                                          display:
                                                            "inline-flex",
                                                          whiteSpace:
                                                            "break-spaces",
                                                        }}
                                                      >
                                                        {item.status}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    {
                                                      <div className="mt-4 d-flex" style={{ gap: "8rem" }}  >
                                                        <div className="w-50">
                                                          <span className="fw-bold">
                                                            {item.fertilizers.length > 0 ? "Fertilizers :" : ""}
                                                          </span>

                                                          {item.fertilizers
                                                            .length > 0 && (
                                                              <div className="d-flex justify-content-between mt-3">
                                                                <div className="d-flex flex-column">
                                                                  <span className="custome-text-md fw-bold ">
                                                                    Name
                                                                  </span>
                                                                  {item.fertilizers.map((fertilizer, index) => (
                                                                    <span key={index} className="custome-text-md font-weight-300 mt-2"  >
                                                                      {fertilizer.name || "No Name"}{" "}
                                                                    </span>
                                                                  )
                                                                  )}
                                                                </div>

                                                                <div className="d-flex flex-column">
                                                                  <span className="custome-text-md fw-bold ">
                                                                    {/* Dosage */}
                                                                    Quantity Used
                                                                  </span>
                                                                  {item.fertilizers.map((fertilizer, index) => (
                                                                    <span key={index}
                                                                      className="custome-text-md font-weight-300 mt-2" >
                                                                      {fertilizer.uom.includes("Kgs") ? fertilizer.dosage + " " + "(gm/kg)" : fertilizer.dosage + " " + "(ml/ltr)"}
                                                                    </span>
                                                                  )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            )}
                                                        </div>

                                                        <div className="w-50">
                                                          <span className="fw-bold">
                                                            {item.chemicals.length > 0 ? "Chemicals :" : ""}
                                                          </span>

                                                          {item.chemicals
                                                            .length > 0 && (
                                                              <div className="d-flex justify-content-between mt-3">
                                                                <div className="d-flex flex-column">
                                                                  <span className="custome-text-md fw-bold ">
                                                                    Name
                                                                  </span>
                                                                  {item.chemicals.map(
                                                                    (chemical, index) => (
                                                                      <span key={index} className="custome-text-md font-weight-300 mt-2" >
                                                                        {chemical.name || "No Name"}
                                                                      </span>
                                                                    )
                                                                  )}
                                                                </div>

                                                                <div className="d-flex flex-column">
                                                                  <span className="custome-text-md fw-bold ">
                                                                    {/* Dosage */}
                                                                    Quantity Used
                                                                  </span>
                                                                  {item.chemicals.map((chemical, index) => (
                                                                    <span key={index} className="custome-text-md font-weight-300 mt-2" >
                                                                      {chemical.uom.includes("Kgs") ? chemical.dosage + " " + "(gm/kg)" : chemical.dosage + " " + "(ml/ltr)"}
                                                                    </span>
                                                                  )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            )}
                                                        </div>
                                                      </div>
                                                    }
                                                  </div>
                                                  {/* <div className="row mt-4" style={{ rowGap: "10px", maxHeight: "250px", overflow: "auto" }}>
                                                    <>
                                                      <span className="fw-bold">Updated activity details:</span>
                                                      {item.updatedWorks.map((updatedWork, index) => (
                                                        <div className="col-12" key={index}>
                                                          <div className="card w-full p-2" style={{ backgroundColor: "#FFF6E5" }}>
                                                            {updatedWork != undefined &&
                                                              updatedWork.fertilizers != undefined &&
                                                              updatedWork.fertilizers.length > 0 &&
                                                              updatedWork.chemicals != undefined &&
                                                              updatedWork.chemicals.length > 0 ? (
                                                              <div className="">
                                                                <div className="px-3 mt-2">
                                                                  <span className="fw-bold">Fertilizers:</span>
                                                                  <div className="d-flex justify-content-between mt-2">
                                                                    <div className="d-flex flex-column">
                                                                      <span className="custome-text-md fw-bold text-decoration-underline">Name</span>
                                                                      {updatedWork.fertilizers.map((fertilizer, index) => (
                                                                        <span className="custome-text-md font-weight-600 mt-2">{fertilizer.name}</span>
                                                                      ))}
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                      <span className="custome-text-md fw-bold text-decoration-underline">Dosage</span>
                                                                      {updatedWork.fertilizers.map((fertilizer, index) => (
                                                                        <span className="custome-text-md font-weight-600 mt-2">
                                                                          {fertilizer.uom.includes("Kgs")
                                                                            ? fertilizer.dosage + " " + "(gm/kg)"
                                                                            : fertilizer.dosage + " " + "(ml/ltr)"}
                                                                        </span>
                                                                      ))}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="px-3 mt-3">
                                                                  <span className="fw-bold">Chemicals:</span>
                                                                  <div className="d-flex justify-content-between mt-2">
                                                                    <div className="d-flex flex-column">
                                                                      <span className="custome-text-md fw-bold text-decoration-underline">Name</span>
                                                                      {updatedWork.chemicals.map((chemical, index) => (
                                                                        <span className="custome-text-md font-weight-600 mt-2">{chemical.name}</span>
                                                                      ))}
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                      <span className="custome-text-md fw-bold text-decoration-underline">Dosage</span>
                                                                      {updatedWork.chemicals.map((chemical, index) => (
                                                                        <span className="custome-text-md font-weight-600 mt-2">
                                                                          {chemical.uom.includes("Kgs")
                                                                            ? chemical.dosage + " " + "(gm/kg)"
                                                                            : chemical.dosage + " " + "(ml/ltr)"}
                                                                        </span>
                                                                      ))}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div className="text-center mt-3">No data available</div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      ))}
                                                    </>
                                                  </div> */}


                                                  {/* {selectedRowIndex === index && */}
                                                  {item != undefined && item.updatedWorks != undefined &&
                                                    item.updatedWorks.length > 0 && (
                                                      <div className='row mt-4' style={{ rowGap: "10px", maxHeight: "250px", overflow: "auto" }}>
                                                        <span className='fw-bold'>Updated Activity Details :</span>
                                                        {item.updatedWorks.map((updatedWork) =>
                                                          <div className='col-12'>
                                                            <div className='card w-full p-2'
                                                              style={{ backgroundColor: "#FFF6E5" }}>

                                                              {updatedWork.updateDate && (
                                                                <div
                                                                  className="custome-text-md font-weight-600 d-flex 
                                              justify-content-between px-3">
                                                                  Date :
                                                                  <span className='text-xs font-weight-normal'>
                                                                    {updatedWork.updateDate}</span>
                                                                </div>)}


                                                              {updatedWork.typeOfOperationName !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Type Of OperationName :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.typeOfOperationName}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.fieldPreparation !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Field Preparation :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.fieldPreparation}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.manpowerRequired !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Manpower Usage :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.manpowerRequired}
                                                                  </span>
                                                                </div>
                                                              )}

                                                              {updatedWork.machineEndTime !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Machine End Time :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.machineEndTime}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.startTime !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Start Time :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.startTime}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.endTime !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  End Time :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.endTime}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.requiredHours !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Required Hours :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.requiredHours}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.manualOperation !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Manual Operation :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.manualOperation}
                                                                  </span>
                                                                </div>
                                                              )}

                                                              {updatedWork.mechanicalOperations !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Mechanical Operations :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.mechanicalOperations}
                                                                  </span>
                                                                </div>
                                                              )}
                                                              {updatedWork.machineHoursRequired !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  MachineHours Required :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.machineHoursRequired}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.dosagePerLiter !== '' && (
                                                                <div
                                                                  className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">
                                                                  Dosage Per Liter :
                                                                  <span className='text-xs font-weight-normal'>{updatedWork.dosagePerLiter}
                                                                  </span>
                                                                </div>
                                                              )}


                                                              {updatedWork.updatedRemarks && (<div className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">Remarks : <span className='text-xs font-weight-normal'> {updatedWork.updatedRemarks}</span>
                                                              </div>)}


                                                              {updatedWork != undefined && updatedWork.fertilizers
                                                                != undefined && updatedWork.fertilizers.length > 0 &&
                                                                updatedWork.chemicals != undefined &&
                                                                updatedWork.chemicals.length > 0 && (

                                                                  <div className=''>
                                                                    <div className='px-3 mt-2'>
                                                                      <span className='fw-bold'>Fertilizers :</span>
                                                                      <div className='d-flex justify-content-between mt-2'>
                                                                        <div className='d-flex flex-column'>
                                                                          <span className='custome-text-md fw-bold 
                                                      text-decoration-underline'>
                                                                            Name
                                                                          </span>
                                                                          {updatedWork.fertilizers.map((fertilizer) => (
                                                                            <span className='custome-text-md font-weight-600 mt-2'>
                                                                              {fertilizer.name}
                                                                            </span>
                                                                          ))}
                                                                        </div>
                                                                        <div className='d-flex flex-column'>
                                                                          <span className='custome-text-md fw-bold text-decoration-underline'>
                                                                            Quantity Used
                                                                          </span>
                                                                          {updatedWork.fertilizers.map((fertilizer) => (
                                                                            <span className='custome-text-md font-weight-600 mt-2'>
                                                                              {fertilizer.uom.includes("Kgs") ? fertilizer.dosage + " " + "(gm/kg)" : fertilizer.dosage + " " + "(ml/ltr)"}
                                                                            </span>))}
                                                                        </div>
                                                                      </div>
                                                                    </div>

                                                                    <div className='px-3 mt-3'>
                                                                      <span className='fw-bold'>Chemicals :</span>
                                                                      <div className='d-flex justify-content-between mt-2'>
                                                                        <div className='d-flex flex-column'>
                                                                          <span className='custome-text-md fw-bold 
                                                      text-decoration-underline'>
                                                                            Name
                                                                          </span>
                                                                          {updatedWork.chemicals.map((chemical, index) => (
                                                                            <span className='custome-text-md font-weight-600 mt-2'>
                                                                              {chemical.name}</span>
                                                                          ))}
                                                                        </div>
                                                                        <div className='d-flex flex-column'>
                                                                          <span className='custome-text-md fw-bold text-decoration-underline'>
                                                                            Quantity Used
                                                                          </span>
                                                                          {updatedWork.chemicals.map((chemical, index) => (
                                                                            <span className='custome-text-md font-weight-600 mt-2'>
                                                                              {chemical.uom.includes("Kgs") ? chemical.dosage + " " + "(gm/kg)" : chemical.dosage + " " + "(ml/ltr)"}</span>
                                                                          ))}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}
                                                  {/* // } */}

                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={thCount} className="text-center">
                                    No Data Available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingLeft: 25, paddingBottom: 20 }}
                    ></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          {/* Pagination */}

                          <div
                            className="d-flex justify-content-between mb-10"
                            style={{ borderRadius: 5 }}
                          >
                            <div>
                              Showing{" "}
                              {Math.min(
                                (currentPage - 1) * itemsPerPage + 1,
                                totalItems
                              )}
                              &nbsp;to{" "}
                              {Math.min(
                                (currentPage - 1) * itemsPerPage +
                                currentItems.length,
                                totalItems
                              )}
                              &nbsp; of {totalItems} entries
                            </div>
                            <div>
                              <div className="div-button text-center">
                                <button
                                  className="borderleft-radius-5"
                                  // className='ul-pagination'
                                  onClick={() =>
                                    handlePageClick(currentPage - 1)
                                  }
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>

                                <button
                                  className="ul-pagination page-item"
                                  style={{
                                    backgroundColor:
                                      currentPage == 1 ? "#0056b3" : "white",
                                    color: currentPage == 1 ? "white" : "black",
                                  }}
                                  key={1}
                                  onClick={() => handlePageClick(1)}
                                  disabled={currentPage === 1}
                                >
                                  {1}
                                </button>
                                {pageNumbers.map((number) => (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage == number
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage == number
                                          ? "white"
                                          : "black",
                                    }}
                                    key={number}
                                    onClick={() => handlePageClick(number)}
                                    disabled={currentPage === number}
                                  >
                                    {number}
                                  </button>
                                ))}
                                {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                  currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) - 1 &&
                                  currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) && (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      ...
                                    </button>
                                  )}
                                {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage ==
                                          Math.ceil(totalItems / itemsPerPage)
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage ==
                                          Math.ceil(totalItems / itemsPerPage)
                                          ? "white"
                                          : "black",
                                    }}
                                    key={Math.ceil(totalItems / itemsPerPage)}
                                    onClick={() =>
                                      handlePageClick(
                                        Math.ceil(totalItems / itemsPerPage)
                                      )
                                    }
                                    disabled={
                                      currentPage ===
                                      Math.ceil(totalItems / itemsPerPage)
                                    }
                                  >
                                    {Math.ceil(totalItems / itemsPerPage)}
                                  </button>
                                )}
                                <button
                                  className="borderright-radius-5"
                                  onClick={() =>
                                    handlePageClick(currentPage + 1)
                                  }
                                  disabled={indexOfLastItem >= totalItems}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}
          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      {/*  <!-- Logout Modal--> */}
      <Logout />
      {/* {!isUserLoggedIn && <Login />} */}
    </body>
  );
};

export default FarmOperationsReports;
