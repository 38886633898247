import React, { useEffect, useState } from "react";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./Logout";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "react-bootstrap/Pagination";
import { DownloadCsHistory, history } from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";
import Loader from "../Utilities/Loader";

const History = () => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [data, setData] = useState([]);
  const [returnHistory, setReturnHistory] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState('');

  const fetchData = async () => {
    const fetchApi = history;
    setIsLoading(true);
    try {
      const headers = await GetApiHeaders();
      const response = await axios.post(fetchApi, null, { headers });
      // console.log("response", response);

      if (response.data.response.requestsHistory) {
        setData(response.data.response.requestsHistory);
        console.log("requestsHistory", response.data.response.requestsHistory);
      } else {
        setData([]);
      }
      if (response.data.response.returnsHistory) {
        console.log("returnsHistory", response.data.response.returnsHistory);
        setReturnHistory(response.data.response.returnsHistory);
      } else {
        setReturnHistory([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
      setReturnHistory([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    console.log("selectedHistory", selectedHistory);
  }, [selectedHistory]);


  const handleDownload = async () => {
    const downloadApi = DownloadCsHistory;
    try {
      setIsLoading(true);
      const headers = await GetApiHeaders();
      const requestBody = {
        requestType: "history",
      };
      const response = await axios.post(downloadApi, requestBody, {
        headers,
      });
      if (
        response.data &&
        response.data.response &&
        response.data.response.history
      ) {
        const fileUrl = response.data.response.history;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "history_report.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const historyOptions = [
    "Request",
    "Return",
  ];


  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">History</h5>

                    <div className="border-0 d-flex justify-content-between">
                      <select
                        className="selector border border-info"
                        type="dropdown"
                        onChange={(e) => setSelectedHistory(e.target.value)}
                      >
                        <option>Select History</option>
                        {historyOptions.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div className="d-flex justify-content-between"></div>
                    {/* <div className="mcrc_hrline"></div> */}
                    <div>
                      <div className="card data_tableCard">
                        <div
                          className="card-body"
                          style={{ overflowX: "auto" }}
                        >

                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary mb-3"
                              onClick={handleDownload}
                              disabled={isLoading}
                            >
                              {isLoading ? 'Downloading...' : 'Download'}
                            </button>
                          </div>

                          <Table>
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2 ">History</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {selectedHistory == "Request" &&
                                data.map((item, index) => {
                                  var newDateFormat = moment(item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A");
                                  var productRequiredDate = moment(
                                    item.productRequiredDate
                                  ).format("DD-MMM-YYYY");
                                  var productIssuedDate = moment(
                                    item.productIssuedDate
                                  ).format("DD-MMM-YYYY");
                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-2 mr-1"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >
                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b className="">
                                                    Request ID &nbsp;
                                                    &nbsp;:&nbsp;
                                                  </b>{" "}
                                                  <span>
                                                    {item.requestId}
                                                  </span>
                                                </span>
                                                <span
                                                  className=" "
                                                  style={{ width: "33%" }}
                                                >
                                                  <b className="">
                                                    Request Name &nbsp;
                                                    &nbsp;:&nbsp;
                                                  </b>{" "}
                                                  <span>
                                                    {item.requesterName}
                                                  </span>
                                                </span>
                                                {
                                                  item.requestSelfOthers.toLowerCase() !== "self" &&
                                                  item.requestSelfOthers &&
                                                  item.acceptorName && (
                                                    <span style={{ width: "29%" }}>
                                                      <b>On Behalf Of &nbsp;:</b>
                                                      <span>&nbsp;&nbsp;{item.employeeName}</span>
                                                    </span>
                                                  )
                                                }
                                                <span
                                                  className=""
                                                  style={{ width: "18%" }}
                                                >
                                                  <b>
                                                    Status &nbsp; &nbsp;: &nbsp;
                                                  </b>
                                                  <span>
                                                    {item.requestStatusCode ===
                                                      2 ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Accepted
                                                      </span>
                                                    ) : item.requestStatusCode ===
                                                      3 ? (
                                                      <span
                                                        style={{
                                                          color: "#D50101", fontWeight: 600,
                                                        }}
                                                      >
                                                        Rejected
                                                      </span>
                                                    ) : item.requestStatusCode ===
                                                      0 ? (
                                                      <span
                                                        style={{
                                                          color: "Orange",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Pending
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          color: "orange",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Partially Accept
                                                      </span>
                                                    )}
                                                  </span>
                                                  &nbsp; &nbsp;
                                                </span>

                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="">
                                                {item.productCategories.map(
                                                  (category, pcIndex) => {
                                                    return (
                                                      <div className="request-card mt-3">
                                                        <div className="mt-3">


                                                          <div className="d-flex gap-4 align-items-center mb-3">

                                                            <p style={{ fontSize: 16 }} className="mb-0">
                                                              <b
                                                                style={{
                                                                  fontSize: 18,
                                                                  color: "orange",
                                                                }}
                                                              >
                                                                Category{" "}
                                                              </b>{" "}
                                                              <span
                                                                style={{
                                                                  marginLeft: 47,
                                                                }}
                                                              >
                                                                :{" "}
                                                              </span>{" "}
                                                              &nbsp;
                                                              {category.categoryName}
                                                            </p>

                                                            <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex"   >
                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                                  Request Date
                                                                </span>
                                                                <span >  :&nbsp;
                                                                </span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>{newDateFormat}</span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          {category.products.map(
                                                            (
                                                              product,
                                                              productIndex
                                                            ) => {
                                                              return (
                                                                <>
                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" key={productIndex}>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Product  Name
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productName}</span>
                                                                    </span>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Required  Quantity
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productRequiredQuantity}</span>
                                                                    </span>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Required  Location
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.productRequiredLocation}</span>
                                                                    </span>
                                                                  </div>

                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" >
                                                                    {
                                                                      item.requestStatusCode === 3 ? "" : (
                                                                        <span style={{ width: '33.4%' }}>
                                                                          <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                            Issued Quantity
                                                                          </span>
                                                                          <span >  :&nbsp;
                                                                          </span>
                                                                          <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.issuedQuantity}</span>
                                                                        </span>
                                                                      )
                                                                    }
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Issued By
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.acceptorName}</span>
                                                                    </span>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Total Order Value
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.totalOrderValue}</span>
                                                                    </span>
                                                                  </div>
                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" >
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Unit Price
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productUnitPrice}</span>
                                                                    </span>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Request Type
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requestSelfOthers}</span>
                                                                    </span>
                                                                    {item.requestStatusCode === 3 ? ("") : (
                                                                      <span style={{ width: '33.4%' }}>
                                                                        <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                          Delivery  Date
                                                                        </span>
                                                                        <span >  :&nbsp;
                                                                        </span>
                                                                        <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{productIssuedDate}</span>
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" >


                                                                    {item.requestStatusCode === 3 ? ("") : (
                                                                      <span style={{ width: '33.4%' }}>
                                                                        <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                          Required  Date
                                                                        </span>
                                                                        <span >  :&nbsp;
                                                                        </span>
                                                                        <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{productRequiredDate}</span>
                                                                      </span>
                                                                    )}
                                                                    {
                                                                      item.requesterRemarks !== '' ? (
                                                                        <span style={{ width: '33.4%' }}>
                                                                          <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                            Requester Remarks
                                                                          </span>
                                                                          <span >  :&nbsp;
                                                                          </span>
                                                                          <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requesterRemarks}</span>
                                                                        </span>) : ''
                                                                    }
                                                                    {
                                                                      item.accepterRemarks !== '' ? (
                                                                        <span style={{ width: '33.4%' }}>
                                                                          <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                            Acceptor Remarks
                                                                          </span>
                                                                          <span >  :&nbsp;
                                                                          </span>
                                                                          <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.accepterRemarks}</span>
                                                                        </span>) : ''
                                                                    }
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                              <div
                                                style={{ fontSize: 14 }}
                                                className="mt-2 ml-3"
                                              ></div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })
                              }



                              {selectedHistory == "Return" &&
                                returnHistory.map((item, index) => {
                                  var newDateFormat = moment(item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A");
                                  var productRequiredDate = moment(
                                    item.productRequiredDate
                                  ).format("DD-MMM-YYYY");
                                  var productIssuedDate = moment(
                                    item.productIssuedDate
                                  ).format("DD-MMM-YYYY");
                                  return (
                                    <Tr key={index}>
                                      <Td className="pl-1">{index + 1}</Td>
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc mt-2 mr-1"
                                          id="dns"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                key={index}
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                                className="ProductName d-flex"
                                              >
                                                <span
                                                  className=""
                                                  style={{ width: "20%" }}
                                                >
                                                  <b className="">
                                                    Request ID &nbsp;
                                                    &nbsp;:&nbsp;
                                                  </b>{" "}
                                                  <span>
                                                    {item.requestId}
                                                  </span>
                                                </span>
                                                <span
                                                  className=" "
                                                  style={{ width: "33%" }}
                                                >
                                                  <b className="">
                                                    Return Name &nbsp;
                                                    &nbsp;:&nbsp;
                                                  </b>{" "}
                                                  <span>
                                                    {item.returnerName}
                                                  </span>
                                                </span>


                                                {
                                                  item.requestSelfOthers?.toLowerCase() !== "self" &&
                                                  item.requestSelfOthers &&
                                                  item.acceptorName && (
                                                    <span style={{ width: "29%" }}>
                                                      <b>On Behalf Of &nbsp;:</b>
                                                      <span>&nbsp;&nbsp;{item.employeeName}</span>
                                                    </span>
                                                  )
                                                }

                                                <span
                                                  className=""
                                                  style={{ width: "18%" }}
                                                >
                                                  <b>
                                                    Status &nbsp; &nbsp;: &nbsp;
                                                  </b>
                                                  <span>
                                                    {item.requestStatusCode ===
                                                      2 ? (
                                                      <span
                                                        style={{
                                                          color: "#00CB0E",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Accepted
                                                      </span>
                                                    ) : item.requestStatusCode ===
                                                      3 ? (
                                                      <span
                                                        style={{
                                                          color: "#D50101", fontWeight: 600,
                                                        }}
                                                      >
                                                        Rejected
                                                      </span>
                                                    ) : item.requestStatusCode ===
                                                      0 ? (
                                                      <span
                                                        style={{
                                                          color: "Orange",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Pending
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          color: "orange",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Partially Accept
                                                      </span>
                                                    )}
                                                  </span>
                                                  &nbsp; &nbsp;
                                                </span>

                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="">
                                                {item.productCategories.map(
                                                  (category, pcIndex) => {
                                                    return (
                                                      <div className="request-card mt-3">
                                                        <div className="mt-3">


                                                          <div className="d-flex gap-4 align-items-center mb-3">

                                                            <p style={{ fontSize: 16 }} className="mb-0">
                                                              <b
                                                                style={{
                                                                  fontSize: 18,
                                                                  color: "orange",
                                                                }}
                                                              >
                                                                Category{" "}
                                                              </b>{" "}
                                                              <span
                                                                style={{
                                                                  marginLeft: 47,
                                                                }}
                                                              >
                                                                :{" "}
                                                              </span>{" "}
                                                              &nbsp;
                                                              {category.categoryName}
                                                            </p>

                                                            <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex"   >
                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                                  Returned Date
                                                                </span>
                                                                <span >  :&nbsp;
                                                                </span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>{newDateFormat}</span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          {category.products.map(
                                                            (
                                                              product,
                                                              productIndex
                                                            ) => {
                                                              return (
                                                                <>
                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" key={productIndex}>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Product  Name
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productName}</span>
                                                                    </span>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Required  Quantity
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productRequiredQuantity}</span>
                                                                    </span>
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Returned  Location
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.productReturnedLocation}</span>
                                                                    </span>
                                                                  </div>

                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" >
                                                                    {
                                                                      item.requestStatusCode === 3 ? "" : (
                                                                        <span style={{ width: '33.4%' }}>
                                                                          <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                            Issued Quantity
                                                                          </span>
                                                                          <span >  :&nbsp;
                                                                          </span>
                                                                          <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.issuedQuantity}</span>
                                                                        </span>
                                                                      )
                                                                    }

                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Total Order Value
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.totalOrderValue}</span>
                                                                    </span>
                                                                  </div>
                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" >
                                                                    <span style={{ width: '33.4%' }}>
                                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                        Unit Price
                                                                      </span>
                                                                      <span >  :&nbsp;
                                                                      </span>
                                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productUnitPrice}</span>
                                                                    </span>

                                                                  </div>
                                                                  <div style={{ fontSize: 14, width: "100%", }}
                                                                    className="ProductNameForRandM d-flex mt-3" >



                                                                    {
                                                                      item.requesterRemarks !== '' ? (
                                                                        <span style={{ width: '33.4%' }}>
                                                                          <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                            Requester Remarks
                                                                          </span>
                                                                          <span >  :&nbsp;
                                                                          </span>
                                                                          <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requesterRemarks}</span>
                                                                        </span>) : ''
                                                                    }
                                                                    {
                                                                      item.accepterRemarks !== '' ? (
                                                                        <span style={{ width: '33.4%' }}>
                                                                          <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                                            Acceptor Remarks
                                                                          </span>
                                                                          <span >  :&nbsp;
                                                                          </span>
                                                                          <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.accepterRemarks}</span>
                                                                        </span>) : ''
                                                                    }
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                              <div
                                                style={{ fontSize: 14 }}
                                                className="mt-2 ml-3"
                                              ></div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    </Tr>
                                  );
                                })
                              }
                            </Tbody>

                          </Table>
                          {isLoading && (
                            <Loader
                              loading={isLoading}
                              message={"Fetching Data. Please Wait..!"}
                              loderimage={loaderImage}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      <Logout />
    </body>
  );
};
export default History;
