import React, { useState, useEffect } from "react";
import { Chart, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2";
import { farmOperationGetRequestFullfill } from "../Utilities/URLCONSTANTS";
import axios from "axios";

Chart.register(...registerables);

const FarmOperationRequestFullfill = () => {

  const [MonthlyData, setMonthlyData] = useState([]);

  const fetchData = async () => {
    const RequestMonthlyChartRequest = farmOperationGetRequestFullfill
    try {
      const response = await axios.get(RequestMonthlyChartRequest);
      console.log("RequestMonthlyChartRequest" + JSON.stringify(response.data));
      setMonthlyData(response.data.response.datasets);
    } catch (error) {
      console.log("RequestMonthlyChartRequest", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
      title: {
        display: true,
        align: 'top',
      },
      datalabels: {
        color: '#000',
        font: {
          size: 10,
          weight: 'bold',
        },
        formatter: (value, context) => {
          return value && value !== 0 ? value : null;
        },
        anchor: 'end',
        align: 'top',
        offset: -8,
      },
    },
    scales: {
      x: {
        position: 'bottom',
      },
      y: {
        position: 'left',
      },
    },
  };



  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //       labels: {
  //         boxWidth: 10,
  //         padding: 10,
  //       },
  //     },
  //     title: {
  //       display: true,
  //       align: 'top',
  //     },
  //     datalabels: {
  //       color: '#000',
  //       font: {
  //         size: 10,
  //         weight: 'bold',
  //       },
  //       formatter: (value, context) => {
  //         return value && value !== 0 ? value : null;
  //       },
  //       anchor: 'end',
  //       align: 'middle',
  //     },
  //   },
  //   scales: {
  //     x: {
  //       position: 'bottom',
  //     },
  //     y: {
  //       position: 'left',
  //     },
  //   },
  // };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const data = {
    labels,
    datasets: MonthlyData
  };


  return (
    <>
      <div className="card p-4  ">
        <label className="chartname">Operation Full Filled With Month</label>
        <div className="barchat chart-container14" style={{ height: '230px', marginTop: '-40px' }} >
          <Bar options={options} data={data} />
        </div>
      </div>
    </>
  )
}

export default FarmOperationRequestFullfill