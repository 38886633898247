import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button, Modal } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import { FarmOperation_getMasters, ImplementMasterAdd, ImplementMasterGet, } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";

export const ImplementMaster = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [getLocation, setGetLocation] = useState([])
    const [name, setName] = useState()
    const [locationName, setLocationName] = useState()
    const [locationId, setLocationId] = useState()
    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg , setResponseMsg]= useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [locationError, setLocationError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)

    const getData = async () => {
        const URL = ImplementMasterGet;
        try {
            setLoader(true)
            const response = await axios.get(URL) 
            setData(response.data.response)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    }

    const LocationDropdown = async () =>{
        const apiUrl = FarmOperation_getMasters;
        const headers = await GetApiHeaders();
        try {
            const response = await axios.get(apiUrl, {headers})
            setGetLocation(response.data.response.subSiteMaster)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
        LocationDropdown()
    }, [])

    const headersData = [
        {
            field: "name",
            header: " Implements Name",
        },
        {
            field: "locationid.name",
            header: "Location Name",
        },
      
        {
            field: "active",
            header: "Status",
        },
    ];

    const onEditClicked = (data) => { 
        console.log(data,"data")
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.name)
        setLocationName(data.locationid.name)
        setLocationId(data.locationid.id)
        setStatus(data.active)
        setError('')
        setLocationError('')
        setStatusError('')
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('')
        setLocationName('')
        setStatus('')
        setLocationId('')
        setError('')
        setLocationError('')
        setStatusError('')
    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) =>{
        const Input = e.target.value
        setName(Input)
        if (Input) {
            setError('')
        }
    }

    const handleLocation = (e) =>{
        const locationInput = e.target.value
        setLocationName(locationInput)
        const locationsID = e.target.options[e.target.selectedIndex].getAttribute("data-locationId");
        setLocationId(locationsID)
        if (locationInput) {
            setLocationError('')
        }
    }

    const handleStatus = (e) =>{
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }

    const handleSubmitData = async () =>{ 
        const apiUrl = ImplementMasterAdd;
        const reqData = { 
            id: saveEdit === "Submit" ? 0 : id,
            name: name,
            locationId : parseInt(locationId),
            locationName : locationName, 
            status  : status
         }
         const header = await GetApiHeaders() 
         try {
            if (!status) {
                setStatusError("Select Status")
            }
            if (!locationName) {
                setLocationError('Select Location')
            }
            if (!name) {
                setError('Enter Implements Id')   
            }
            if (name !=='' && locationName !=='' && status !=='') {
                setLoader(true)
                const response = await axios.post(apiUrl,reqData, {header} )
             if (response.data.statusCode == 200 || response.data.status == 500) {
                 setShowModal(false)
                 setMsgPopup(true)
                 setResponseMsg(response.data.message);
                 setLoader(false)
             }
            }
             
         } catch (error) {
            console.log(error )
         }
    }
    const handleOk = () =>{
        setMsgPopup(false)
        getData()
    }

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">My Master</h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text"> Implements Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add Implements Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader = {loader}
                                            FileName={'Implements'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'Implements Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12 mt-2'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                               Implements Id <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter Implement Id'value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1">
                                                                Location <span style={{ color: "red" }}>*</span> 
                                                            </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control" value={locationName}onChange={handleLocation}>
                                                                <option value="" disabled>Select Location Name</option> 
                                                                {
                                                                    getLocation.map((item,index)=>
                                                                    <option key={item.code} value={item.name} data-locationId={item.code}>
                                                                        {item.name}
                                                                    </option> )
                                                                }
                                                            </select>
                                                             <span style={{ color: "red" }}> {locationError}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                            >Status <span style={{ color: "red" }}>*</span> </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control p-2" value={status} onChange={handleStatus}>
                                                                <option value="" disabled>Select Status</option> 
                                                                <option value={true}>Active</option>
                                                                <option value={false}>In-Active</option>
                                                            </select>
                                                        <span style={{ color: "red" }}> {statusError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal 
                                         showModal={msgPopup}
                                        msgPopup ={msgPopup}
                                        handleOk= {handleOk}
                                        handleCancel_new={handleCancel_new}
                                        tittle={'Implements Master'}
                                        childrens={<div className="p-4 d-flex justify-content-center" style={{fontSize:"18px", color:"green", fontWeight:500}}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}