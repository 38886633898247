import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../../src/Pages/SideMenu";
import Footer from "../../src/Pages/Footer";
import Header from "../../src/Pages/Header";
import Logout from "../../src/Pages/Logout";
import { strings } from "../Utilities/strings";
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  dailyWorkLogView,
  dailyWorkLogAssignedToMe,
  dailyWorkLogUpdateWork,
  rmGetmasters,
  dailyWorkLogViewById,
} from "../Utilities/URLCONSTANTS";

// import {Oval as Loader} from 'react-loader-spinner';
import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import UploadImg from "../images/uploadImg.png";
import * as XLSX from "xlsx";
import moment from "moment";
import { USER_PROFILE } from "../Utils";
import Loader_Bulk from "../Utilities/Loader_Bulk";

//UnitMeasureMaster
const DailyWorkAllocation = ({ }) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [id, setId] = useState("");
  const [location, setLocation] = useState("");
  const [subLocation, setSubLocation] = useState();
  const [equipment, setEquipment] = useState("");
  const [date, setDate] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [workCreatedDate, setWorkCreatedDate] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [status, setStatus] = useState();
  const [remark, setRemark] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Unit Measure Master"
  );
  const [loading, setLoading] = useState(false);
  const [deleteItems, setDeleteItems] = useState("");
  const [errLocation, setErrLocation] = useState("");
  const [errSubLocation, setErrSubLocation] = useState("");
  const [errDate, setErrDate] = useState("");
  const [errEquipment, setErrEquipment] = useState("");
  const [errAssignTo, setErrAssignTo] = useState("");
  const [errWorkCreated, setErrWorkCreated] = useState("");
  const [errRemark, setErrRemark] = useState("");
  const [errStatus, setErrStatus] = useState("");
  const [getData, setGetData] = useState([]);
  const [locationCode, setLocationCode] = useState([]);
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [editValue, setEditValue] = useState();
  // const [error, setError] = useState("");
  const [modalData, setModalData] = useState({
    id: "",
    roleName: "",
    description: "",
    status: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [serverRecordId, setServerRecordId] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [generalList, setGeneralList] = useState([]);
  const [generalWork, setGeneralWork] = useState();
  const [errgeneralWork, setErrGeneralWork] = useState("");
  const [corePermit, setCorePermit] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [fullData, setFullData] = useState([]);
  const [getSubLocations, setGetSubLocations] = useState([]);
  const [getSubLocationsArray, setSubLocationsArray] = useState([]);
  const [getEquipments, setGetEquipments] = useState([]);
  const [getEquipmentsArray, setGetEquipmentsArray] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [assigneName, setAssigneName] = useState("");
  const [assigneMobile, setAssigneMobile] = useState();
  const [assigneUser, setAssigneUser] = useState();
  const [getLogin, setGetLogin] = useState([]);
  const [equipmentName, setEquipmentName] = useState();
  const [subLocationName, setSubLocationName] = useState();
  const [generalName, setGeneralName] = useState();
  const [getAssignedToData, setGetAssignedToData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data.length > 0) {
      const filteredResults = data.filter(
        (item) => {
          return (
            item.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.subLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.equipment.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.assignWorkToName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.workCreatedDate
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.remarks.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.status.toLowerCase().includes(searchTerm.toLowerCase())
          )
        });
      searchTerm === "" ? setFullData(data) : setFullData(filteredResults);
      handlePageClick(1);
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      setFilteredData(data);
      setTotalItems(data.length);
    }
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetLogin(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, [data, getSubLocationsArray]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "generalWorks",
        "location",
        "subLocation",
        "equipment",
        "date",
        "assignWorkToName",
        "workCreatedDate",
        "corePermitRequired",
        "remarks",
        "Status",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.generalWorks,
        item.location,
        item.subLocation,
        item.equipment,
        item.date,
        item.assignWorkToName,
        item.workCreatedDate,
        item.corePermitRequired,
        item.remarks,
        item.status,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "DailyWorkAllocation.xlsx");
  };

  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowMsg(true);
    // window.location.reload();
  };
  const handleClose = () => {
    //yes
    // alert("handleCancel");
    // setPopupText('Custom popup text here');
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalOpen = (item, index) => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId("");
    setLocation("");
    setSubLocation("");
    setEquipment("");
    setAssignTo("");
    setDate("");
    setWorkCreatedDate("");
    setRemark("");
    setStatus("");
    setModalData({
      id: "",
      location: "",
      subLocation: "",
      equipment: "",
      date: "",
      assignTo: "",
      workCreatedDate: "",
      remark: "",
      status: "",
    });
    setSelectedItem(item);
    setSelectedIndex(index);
  };
  const handleLocation = (e) => {
    const subLocationsArray = [];
    setLocation(e.target.value);
    const selectedLocation = e.target.options[e.target.selectedIndex].getAttribute("data-location");
    setLocationCode(selectedLocation);
    if (e.target.value) {
      getSubLocations.find((item) => {
        if (item.locationId === parseInt(e.target.value)) {
          subLocationsArray.push(item);
        }
      });
      setSubLocationsArray(subLocationsArray);
      setErrLocation("");
      console.log("subLocationsArray" + JSON.stringify(getSubLocationsArray));
    } else {
      setErrLocation("Select Location");
      setSubLocationsArray([]);
    }
  };
  const handleSubLocation = (e) => {
    setSubLocation(e.target.value);
    const EquipmentArray = [];
    const selectedSubLocationName = e.target.options[
      e.target.selectedIndex
    ].getAttribute("data-subLocationName");
    setSubLocationName(selectedSubLocationName);

  };
  const handleEquipment = (e) => {
    const selectedEquipment = e.target.value;
    setEquipment(selectedEquipment);
    const selectedEquipmentName =
      e.target.options[e.target.selectedIndex].getAttribute(
        "data-equipmentName"
      );
    setEquipmentName(selectedEquipmentName);
    if (e.target.value) {
      setErrEquipment("");
    }
  };
  const handleAssignTo = (e) => {
    setAssignTo(e.target.value);
    const selectedName =
      e.target.options[e.target.selectedIndex].getAttribute("data-Name");
    const selectedUser =
      e.target.options[e.target.selectedIndex].getAttribute("data-user");
    const selectedMobile =
      e.target.options[e.target.selectedIndex].getAttribute("data-mobileNo");

    setAssigneMobile(selectedMobile);
    setAssigneName(selectedName);

    setAssigneUser(selectedUser);
    if (e.target.value) {
      setErrAssignTo("");
    }
  };
  const generalSel = useRef(null);
  useEffect(() => {
    console.log("assigneName", assigneMobile);
  }, [assigneName, assigneMobile, assigneUser, generalName]);
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }
    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, []);
  const handleGeneralList = (e) => {
    setGeneralWork(e.target.value);
    const selectedGeneralName = e.target.options[
      e.target.selectedIndex].getAttribute("data-generalWorkName");
    setGeneralName(selectedGeneralName);
  };
  const fetchData = async () => {
    const fetchDataApi = dailyWorkLogView;
    const headers = await GetApiHeaders();

    try {
      setIsLoading(true);
      const response = await axios.post(fetchDataApi, {}, { headers });
      console.log("fetchDataResponse: ", response);
      setData(response.data.response.dailyWorkLog);
      setFullData(response.data.response.dailyWorkLog);
      setIsLoading(false);
    } catch (error) {
      console.log("fetchData Error :", error);
    }
  };
  useEffect(() => {
    fetchData();
    getMasterData();
  }, []);
  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      const responseGetData = await axios.get(getData, { headers });
      console.log("resssss,", responseGetData);
      setGetData(responseGetData.data.response.workLogLocation);
      setGeneralList(responseGetData.data.response.generalWorksList);
      setGetSubLocations(responseGetData.data.response.workLogSubLocation);
      setGetEquipments(responseGetData.data.response.workLogEquipment);
      setGetAssignedToData(responseGetData.data.response.employees);
    } catch (err) {
      console.log("getError", err);
    }
  };
  const handleDate = (e) => {
    setDate(e.target.value);
    if (e.target.value) {
      setErrDate("");
    }
  };
  const handleChecks = (value) => {
    setCorePermit(value);
  };
  const handleSubmit = async (index) => {
    if (equipment === "") {
      setErrEquipment("Please select Equipment");
    }
    if (location === "") {
      setErrLocation("Please select Location");
    }
    if (date === "") {
      setErrDate("Please select Date");
    }
    if (subLocation === "") {
      setErrSubLocation("Please select SubLocation");
    }
    if (assignTo === "") {
      setErrAssignTo("Please select AssignTo");
    }

    const submitApi = dailyWorkLogAssignedToMe;
    const headers = await GetApiHeaders();

    const postData = {
      assignWorkToId: assignTo,
      assignWorkToMobileNumber: assigneMobile,
      assignWorkToName: assigneName,
      assignedByEmpId: getLogin.userId,
      assignedByEmpMobileNumber: getLogin.mobileNumber,
      assignedByEmpName: getLogin.firstName + " " + getLogin.lastName,
      userType: getLogin.roleTypeName,
      date: date,
      equipment: equipmentName,
      equipmentId: equipment,
      location: locationCode,
      locationId: location,
      remarks: remark,
      status: "Assigned",
      subLocationId: subLocation,
      subLocation: subLocationName,
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
      generalWorksId: generalWork,
      generalWorks: generalName,
      corePermitRequired: corePermit,
    };
    console.log("postData", postData);

    try {
      if (
        date != "" &&
        assignTo != "" &&
        location != "" &&
        subLocation != "" &&
        equipment != ""
      ) {
        const response = await axios.post(submitApi, postData, { headers });
        console.log("res----------:", response);

        if (response.data.statusCode == 200) {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
        }
        if (response.data.statusCode == 500) {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
        }
        setErrAssignTo("");
        setErrEquipment("");
        setErrDate("");
        setErrLocation("");
        setErrSubLocation("");
        setErrStatus("");
        setIsSubmitted(true);
      }
    } catch (error) {
      console.log("error,", error);
    }
  };
  const handleUpdate = async (id, selectedIndex) => {
    var selectedGeneralName = "";
    var updateLocation = "";
    var selectedSublocation = "";
    var updateEquipment = "";
    var selectedAssignto = "";
    var selectedMobile = "";
    const updateApi = dailyWorkLogUpdateWork;
    const headers = await GetApiHeaders();
    const serverRecordId = id ? parseInt(id) : null;
    generalList.map((v, i) => {
      if (v.code === parseInt(generalWork)) {
        selectedGeneralName = v.name;
      }
    });

    getData.map((v, i) => {
      if (v.code === parseInt(location)) {
        updateLocation = v.name;
      }
    });
    getSubLocationsArray.map((v, i) => {
      if (v.code === parseInt(subLocation)) {
        selectedSublocation = v.name;
      }
    });
    getEquipments.map((v, i) => {
      if (v.code === parseInt(equipment)) {
        updateEquipment = v.name;
      }
    });
    getAssignedToData.map((v, i) => {
      if (v.code === parseInt(assignTo)) {
        selectedAssignto = v.name;
        selectedMobile = v.mobileNumber;
      }
    });

    const updateData = {
      assignWorkToId: assignTo.toString(),
      assignWorkToMobileNumber: selectedMobile,
      assignWorkToName: selectedAssignto,
      assignedByEmpId: getLogin.userId,
      assignedByEmpMobileNumber: getLogin.mobileNumber,
      assignedByEmpName: getLogin.firstName + " " + getLogin.lastName,
      userType: getLogin.roleTypeName,
      date: date,
      equipment: updateEquipment,
      equipmentId: equipment,
      location: updateLocation,
      locationId: location,
      remarks: remark,
      status: "Assigned",
      subLocationId: subLocation,
      subLocation: selectedSublocation,
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
      generalWorksId: generalWork,
      generalWorks: selectedGeneralName,
      corePermitRequired: corePermit,
      serverRecordId: serverRecordId,
    };
    console.log("postDataCheck", updateData);
    try {
      const response = await axios.post(updateApi, updateData, { headers });
      console.log("updatedResponse", response);

      if (response.data.statusCode == 200) {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);
      }
      if (response.data.statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const updatedEItems = (item) => {
    setId(item.serverRecordId);
    setSubLocationsArray(getSubLocations);
    setSubLocation(item.subLocation);
    setEquipment(item.equipment);
    setDate(item.date);
    setCorePermit(item.corePermitRequired);
    setGeneralWork(item.generalWorks);
    setAssignTo(item.assignWorkTo);
    setWorkCreatedDate(item.workCreatedDate);
    setRemark(item.remarks);
    setStatus(item.status);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(true);
  };
  const handleEditLocation = (id) => {
    const subLocationsArray = [];
    setLocation(id);
    setLocationCode(id);
    if (id) {
      getSubLocations.find((item) => {
        if (item.locationId === parseInt(id)) {
          subLocationsArray.push(item);
        }
      });
      setSubLocationsArray(subLocationsArray);
      setErrLocation("");
      console.log("subLocationsArray" + JSON.stringify(getSubLocationsArray));
    } else {
      setErrLocation("Select Location");
      setSubLocationsArray([]);
    }
  };
  const handleEditEquipment = (id) => {
    setEquipment(id);
  };
  const handleEditGeneral = (id) => {
    setGeneralWork(id);
  };
  const handleEditSublocation = (id) => {
    const EquipmentArray = [];
    if (id) {
      getEquipments.find((item) => {
        if (item.subLocationId === parseInt(id)) {
          EquipmentArray.push(item);
        }
      });
      setGetEquipmentsArray(EquipmentArray);
      setErrSubLocation("");
      console.log("Equipmnet Array" + JSON.stringify(EquipmentArray));
    } else {
      setErrSubLocation("Select Location");
      setGetEquipmentsArray([]);
    }
  };
  const updatedItems = async (item) => {
    console.log(item, "selcted row data")
    const id = item.serverRecordId;
    setId(id);
    const apiEdit = dailyWorkLogViewById;
    const headers = await GetApiHeaders();

    try {
      const response = await axios.get(`${apiEdit}?id=${id}`, {
        headers,
      });
      console.log('cheking respons', response);
      const onEdit = response.data.response.dailyWorkLog[0];

      generalList.map((v, i) => {
        if (v.code === parseInt(onEdit.generalWorksId)) {
          setGeneralWork(v.code);
          handleEditGeneral(v.code);
        }
      });
      getData.map((v, i) => {
        debugger;
        if (v.code === parseInt(onEdit.locationId)) {
          setLocation(v.code);
          handleEditLocation(v.code);
          setSubLocation(onEdit.subLocationId);
          handleEditSublocation(onEdit.subLocationId);
          setEquipment(onEdit.equipmentId);
          setAssignTo(onEdit.assignWorkToId);
          setDate(onEdit.date);
          setCorePermit(onEdit.corePermitRequired);
          setRemark(onEdit.remarks);
        }
      });
      setShowModal(true);

      setButtonText("Update");
      setIsSubmitted(false);
      setIsUpdated(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key]?.toString().toLowerCase();
      const bValue = b[sortConfig.key]?.toString().toLowerCase();
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };



  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text"> Daily Work Allocation</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text">Daily Work Allocation</h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Assign to work"
                      >
                        <Button
                          className=""
                          variant="primary"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Assign Work
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                        &nbsp;&nbsp;
                        <label> Entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* Search Input */}
                        <input
                          type="text"
                          placeholder="🔍 Type to Search..."
                          value={searchTerm}
                          onChange={(e) => handleFilter(e.target.value)}
                          style={{ height: "36px", width: "160px" }}
                          className="mr-1"
                        />{" "}
                        &nbsp;
                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                          <button
                            className="btn btn-secondary buttons-excel buttons-html5"
                            onClick={exportToExcel}
                          >
                            Download Excel
                          </button>

                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <div style={{ overflow: "auto" }}>
                            <Table className="table table-bordered  table-striped">
                              <Thead>
                                <Tr>
                                  <Th className="text-center p-1">S.No</Th>

                                  <Th className="text-center p-1" onClick={() => handleSort("generalWorks")}>
                                    General Works{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "generalWorks"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>

                                  <Th className="text-center p-1" onClick={() => handleSort("corePermitRequired")}>
                                    Core Permit{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "corePermitRequired"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center p-1" onClick={() => handleSort("location")}>
                                    Location{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "location"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>

                                  <Th className="text-center p-1" onClick={() => handleSort("subLocation")}>
                                    Sub-Location{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "subLocation"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center" onClick={() => handleSort("equipment")}>
                                    Equipment{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "equipment"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>
                                  <Th className="text-center " onClick={() => handleSort("date")}>
                                    Date{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "date"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>

                                  <Th className="text-center " onClick={() => handleSort("assignWorkToName")}>
                                    Assign Work To{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "assignWorkToName"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>

                                  <Th className="text-center " onClick={() => handleSort("remarks")}>
                                    Remarks{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "remarks"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>

                                  <Th className="text-center " onClick={() => handleSort("status")}>
                                    Status{" "}
                                    <i
                                      className={`fa ${sortConfig.key === "status"
                                        ? sortConfig.direction === "asc"
                                          ? "fa-sort-up"
                                          : "fa-sort-down"
                                        : "fa-sort"
                                        } fa-xs icon-opacity`}
                                    ></i>
                                  </Th>

                                  <Th className="text-center">Actions</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {/* {data.map((item, index) => ( */}
                                {/* {fullData.map((item, index) => { */}
                                {sortedItems.map((item, index) => {
                                  var newDateFormat = moment(item.date).format("DD-MMM-YYYY ");

                                  return (
                                    <Tr key={index}>
                                      <Td className="text-left"> {(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                      <Td className="text-left">
                                        {item.generalWorks}
                                      </Td>
                                      <Td className="text-left">
                                        {item.corePermitRequired}
                                      </Td>
                                      <Td className="text-left">
                                        {item.location}
                                      </Td>
                                      <Td className="text-left">
                                        {item.subLocation}
                                      </Td>
                                      <Td className="text-center">
                                        {item.equipment}
                                      </Td>
                                      <Td className="text-center">
                                        {newDateFormat}
                                      </Td>
                                      <Td className="text-center">
                                        {item.assignWorkToName}
                                      </Td>
                                      <Td className="text-center">
                                        {item.remarks}
                                      </Td>
                                      <Td
                                        className="text-center"
                                        style={{ color: "#FFA500" }}
                                      >
                                        {item.status}
                                      </Td>

                                      <Td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div onClick={() => updatedItems(item)}>
                                          <FontAwesomeIcon
                                            icon={faEdit}
                                            className="mr-1 edit_delete_icon text-primary "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit Daily Work Allocation"
                                          />
                                        </div>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                              </Tbody>

                            </Table>
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5 }}
                        >
                          <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                            &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                            of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                                  }`}

                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button

                                className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                                  }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                    }`}

                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                    }`}

                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel_new}
                              >
                                {" "}
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">Assign Work</span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Type Of Work
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={generalWork}
                                      onChange={handleGeneralList}
                                      id="generalListDropdown"
                                      ref={generalSel}
                                    >
                                      <option value="">
                                        Select Type Of Work
                                      </option>
                                      {generalList.map((items) => {
                                        return (
                                          <option
                                            key={items.code}
                                            value={items.code}
                                            data-generalWorkName={items.name}
                                          >
                                            {items.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label text-sm font-weight-600 mb-0"
                                    >
                                      Core Permit Required ?
                                    </label>
                                    <div className="mt-2">
                                      <label>
                                        {" "}
                                        <input
                                          type="radio"
                                          name="corePermit"
                                          onChange={() => handleChecks("Yes")}
                                          value={corePermit}
                                          checked={corePermit === "Yes"}
                                        />{" "}
                                        Yes{" "}
                                      </label>{" "}
                                      &nbsp;
                                      <label>
                                        {" "}
                                        <input
                                          type="radio"
                                          name="corePermit"
                                          checked={corePermit === "No"}
                                          value={corePermit}
                                          onChange={() => handleChecks("No")}
                                        />{" "}
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Location
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={location}
                                      onChange={handleLocation}
                                    >
                                      <option value="">Select Location</option>
                                      {getData.map((items) => {
                                        return (
                                          <option
                                            key={items.code}
                                            data-location={items.name}
                                            value={items.code}
                                          >
                                            {items.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errLocation}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Sub-Location
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={subLocation}
                                      onChange={handleSubLocation}
                                    >
                                      <option value="" hidden>
                                        Select subLocation
                                      </option>
                                      {getSubLocationsArray.map((items) => {
                                        console.log(
                                          "subLocatiosn-----1" + items
                                        );
                                        return (
                                          <option
                                            key={items.code}
                                            value={items.code}
                                            data-subLocationName={items.name}
                                          >
                                            {items.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>{errSubLocation} </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Equipment
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={equipment}
                                      da
                                      onChange={handleEquipment}
                                    >
                                      <option value="">Select Equipment</option>
                                      {getEquipments.map(
                                        (items, itemsIndex) => {
                                          return (
                                            <option
                                              key={items.code}
                                              value={items.code}
                                              data-equipmentName={items.name}
                                            >
                                              {items.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>

                                  <span style={{ color: "red" }}>
                                    {errEquipment}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label text-sm font-weight-600 mb-0"
                                    >
                                      Date
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="date"
                                      id="textAreaExample1"
                                      rows="2"
                                      value={date}
                                      min={getCurrentDate()}
                                      onChange={handleDate}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errDate}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Assign Work To
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={assignTo}
                                      onChange={handleAssignTo}
                                    >
                                      <option value="">Select </option>
                                      {getAssignedToData.map(
                                        (items, itemsIndex) => {
                                          return (
                                            <option
                                              key={items.code}
                                              value={items.code}
                                              data-mobileNo={items.mobileNumber}
                                              data-Name={items.name}
                                              data-user={items.userType}
                                            >
                                              {items.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>

                                  <span style={{ color: "red" }}>
                                    {errAssignTo}
                                  </span>
                                </div>
                                {/* <div className='col-md-6 col-12 '>
                                  <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label text-sm font-weight-600 mb-0"   >Work submitted Date<span style={{ color: "red" }}>*</span></label>
                                    <input className="form-control" type='date'  id="textAreaExample1" rows="2" value={workCreatedDate} onChange={(e)=>setWorkCreatedDate(e.target.value)}  />
                                  </div>
                                  <span style={{ color: 'red' }}>{errDate}</span>
                                </div> */}

                                <div className="col-md-6 col-12">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label text-sm font-weight-600 mb-0"
                                    >
                                      Remarks
                                    </label>
                                    <textarea
                                      class="form-control"
                                      style={{ height: 115 }}
                                      id="textAreaExample1"
                                      rows="2"
                                      value={remark}
                                      onChange={(e) =>
                                        setRemark(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              &nbsp;
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() =>
                                    buttonText === "Submit"
                                      ? handleSubmit(
                                        selectedItem,
                                        selectedIndex
                                      )
                                      : handleUpdate(
                                        id,
                                        selectedItem,
                                        selectedIndex
                                      )
                                  }
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            className="modalBlur"
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Assign Work</span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close this Window
                                    ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Assign Work</span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                <span className="modal_title">
                                  Daily Work Allocation
                                </span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <Loader_Bulk
                    loading={isLoading}
                    message={"Fetching Data. Please Wait..!"}
                    loderimage={loaderImage}
                  />
                )}
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default DailyWorkAllocation;
