import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CorePermitInputFields(props) {
  const permitsOneRef = useRef(null); // Create a ref for permitsOne input field
  const dateInputRef = useRef(null); // Create a ref for Date input field

  const handleInitialPermits = (key, val) => {
    props.setPermitInitials((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <div
      className="d-flex"
      style={{
        padding: "2px",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        borderRadius: " 5px",
        border: "1px solid gray",
      }}
    >
      <p
        style={{
          color: "#000",
          margin: "5px 5px",
          fontSize: "12px",
          fontWeight: "bolder",
        }}
      >
        Excavation Permit #:
      </p>
      {/* Intilas */}
      <div>
        <input
          id="initialsOne"
          type="text"
          className="microsite-txtbx"
          value={props.data.initialsOne}
          maxLength={"1"}
          onChange={(e) => {
            const inputValue = e.target.value;
            const alphabeticValue = inputValue.replace(/[^a-zA-Z]/g, "");
            e.target.value = alphabeticValue;
            handleInitialPermits("initialsOne", alphabeticValue);
            if (alphabeticValue.length === 1) {
              document.getElementById("initialsTwo").focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && props.data.initialsOne === "") {
              e.preventDefault();
            }
          }}
        />

        <input
          id="initialsTwo"
          type="text"
          className="microsite-txtbx"
          maxLength={"1"}
          value={props.data.initialsTwo}
          onChange={(e) => {
            const inputValue = e.target.value;
            const alphabeticValue = inputValue.replace(/[^a-zA-Z]/g, "");
            e.target.value = alphabeticValue;
            handleInitialPermits("initialsTwo", alphabeticValue);
            if (alphabeticValue.length === 1) {
              document.getElementById("initialsThree").focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && props.data.initialsTwo === "") {
              document.getElementById("initialsOne").focus();
            }
          }}
        />

        <input
          id="initialsThree"
          type="text"
          className="microsite-txtbx"
          maxLength={"1"}
          value={props.data.initialsThree}
          onChange={(e) => {
            const inputValue = e.target.value;
            const alphabeticValue = inputValue.replace(/[^a-zA-Z]/g, "");
            e.target.value = alphabeticValue;
            handleInitialPermits("initialsThree", alphabeticValue);
            if (alphabeticValue.length === 1) {
              const nextInput = dateInputRef.current;
              if (nextInput) nextInput.setFocus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && props.data.initialsThree === "") {
              document.getElementById("initialsTwo").focus();
            }
          }}
        />
        <p className="header-lbl">Initials</p>
      </div>
      {/* Date */}
      <div className="datePicker">
        <DatePicker
          ref={dateInputRef}
          className="microsite-txtbxdate"
          selected={
            props.data.dateInputOne &&
              !isNaN(Date.parse(props.data.dateInputOne))
              ? new Date(props.data.dateInputOne)
              : new Date()
          }
          minDate={new Date()}
          maxDate={new Date()}
          dateFormat="dd-MMM-yyyy"
          onChange={(date) => {
            handleInitialPermits("dateInputOne", date);
            // permitsOneRef.current.focus();
          }}
          onKeyDown={(e) => {
            if (
              !(
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode === 8 ||
                e.keyCode === 46
              )
            ) {
              e.preventDefault();
            }
          }}
        />

        <p className="header-lbl">Date</p>
      </div>
      {/* Permits */}
      <div className="ml-">
        <input
          ref={permitsOneRef}
          type="text"
          className="microsite-txtbx"
          value={props.data.permitsOne}
          maxLength={"1"}
          onChange={(e) => {
            const inputValue = e.target.value;
            const numericValue = inputValue.replace(/[^0-9]/g, "");
            e.target.value = numericValue;
            handleInitialPermits("permitsOne", numericValue);
            if (numericValue.length === 1) {
              const nextInput = document.getElementById("permitsTwo");
              if (nextInput) nextInput.focus();
            }
          }}

          onKeyDown={(e) => {
            if (e.key === "Backspace" && props.data.permitsOne === "") {
              const dateInput = dateInputRef.current;
              if (dateInput && dateInput.input) {
                dateInput.input.focus();
              }
              e.preventDefault();
            }
          }}
        />

        <input
          id="permitsTwo"
          type="text"
          className="microsite-txtbx"
          value={props.data.permitsTwo}
          maxLength={"1"}
          onChange={(e) => {
            const inputValue = e.target.value;
            const numericValue = inputValue.replace(/[^0-9]/g, ""); // Allow only numeric characters
            e.target.value = numericValue;
            handleInitialPermits("permitsTwo", numericValue);
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && props.data.permitsTwo === "") {
              const previousInput = permitsOneRef.current;
              if (previousInput) previousInput.focus();
            }
          }}
        />
        <p className="header-lbl">#Permits</p>
      </div>
    </div>
  );
}

export default CorePermitInputFields;
