import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  getCorePermitApprovedData,
  getEquipmentBreakdownCount,
} from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Chart.register(...registerables);

const CorePermitApproved = () => {
  // const [requestedCount, setRequestedCount] = useState([]);
  const [RequestDelvLabels, setRequestDelvLabels] = useState([]);
  const [selectedValue, setSelectedValue] = useState("All");
  const [hoveredPercentage, setHoveredPercentage] = useState(null);
  const [breakCount, setBreakCount] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [requestedCount, setRequestedCount] = useState([]);
  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;
    const corepermitApi = getCorePermitApprovedData;
    const postData = {
      type: (startDate && endDate) ? "" : selectedValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    try {
      const response = await axios.post(corepermitApi, postData);
      console.log("check the response", response);
      setRequestedCount(response.data.datasets);
      setRequestDelvLabels(response.data.labels);

      // setRequestedCount(response.data.response.datasets.data);
      // setRequestDelvLabels(response.data.labels);
    } catch (error) {
      console.log("RequestCount", error);
    }
  };
  const fetchBreakCount = async () => {
    const apiFetch = getEquipmentBreakdownCount;
    const headers = await GetApiHeaders();
    try {
      const response = await axios.get(apiFetch, { headers });
      setBreakCount(response.data.response.datasets[0].data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBreakCount();
    const shouldFetchData = selectedValue === "DTD" && startDate && endDate;
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate);
    }
  }, [startDate, endDate, selectedValue]);

  const data = {
    labels: RequestDelvLabels,
    datasets: [requestedCount],
  };
  console.log(data, "get");
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "start",
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
      title: {
        display: true,
        text: " ",
        align: "start",
      },
      datalabels: {
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value, context) => {
          return value && value !== 0 ? value  : null;
        },
        anchor: 'end',
        align: 'start',
      },
    },
  };

  
  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return (
    <>
      <div className="card p-4">
        <label className="chartname">Core Permit Approved</label>
        <div className="d-flex  " id="filtersInputs">
          <select
            className="form-control w-100"
            value={selectedValue}
            onChange={handleSelect}
          >
            <option value="All">All</option>
            <option value="WTD">Current Week</option>
            <option value="MTD">Current Month</option>
            <option value="YTD">Yearly</option>
            <option value="DTD">Custom selection</option>
          </select>
        </div>
        {selectedValue === "DTD" && (
          <div className="d-flex">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="form-control  custom-datepicker"
            />
            <DatePicker
              style={{ marginLeft: "130px  " }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="form-control  custom-date"
            />
          </div>
        )}
        <div
          className="chart-container"
          style={{
            position: "relative",
            height: "260px",
            marginLeft: "auto",
            marginTop: "-130px",
          }}
        >
          {/* <div className="chart-container" style={{height:'244px',marginTop:'-53px', marginLeft:100}}></div> */}
          <Doughnut data={data} options={options} />
         
        </div>
        {/* <div className=' font-weight-600  '>Equipment Breakdown Dectected</div> */}
        <hr />
        <div className="d-flex">
          <span style={{ marginTop: "-10px" }} className="chartname">
            Equipment Breakdown Dectected{" "}
          </span>{" "}
          <span style={{ marginLeft: 100, fontSize: 22, fontWeight: 700 }}>
            {breakCount}
          </span>
        </div>
        <div
          style={{
            color: "gray",
            marginTop: "-20px",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {" "}
          (As on Date)
        </div>
      </div>
    </>
  );
};

export default CorePermitApproved;
