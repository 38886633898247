import React from "react";
import { getNewListWithModifyChecked } from "../../Utilities/utils";

const CorePermitReceiver = ({recData, setRecData,approvalStatus}) => {
  return (
    <>
      {
        <>
          <div class="form-group row">
            <label for="inputPassword" class="col-12 col-form-label">
              <b>The Core Permit Receiver will ensure that all workers.</b>
            </label>
          </div>
          {recData.corePermitReceiverList.map((data, index) => (
            <div class="form-group row">
              <div class="col-12">
                <div class="form-check form-check-inline">
                  <input
                    disabled={approvalStatus}
                    class="form-check-input  mt-1"
                    type="checkbox"                 
                    checked={data.checked}
                    onChange={() =>
                        setRecData({
                        ...recData,
                        corePermitReceiverList: getNewListWithModifyChecked(
                          index,
                          recData.corePermitReceiverList
                        ),
                      })
                    }
                  />
                  <label class="form-check-label  mt-0" >
                    {data.cbTitle}{" "}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </>
      }
    </>
  );
};

export default CorePermitReceiver;
