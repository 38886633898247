import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import CentralStore from "./Pages/CentralStore";
import RMTracking from "./Pages/RMTracking";
import FarmOperations from "./Pages/FarmOperations";
import RoleMaster from "./Pages/RoleMaster";
import AssetMaster from "./Pages/AssetMaster";
import MainAssertMaster from "./Pages/MainAssertMaster";
import UserMaster from "./Pages/UserMaster";
import UnitMeasureMaster from "./Pages/UnitMeasureMaster";
import MainStoreMaster from "./Pages/MainStoreMaster";
import StoreMaster from "./Pages/StoreMaster";
import RackMaster from "./Pages/RackMaster";
import SubAssetMaster from "./Pages/SubAssetMaster";
import ProductMaster from "./Pages/ProductMaster";
// import { MyContext } from './Pages/MyContext';
import CategoryMaster from "./Pages/CategoryMaster";
import RequestMaster from "./Pages/RequestMaster";
import RequestMasterTest from "./Pages/RequestMasterTest";
import { MOBILE_NUMBER, retrieveData } from "./Utils";
import StockInWard from "./CentralStore/Stocks/StockInWard";
import StockInwardReport from "./CentralStore/Stocks/stockInwardReport";
import RequestReport from "./CentralStore/Stocks/requestReport";
import ReturnReport from "./CentralStore/Stocks/returnReport";
import History from "./Pages/History";
import RequestOrderHistory from "./CentralStore/Stocks/RequestedOrderHistory";
import ReturnOrderHistory from "./CentralStore/Stocks/ReturnOrderHistory";
import OpenStockReport from "./CentralStore/Stocks/openStockReport";
import RoleAssetMapping from "./Pages/RoleAssetMapping";
import OpenStock from "./CentralStore/Stocks/OpenStock";

import ProductStatus from "./CentralStore/Stocks/ProductStatus";
//import CorePermitLogin from './Pages/CorePermitLogin';
import CorePermitM2 from "./Pages/CorePermitM2";
import CorePermitOne from "./Pages/CorePermitOne";
import CorePermitM3 from "./Pages/CorePermitM3";
import AdditionalPermitOptions from "./Pages/AdditionalPermitOptions";
import HotWorkPermit2_Web from "./Pages/HotWorkPermit2_Web";
import WorkPermit from "./R & M Pages/WorkPermit";
import RepairMaintenace from "./R & M Pages/R&M";

import CranePermitMain from "./Pages/CranePermitMain";
import CorePermitM2Acceptor from "./Pages/CorePermitM2Acceptor";
import CriticalElevated from "./Pages/CriticalElevated";
import ExcavationPermit from "./Pages/ExcavationPermit";
import HotWorkPermit2Main from "./Pages/HotWorkPermit2Main";
import HotWorkPermit2 from "./Pages/HotWorkPermit2";
import GeneralHotWork2Additional from "./Pages/GeneralHotWork2Additional";
import ExcavationPermit2Additional from "./Pages/ExcavationPermit2Additional";
import CriticalElevated2Additional from "./Pages/CriticalElevated2Additional";
import CorePermit_Web from "./Pages/CorePermit_Web";
import CriticalElevatedWork_Web from "./Pages/CriticalElevatedWork_Web";
import CranePermit2Additional from "./Pages/CranePermit2Additional";
import RequestFarmOperation from "./FarmOperation/RequestFarmOperation";

import UpdateActivity from "./FarmOperation/UpdateActivity";
import FarmOperationHistory from "./FarmOperation/FarmOperationHistory";
import RMhistory from "./R & M Pages/RMhistory";
import ExcavationPermit_Web from "./Pages/ExcavationPermit_Web";
import CraneOperationPermit_Web from "./Pages/CraneOperationPermit_Web";
import Notification from "./Pages/Notifications";
import FarmOperationsGenaralMaintanance from "./Pages/FarmOperationsGenaralMaintanance";
import FarmOperationsBoreWell from "./Pages/FarmOperationsBoreWell";
import FarmOperationsSprayServices from "./Pages/FarmOperationsSprayServices";
import FarmOperationsTractors from "./FarmOperation/FarmOperationsTractors";
import FarmOperationsReports from "./FarmOperation/FarmOperationsReports";
import FarmOperationHistoryAll from "./FarmOperation/FarmOperationHistoryAll";
//R&M Reports
import WorkPermitReport from "./R & M Pages/workPermitReport";
import RMReport from "./R & M Pages/rmReport";
// import NewRequest from "./R & M Pages/NewRequest";
import NewRequestReport from "./R & M Pages/NewRequestReport";
import EquipmentMonitoringReport from "./R & M Pages/EquipmentMonitoringReport";
import DailyWorkAllocationReport from "./R & M Pages/DailyWorkAllocationReport";
import BulkAllocationReport from "./R & M Pages/BulkAllocationReport";
import ShiftBasedReport from "./R & M Pages/ShiftBasedReport";

//import NewRequest from "./R & M Pages/newRequestReport";
import NewRequest from "./R & M Pages/NewRequest";
import BulkAllocation from "./R & M Pages/BulkAllocation";
import DailyWorkAllocation from "./R & M Pages/DailyWorkAllocation";
import EquipmentMonitoring from "./R & M Pages/EquipmentMonitoring";

// import WorkPermit1 from './R & M Pages/WorkPermit1';

import FarmOperationsImplements from "./FarmOperation/FarmOperationsImplements";
import FarmOperationsDripSystem from "./FarmOperation/FarmOperationsDripSystem";
import FarmOperationsDG from "./Pages/FarmOperationsDG";
import DashboardNew from "./Dashboard2";
import Popup from "./Pages/Popup";
import { useParams } from "react-router-dom";
import EmployeeList from "./ManPowerPages/EmployeeList";
import TurnAroundTime from "./R&MDashboard/TurnAroundTime";
import Requested from "./R&MDashboard/Requested";
import RMDashboard from "./RMDashboard";
import MassUpload from "./CentralStore/Stocks/massUpload";
import CorePermitApproved from "./R&MDashboard/CorePermitApproved";
//import EmployeeList from "./ManPowerPages/EmployeeList"; 
import FarmOperationDashboard from "./FarmOperationDashboard";
import { CustomTable } from "./CustomTable";
import { DripSystemMaster } from "./FOMasters/DripSystem";
import { DGmaster } from "./FOMasters/DGmaster";
import { SprayerMaster } from "./FOMasters/SprayerMaster";
import { BoreWellMaster } from "./FOMasters/BoreWellMaster";
import { ImplementMaster } from "./FOMasters/ImplementMaster";
import { TractorMaster } from "./FOMasters/TractorMaster";
import { MainActivityMaster } from "./FOMasters/MainActivityMaster";
import { ActivityMaster } from "./FOMasters/ActivityMaster";
import { PloteLocationMaster } from "./FOMasters/PloteLocationMaster";
import { PlotAcrageMaster } from "./FOMasters/PlotAcrageMaster";
import { ChemicalMaster } from "./FOMasters/ChemicalMaster";
import { FertilizerMaster } from "./FOMasters/FertilizerMaster";
import { CropFunctionMaster } from "./FOMasters/CropFunctionMaster";
import { CropMaster } from "./FOMasters/CropMaster";
import { FunctionMaster } from "./FOMasters/FunctionMaster";
import { SubCropMaster } from "./FOMasters/SubCropMaster";
import { RMActivityMaster } from "./FOMasters/RMActivityMaster";
import { SubFunctionsMasters } from "./FOMasters/SubFunctionsMasters";
import { SiteMaster } from "./FOMasters/SiteMaster";
import { SubSiteMasters } from "./FOMasters/SubSiteMaster";
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [roughtloggedIn, setroughtloggedIn] = useState(false);
  // Function to handle login
  // const handleLogin = () => {
  //   // Perform login logic and set loggedIn to true
  //   setLoggedIn(true);
  //};

  const isloggedIn = retrieveData(MOBILE_NUMBER);
  console.log("isloggedIn1234:::", isloggedIn);
  if (isloggedIn == null || isloggedIn == "" || isloggedIn == undefined) {
    //nav('/Login');
    setroughtloggedIn(false);
    //window.location.href = '/Login';
  }
  return (
    // <ClientSessionProvider storage="local" expiration={3}>


    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={loggedIn ? <Navigate to="/Popup" /> : <Login />}
        />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/Popup" element={<Popup />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/EmployeeList" element={<EmployeeList />} />
        <Route path="/centralstore" element={<CentralStore />} />
        <Route path="/rolemaster" element={<RoleMaster />} />
        <Route path="/assetmaster" element={<AssetMaster />} />
        <Route path="/MainAssertMaster" element={<MainAssertMaster />} />
        <Route path="/usermaster" element={<UserMaster />} />
        <Route path="/mainstoremaster" element={<MainStoreMaster />} />
        <Route path="/unitmeasuremaster" element={<UnitMeasureMaster />} />
        <Route path="/farmoperations" element={<FarmOperations />} />
        <Route path="/rmtracking" element={<RMTracking />} />
        <Route path="/storemaster" element={<StoreMaster />} />
        <Route path="/rackmaster" element={<RackMaster />} />
        <Route path="/unitmeasuremaster" element={<UnitMeasureMaster />} />
        <Route path="/subassetmaster" element={<SubAssetMaster />} />
        <Route path="/productmaster" element={<ProductMaster />} />
        {/* <Route path="/MyContext" element={<MyContext />} /> */}
        <Route path="/CategoryMaster" element={<CategoryMaster />} />
        <Route path="/RequestMaster" element={<RequestMaster />} />
        <Route path="/RoleAssetMapping" element={<RoleAssetMapping />} />
        <Route path="/RequestMasterTest" element={<RequestMasterTest />} />
        <Route path="/history" element={<History />} />
        <Route path="/massUpload" element={<MassUpload />} />

        <Route
          path="/RequestedOrderHistory"
          element={<RequestOrderHistory />}
        />
        <Route path="/ReturnOrderHistory" element={<ReturnOrderHistory />} />
        <Route path="/HotWorkPermit2_Web" element={<HotWorkPermit2_Web />} />
        <Route path="/WorkPermit" element={<WorkPermit />} />
        <Route path="/RepairMaintenace" element={<RepairMaintenace />} />
        <Route path="/NewRequest" element={<NewRequest />} />
        <Route path="/DailyWorkAllocation" element={<DailyWorkAllocation />} />
        <Route path="/BulkAllocation" element={<BulkAllocation />} />
        <Route path="/EquipmentMonitoring" element={<EquipmentMonitoring />} />
        {/* <Route path="/RMhistory" element={<RMhistory />} /> */}
        <Route path="/CranePermitMain" element={<CranePermitMain />} />
        <Route path="/CriticalElevated" element={<CriticalElevated />} />
        <Route path="/ExcavationPermit" element={<ExcavationPermit />} />
        <Route path="/OpenStock" element={<OpenStock />} />
        <Route path="/ProductStatus" element={<ProductStatus />} />
        {/* Approve and Reject */}
        <Route
          path="/CorePermitM2Acceptor"
          element={<CorePermitM2Acceptor />}
        />
        <Route
          path="/GeneralHotWork2Additional"
          element={<GeneralHotWork2Additional />}
        />
        <Route
          path="/ExcavationPermit2Additional"
          element={<ExcavationPermit2Additional />}
        />
        <Route
          path="/CriticalElevated2Additional"
          element={<CriticalElevated2Additional />}
        />
        <Route
          path="/CranePermit2Additional"
          element={<CranePermit2Additional />}
        />

        <Route path="/HotWorkPermit2" element={<HotWorkPermit2 />} />
        {/* R&M Report */}

        <Route path="/WorkPermitReport" element={<WorkPermitReport />} />
        <Route path="/RMReport" element={<RMReport />} />
        <Route path="/NewRequestReport" element={<NewRequestReport />} />
        <Route
          path="/DailyWorkAllocationReport"
          element={<DailyWorkAllocationReport />}
        />
        <Route
          path="/EquipmentMonitoringReport"
          element={<EquipmentMonitoringReport />}
        />
        <Route
          path="/BulkAllocationReport"
          element={<BulkAllocationReport />}
        />
        <Route path="/ShiftBasedReport" element={<ShiftBasedReport />} />
      </Routes>
      <Routes>
        <Route path="/stockinward" element={<StockInWard />} />
        <Route path="/stockInwardReport" element={<StockInwardReport />} />
        <Route path="/requestReport" element={<RequestReport />} />
        <Route path="/returnReport" element={<ReturnReport />} />
        <Route path="/openStockReport" element={<OpenStockReport />} />
        <Route path="/CorePermitOne" element={<CorePermitOne />} />
        <Route path="/CorePermitM3" element={<CorePermitM3 />} />
        <Route path="/CorePermitM2" element={<CorePermitM2 />} />
        <Route path="/HotWorkPermit2Main" element={<HotWorkPermit2Main />} />
        {/* <Route path="/EmployeeList" element={<EmployeeList />} /> */}

        <Route
          path="/AdditionalPermitOptions"
          element={<AdditionalPermitOptions />}
        />

        <Route path="/CorePermit_Web" element={<CorePermit_Web />} />
        <Route
          path="/CriticalElevatedWork_Web"
          element={<CriticalElevatedWork_Web />}
        />
        <Route
          path="/ExcavationPermit_Web"
          element={<ExcavationPermit_Web />}
        />
        <Route
          path="/ExcavationPermit_Web"
          element={<ExcavationPermit_Web />}
        />
      </Routes>

      <Routes>
        <Route
          path="/RequestFarmOperation"
          element={<RequestFarmOperation />}
        />
        <Route path="/UpdateActivity" element={<UpdateActivity />} />
        <Route
          path="/CraneOperationPermit_Web"
          element={<CraneOperationPermit_Web />}
        />
        <Route path="/RMhistory" element={<RMhistory />} />

        {/* <Route path='/WorkPermit1' element={<WorkPermit1/>}/> */}

        <Route
          path="/FarmOperationsGenaralMaintanance"
          element={<FarmOperationsGenaralMaintanance />}
        />
        <Route
          path="/FarmOperationsBoreWell"
          element={<FarmOperationsBoreWell />}
        />
        <Route
          path="/FarmOperationsSprayServices"
          element={<FarmOperationsSprayServices />}
        />
        <Route
          path="/FarmOperationsTractors"
          element={<FarmOperationsTractors />}
        />
        <Route
          path="/FarmOperationHistory"
          element={<FarmOperationHistory />}
        />
        <Route
          path="/FarmOperationHistoryAll"
          element={<FarmOperationHistoryAll />}
        />
        <Route
          path="/FarmOperationsImplements"
          element={<FarmOperationsImplements />}
        />
        <Route
          path="/FarmOperationsDripSystem"
          element={<FarmOperationsDripSystem />}
        />
        <Route
          path="/FarmOperationsReports"
          element={<FarmOperationsReports />}
        />
        <Route path="/FarmOperationsDG" element={<FarmOperationsDG />} />
        <Route path="/CentralStoreDashboard" element={<DashboardNew />} />
        <Route path="/RMDashboard" element={<RMDashboard />} />
        <Route path="/FarmOperationDashboard" element={<FarmOperationDashboard />} />
        <Route path="/DripSystemMaster" element={<DripSystemMaster />} />
        <Route path="/DGmaster" element={<DGmaster />} />
        <Route path="/SprayerMaster" element={<SprayerMaster />} />
        <Route path="/BorewellMaster" element={<BoreWellMaster />} />
        <Route path="/ImplementMaster" element={<ImplementMaster />} />
        <Route path="/TractorMaster" element={<TractorMaster />} />
        <Route path="/MainActivity" element={<MainActivityMaster />} />
        <Route path="/ActivityMaster" element={<ActivityMaster />} />
        <Route path="/PlotLocationMaster" element={<PloteLocationMaster />} />
        <Route path="/PlotAcrageMasters" element={<PlotAcrageMaster />} />
        <Route path="/ChemicalMasters" element={<ChemicalMaster />} />
        <Route path="/FertilizerMasters" element={<FertilizerMaster />} />


        <Route path="/CropFunction" element={<CropFunctionMaster />} />
        <Route path="/CropMasters" element={<CropMaster />} />
        <Route path="/SubCropMasters" element={<SubCropMaster />} />
        <Route path="/FunctionMasters" element={<FunctionMaster />} />
        <Route path="/SubFunctionMaster" element={<SubFunctionsMasters />} />
        <Route path="/RMactMasters" element={<RMActivityMaster />} />
        <Route path="/SiteMasters" element={<SiteMaster />} />
        <Route path="/SubSiteMaster" element={<SubSiteMasters />} />

        

      </Routes>
    </Router>
    // </MyContext.Provider>

    // </ClientSessionProvider>
  );
}

export default App;
