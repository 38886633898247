import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../src/Pages/SideMenu";
import Header from "../../src/Pages/Header";
import Footer from "../../src/Pages/Footer";
import Logout from "../../src/Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../src/Utilities/Loader";
import { USER_PROFILE } from "../Utils";
import moment from "moment";
import {
  getMasters,
  newRqeuestAcceptReject,
  newRqeuest,
  rmGetmasters,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';


const NewRequest = (category, index) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [estDate, setEstDate] = useState("");
  const [corePermit, setCorePermit] = useState('');
  const [assignWork, setAssignWork] = useState([]);
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getAccName, setGetAccName] = useState([]);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showMsg, setShowMsg] = useState("false");
  const [errAssignTo, setErrAssignTo] = useState("");
  const [errEstimated, setErrEstimated] = useState("");
  const [errcorepermit, setErrCorepermit] = useState("");
  const [errRemark, setErrRemark] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [code, setCode] = useState([]);
  const [mobileNo, setMobileNo] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [rejectedRequests, setRejectedRequests] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [errors, setErrors] = useState({});




  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );


  const handleRadioChange = (index, value) => {
    setCorePermit((prev) => ({
      ...prev,
      [index]: value,
    }));

    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        corePermitRequired: value,
      };
      return updatedData;
    });
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index]?.corePermit;
      return updatedErrors;
    });
  };


  const handleEditClick = (index) => {
    setIsEditMode(true);
    setSelectedIndex(index);
  };



  const handleRemark = (e, index) => {
    if (data[index]) {
      const updatedData = [...data];
      updatedData[index].accepterRemarks = e.target.value;
      setData(updatedData);
      setErrRemark("");
    }
  };


  const handleresponse = () => {
    setShowResponsePopup(true);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      const responseGetData = await axios.get(getData, { headers });
      console.log("resssss,", responseGetData);
      setGetData(responseGetData.data.response.employees);
    } catch (err) {
      console.log("getError", err);
    }
  };

  useEffect(() => {
    const savedApprovedRequests = JSON.parse(localStorage.getItem("approvedRequests"));
    if (savedApprovedRequests) {
      setApprovedRequests(savedApprovedRequests);
    }
  }, []);

  // const handleAprove = async (index) => {
  //   // Validate required fields
  //   if (corePermit === "") {
  //     setErrCorepermit("Please Select Core Permit");
  //     return;
  //   }
  //   if (selectedOptions[index].length === 0) {
  //     setErrAssignTo("Please Select Assign To Work");
  //     return;
  //   }

  //   if (!selectedOptions[index] || selectedOptions[index].length === 0) {
  //     setErrAssignTo("Please Select Assign To Work");
  //     return;
  //   }
  //   if (estDate === "") {
  //     setErrEstimated("Please Select Estimated Date");
  //     return;
  //   }
  //   const assignWorkTo = selectedOptions[index].map((item) => ({
  //     assignWorkToName: item.value,
  //     assignWorkToMobileNumber: item.mobNo,
  //     assignWorkToId: item.code,
  //   }));

  //   const postData = {
  //     acceptorName: `${getAccName.firstName} ${getAccName.lastName}`,
  //     acceptorID: getAccName.userId,
  //     acceptorMobileNumber: getAccName.mobileNumber,
  //     serverRecordId: data[index].serverRecordId,
  //     requestId: data[index].requestId,
  //     requesterName: data[index].requesterName,
  //     requesterMobileNumber: data[index].requesterMobileNumber,
  //     requesterType: data[index].requesterType,
  //     typeOfWork: data[index].typeOfWork,
  //     typeOfWorkId: data[index].typeOfWorkId,
  //     function: data[index].function,
  //     functionId: data[index].functionId,
  //     location: data[index].location,
  //     locationId: data[index].locationId,
  //     subLocation: data[index].subLocation,
  //     subLocationId: data[index].subLocationId,
  //     requiredDate: data[index].requiredDate,
  //     image: "",
  //     requesterRemarks: data[index].requesterRemarks,
  //     accepterRemarks: data[index].accepterRemarks,
  //     corePermitRequired: data[index]?.corePermitRequired,
  //     assignWorkTo,
  //     estimatedCompletionDate: estDate,
  //     technicianRemarks: data[index].technicianRemarks,
  //     status: "Approved",
  //     submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
  //   };

  //   try {
  //     setIsLoading(true);
  //     console.log("Sending postData to server:", postData);
  //     const headers = await GetApiHeaders();
  //     const response = await axios.post(newRqeuestAcceptReject, postData, { headers });

  //     if (response.data.statusCode == 200 || response.data.statusCode == 500) {
  //       setShowResponsePopup(true);
  //       setsubmitResponse(response.data.message);

  //       setApprovedRequests((prev) => {
  //         const updatedApprovedRequests = approvedRequests.includes(index)
  //           ? prev
  //           : [...prev, index];
  //         localStorage.setItem("approvedRequests", JSON.stringify(updatedApprovedRequests));
  //         return updatedApprovedRequests;
  //       });
  //       console.log("Request processed successfully:", response.data.message);

  //       fetchData();
  //     }
  //   } catch (error) {
  //     console.error("API error:", error);
  //     console.error("Error details:", error.response ? error.response.data : error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }



  // const handleAssignTo = (selectedItems, index) => {
  //   if (selectedItems === null) {
  //     setSelectedOptions((prevOptions) => {
  //       const updatedOptions = [...prevOptions];
  //       updatedOptions[index] = [];  // Reset options for the specific index
  //       return updatedOptions;
  //     });
  //     setErrAssignTo(""); // Clear any existing error
  //     return;
  //   }

  //   if (!selectedItems || !Array.isArray(selectedItems)) {
  //     console.log("selectedItems is not a valid array");
  //     return;
  //   }

  //   if (selectedItems.length < 1 || selectedItems.length > 5) {
  //     setErrAssignTo("Please select Min - 1 Technician and Maximum - 5.");
  //     return;
  //   }

  //   setSelectedOptions((prevOptions) => {
  //     const updatedOptions = [...prevOptions];
  //     updatedOptions[index] = selectedItems; // Update options for the specific index
  //     return updatedOptions;
  //   });

  //   // Update the item's assignWorkTo property if currentItem is set
  //   if (currentItem) {
  //     currentItem.assignWorkTo = selectedItems.map((selectedItem) => ({
  //       assignWorkToName: selectedItem.label,
  //       assignWorkToId: selectedItem.value,
  //     }));
  //   }

  //   setErrAssignTo("");
  // };

  const handleAprove = async (index) => {
    console.log("Validation check starting for index:", index);

    // Initialize error object
    const newErrors = { corePermit: "", assignTo: "", estimatedDate: "" };

    // Validation logic
    if (!data[index]?.corePermitRequired) {
      newErrors.corePermit = "Please Select Core Permit";
    }

    const assignWorkOptions = selectedOptions[index]?.length
      ? selectedOptions[index]
      : data[index]?.assignWorkTo;

    if (!assignWorkOptions || assignWorkOptions.length === 0) {
      newErrors.assignTo = "Please Select Assign To Work";
    }

    const completionDate = estDate || data[index]?.estimatedCompletionDate;
    if (!completionDate) {
      newErrors.estimatedDate = "Please Select Estimated Date";
    } else if (new Date(completionDate) < new Date()) {
      newErrors.estimatedDate = "Estimated date cannot be in the past.";
    }

    // If there are errors, set them in state and stop execution
    if (Object.values(newErrors).some((error) => error)) {
      console.log("Errors found:", newErrors);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: newErrors,
      }));
      return;
    }

    // Prepare data for API
    const assignWorkTo = assignWorkOptions.map((item) => ({
      assignWorkToName: item.value || item.assignWorkToName,
      assignWorkToMobileNumber: item.mobNo || item.assignWorkToMobileNumber,
      assignWorkToId: item.code || item.assignWorkToId,
    }));

    const postData = {
      acceptorName: `${getAccName.firstName} ${getAccName.lastName}`,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      requestId: data[index].requestId,
      requesterName: data[index].requesterName,
      requesterMobileNumber: data[index].requesterMobileNumber,
      requesterType: data[index].requesterType,
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      function: data[index].function,
      functionId: data[index].functionId,
      location: data[index].location,
      locationId: data[index].locationId,
      subLocation: data[index].subLocation,
      subLocationId: data[index].subLocationId,
      requiredDate: data[index].requiredDate,
      image: "",
      requesterRemarks: data[index].requesterRemarks,
      accepterRemarks: data[index].accepterRemarks,
      corePermitRequired: data[index]?.corePermitRequired,
      assignWorkTo,
      estimatedCompletionDate: completionDate,
      technicianRemarks: data[index].technicianRemarks,
      status: "Approved",
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
    };

    try {
      setIsLoading(true);
      console.log("Sending postData to server:", postData);
      const headers = await GetApiHeaders();
      const response = await axios.post(newRqeuestAcceptReject, postData, { headers });

      if (response.data.statusCode == 200 || response.data.statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);

        setApprovedRequests((prev) => {
          const updatedApprovedRequests = approvedRequests.includes(index)
            ? prev
            : [...prev, index];
          localStorage.setItem("approvedRequests", JSON.stringify(updatedApprovedRequests));
          return updatedApprovedRequests;
        });

        console.log("Request processed successfully:", response.data.message);
        fetchData();
      }
    } catch (error) {
      console.error("API error:", error.response ? error.response.data : error);
    } finally {
      setIsLoading(false);
    }
  };





  const handleAssignTo = (selectedItems, index) => {
    if (selectedItems === null) {
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[index] = [];
        return updatedOptions;
      });

      // Clear error
      setErrAssignTo("");
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[index]?.assignTo) {
        }
        return updatedErrors;
      });
      return;
    }

    if (!Array.isArray(selectedItems)) {
      console.log("selectedItems is not a valid array");
      return;
    }
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = selectedItems.map((item) => ({
        value: item.label || item.value,
        label: item.label || item.value,
        mobNo: item.mobNo || item.mobileNumber,
        code: item.code || item.assignWorkToId,
      }));
      return updatedOptions;
    });

    if (selectedItems.length > 0) {
      setErrAssignTo("");
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[index]?.assignTo) {
          delete updatedErrors[index].assignTo;
        }
        return updatedErrors;
      });
    }
  };


  const closeBox = () => {
    setBoxVisible(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);

    //  window.location.reload();
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    // window.location.reload();
  };


  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setEstDate(newDate);
    if (event.target.value) {
      setErrEstimated("");
    }
  };




  const handleReject = async (index) => {
    const approveApi = newRqeuestAcceptReject;
    const headers = await GetApiHeaders();
    const postData = {
      acceptorName: getAccName.firstName + " " + getAccName.lastName,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      requestId: data[index].requestId,
      requesterName: data[index].requesterName,
      requesterMobileNumber: data[index].requesterMobileNumber,
      requesterType: data[index].requesterType,
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      function: data[index].function,
      functionId: data[index].functionId,
      location: data[index].location,
      locationId: data[index].locationId,
      subLocation: data[index].subLocation,
      subLocationId: data[index].subLocationId,
      requiredDate: data[index].requiredDate,
      image: "",
      requesterRemarks: data[index].requesterRemarks,
      // accepterRemarks: remark,
      accepterRemarks: data[index].accepterRemarks,
      corePermitRequired: corePermit,
      assignWorkTo: selectedOptions.map(option => ({
        assignWorkToName: option.label,
        assignWorkToMobileNumber: option.mobNo,
        assignWorkToId: option.value,
      })),
      // assignWorkTo,
      estimatedCompletionDate: estDate,
      technicianRemarks: data[index].technicianRemarks,
      status: "Rejected",
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
    };

    if (remark === "") {
      setErrRemark("Please Enter Remarks");
    } else {
      setErrRemark("");
    }

    try {
      if (remark !== "") {
        setIsLoading(true);
        const response = await axios.post(approveApi, postData, { headers });

        if (response.data.statusCode == 200 || response.data.statusCode == 500) {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
          setRejectedRequests((prev) => [...prev, index]);
          Swal({
            title: "Rejected",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }

        setErrRemark("");
        setIsSubmitted(true);
      }
    } catch (err) {
      console.error("error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    const getApi = newRqeuest;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.post(getApi, {}, { headers });
      console.log("res:", response);
      setData(response.data.response.newRequests);
      setIsLoading(false);
    } catch (err) {
      console.error("error", err);
    }
  };
  useEffect(() => {
    fetchData();
    getMasterData();

    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetAccName(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">New Requests</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2">
                                  New Requests
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>

                              {data.map((item, index) => {
                                var newDateFormat = moment(item.requesterSubmittedDate).format("DD-MM-YYYY hh:mm:ss A");
                                var parsedDate = moment(item.requiredDate, "DD-MM-YYYY");
                                var requiredDate = parsedDate.format("DD-MMM-YYYY");

                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mb-2"
                                        id="dns"
                                      >
                                        <Accordion.Item eventKey="1">

                                          <Accordion.Header>
                                            <div
                                              key={index}
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="ProductName d-flex"
                                            >
                                              <span style={{ width: "16%" }}>
                                                <b>Request Id &nbsp;&nbsp;:&nbsp;</b>
                                                <span>{item.serverRecordId}</span>
                                              </span>
                                              <span style={{ width: "31%" }}>
                                                <b>Requested Date &nbsp;&nbsp;:&nbsp;</b>
                                                <span>{newDateFormat}</span>
                                              </span>
                                              <span style={{ width: "32%" }}>
                                                <b>Requester Name &nbsp;&nbsp;:&nbsp;</b>
                                                <span>{item.requesterName}</span>
                                              </span>
                                              <span style={{ width: "20%" }}>
                                                <b>Status &nbsp;&nbsp;:&nbsp;</b>
                                                <span
                                                  style={{
                                                    color:
                                                      item.status === "Pending" ? "orange" :
                                                        item.requestStatusCode === 3 ? "#D50101" :
                                                          item.requestStatusCode === 0 ? "orange" :
                                                            "green",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.status === "Pending"
                                                    ? "Pending"
                                                    : item.requestStatusCode === 3
                                                      ? "Rejected"
                                                      : "Approved"}
                                                </span>
                                              </span>
                                              <div>
                                                {item.status === "Approved" && (
                                                  <span
                                                    style={{
                                                      marginRight: "10px",
                                                      cursor: "pointer",
                                                      float: "right",
                                                    }}
                                                    onClick={() => handleEditClick(index)}
                                                  >
                                                    <i className="fa fa-edit" style={{ fontSize: "17px", color: "#007bff" }}></i>
                                                  </span>
                                                )
                                                }
                                              </div>
                                            </div>


                                          </Accordion.Header>


                                          <Accordion.Body>
                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex">
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Requester Type
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.requesterType}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Function
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.function}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Type of work
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.typeOfWork}</span>
                                              </span>
                                            </div>
                                            {/* <div>
                                              {item.status === "Approved" && (
                                                <span
                                                  style={{
                                                    marginRight: "10px",
                                                    cursor: "pointer",
                                                    float: "right",
                                                  }}
                                                  onClick={() => handleEditClick(index)}
                                                >
                                                  <i className="fa fa-edit" style={{ fontSize: "24px", color: "#007bff" }}></i>
                                                </span>
                                              )
                                              }
                                            </div> */}

                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex mt-3">
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Location
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.location}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Sub Location
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.subLocation}</span>
                                              </span>
                                              <div style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                  Core Permit required ?
                                                </span>
                                                <span >  :&nbsp;
                                                </span>

                                                <div style={{ width: '60%', display: 'inline-block' }}>
                                                  <div className="form-check form-check-inline">
                                                    <input
                                                      className="form-check-input mt-3"
                                                      type="radio"
                                                      value="yes"
                                                      checked={data[index]?.corePermitRequired === 'yes'}
                                                      onChange={() => handleRadioChange(index, 'yes')}
                                                      disabled={
                                                        !(isEditMode && selectedIndex === index)
                                                      }
                                                    />
                                                    <label className="form-check-label mt-2">Yes</label>
                                                  </div>
                                                  <div className="form-check form-check-inline">
                                                    <input
                                                      className="form-check-input mt-3"
                                                      type="radio"
                                                      value="no"
                                                      checked={data[index]?.corePermitRequired === 'no'}
                                                      onChange={() => handleRadioChange(index, 'no')}
                                                      disabled={
                                                        !(isEditMode && selectedIndex === index) && item.status !== 'Pending'
                                                      }

                                                    />
                                                    <label className="form-check-label mt-2">No</label>
                                                  </div>
                                                  {errors[index]?.corePermit && (
                                                    <span style={{ color: "red" }}>
                                                      {errors[index].corePermit}
                                                    </span>
                                                  )}
                                                </div>
                                              </div>

                                            </div>

                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex mt-3">
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Required Date
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>{item.requiredDate}</span>
                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Assign Work to
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '50%', display: 'inline-block' }}>
                                                  <div>
                                                    <MultiSelect
                                                      isClearable={true}
                                                      options={getData.map((item) => ({
                                                        label: item.name,
                                                        value: item.name,
                                                        code: item.code,
                                                        mobNo: item.mobileNumber,
                                                      }))}
                                                      value={
                                                        selectedOptions[index] && selectedOptions[index].length > 0
                                                          ? selectedOptions[index]
                                                          : item.assignWorkTo?.map((assignItem) => ({
                                                            label: assignItem.assignWorkToName,
                                                            value: assignItem.assignWorkToId,
                                                          })) || []
                                                      }
                                                      onChange={(selectedItems) => handleAssignTo(selectedItems, index)}
                                                      labelledBy="Select"
                                                      disabled={
                                                        !(isEditMode && selectedIndex === index) && item.status !== 'Pending'
                                                      }

                                                    />
                                                    {errors[index]?.assignTo && (
                                                      <span style={{ color: "red" }}>
                                                        {errors[index].assignTo}
                                                      </span>
                                                    )}
                                                  </div>
                                                </span>

                                              </span>
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Estimated Date
                                                </span>
                                                <span>:&nbsp;</span>
                                                <span style={{ width: '60%', display: 'inline-block' }}>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>
                                                    <input
                                                      type="date"
                                                      style={{ width: 108, height: 32 }}
                                                      min={getCurrentDate()}
                                                      onChange={handleDateChange}
                                                      disabled={
                                                        !(isEditMode && selectedIndex === index) && item.status !== 'Pending'
                                                      }

                                                      value={
                                                        estDate || moment(data[index]?.estimatedCompletionDate).format("YYYY-MM-DD")
                                                      }
                                                    />

                                                  </span>
                                                  {errors[index]?.estimatedDate && (
                                                    <span style={{ color: "red", marginLeft: 160 }}>
                                                      {errors[index].estimatedDate}
                                                    </span>
                                                  )}
                                                </span>
                                                <div>
                                                  <span style={{ color: "red" }}>{errEstimated}</span>
                                                </div>
                                              </span>
                                            </div>

                                            <div style={{ fontSize: 14, width: "100%" }} className="ProductNameForRandM d-flex mt-3">
                                              {item.requesterRemarks && (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                    Requester Remarks
                                                  </span>
                                                  <span>:&nbsp;</span>
                                                  <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
                                                    {item.requesterRemarks}
                                                  </span>
                                                </span>
                                              )}
                                              <span style={{ width: '33.4%' }}>
                                                <div style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                  Remarks
                                                </div>
                                                <span>:&nbsp;</span>
                                                <div style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>

                                                  <textarea
                                                    type="text"
                                                    style={{ borderColor: "silver", borderRadius: 5 }}
                                                    value={item.accepterRemarks}
                                                    onChange={(e) => handleRemark(e, index)}
                                                  />
                                                </div>
                                                <div>
                                                  <span style={{ color: "red", marginLeft: 160 }}>{errRemark}</span>
                                                </div>
                                              </span>
                                            </div>

                                            <div className="mt-0 d-flex justify-content-end">
                                              <span className="mt-3">
                                                {item.status === "Pending" ? (
                                                  <>
                                                    {/* Approve Button */}
                                                    <button onClick={() => handleAprove(index)} className="btns">
                                                      Approve
                                                    </button>
                                                    {/* Reject Button */}
                                                    <button
                                                      className="btnsReject ml-2 mb-2"
                                                      onClick={() => handleReject(index)}
                                                    >
                                                      Reject
                                                    </button>
                                                  </>
                                                ) : (
                                                  <button onClick={() => handleAprove(index)} className="btns">
                                                    Update
                                                  </button>
                                                )}
                                              </span>
                                            </div>
                                          </Accordion.Body>

                                        </Accordion.Item>
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}


                            </Tbody>
                          </Table>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">New Request</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleClose}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">


                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading && (
                <Loader_Bulk
                  loading={isLoading}
                  message={"Fetching Data. Please Wait..!"}
                  loderimage={loaderImage}
                />
              )}
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body >
  );
};

export default NewRequest;
