import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// for Sidebar And Navbar
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";

import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  FarmOperationRMHistoryAll,
  RmhistoryGet,
  FarmOperation_getMasters,
} from "../Utilities/URLCONSTANTS";
import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import * as XLSX from "xlsx";
import moment from "moment";
import { CustomDatePickerInput } from "../../src/Utilities/utils";

//UnitMeasureMaster
const FarmOperationHistory = ({}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Unit Measure Master"
  );
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [ActivityData, setActivityData] = useState([]);
  const [selectedMachine, setSelectedOption] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [machineSelecetdOptions, setMachineSelectedOptions] = useState("");

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const toggleDetails = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const fetchTypeOfOperationDropdownData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("responseLocation", response);
        setActivityData(response.data.response.rmActivitiesMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handleModalOpen = () => {
    setSelectedOption("");
    setSelectedStartDate("");
    setSelectedEndDate("");
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const closeWarning = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (data != null && data != undefined) {
      setFilteredData(data);
      setTotalItems(data.length);
      setIsLoading(false);
    }
  }, [data]);

  const fetchData = async () => {
    debugger;
    const apiUrl = FarmOperationRMHistoryAll;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        debugger;
        setIsLoading(false);
        setData(response.data.response.rmHistory);
        const respData = response.data.response.rmHistory;
        const activityType = [
          ...new Set(respData.map((item) => item.activityType)),
        ];
        setActivityData(activityType);
        setLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
    // }, 900);
  };

  useEffect(() => {
    fetchData();
    fetchTypeOfOperationDropdownData();
  }, []);

  // const handleChange = (event) => {
  //   debugger;

  //   const { name, value } = event.target;
  //   if (name === "machineSelecetd") {
  //     setSelectedOption(value);
  //   } else if (name === "selectedStartDt") {
  //     setSelectedStartDate(value);
  //   } else if (name === "selectedEndDt") {
  //     setSelectedEndDate(value);
  //   }
  // };

  // const handleChange = (machineSelecetdOptions) => {
  //   const labelNames = machineSelecetdOptions.map((item) => item.label).join(", ");
  //   setSelectedOption(machineSelecetdOptions);
  //   setMachineSelectedOptions(labelNames);
  // };


  const handleChange = (machineSelecetdOptions, name, value) => {
    if (name === "selectedStartDate") {
      setSelectedStartDate(value); 
    } else if (name === "selectedEndDate") {
      setSelectedEndDate(value); 
    } else {
      const labelNames = machineSelecetdOptions.map((item) => item.label).join(", ");
      setSelectedOption(machineSelecetdOptions);
      setMachineSelectedOptions(labelNames); 
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqObj = {
      activityType: machineSelecetdOptions,
      // startDate: selectedStartDate,
      // endDate: selectedEndDate,
      startDate: formatDate(selectedStartDate),
      endDate: formatDate(selectedEndDate),
    
    };
    const apiUrl = RmhistoryGet;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    axios.post(apiUrl, reqObj, { headers }).then((response) => {
      setData(response.data.response.rmHistory);
      setShowModal(false);
    });
  };

  function extractTimeFromDate(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const timeOnly = dateObject.toLocaleTimeString("en-US", { hour12: true });
    return timeOnly;
  }

  function resetData(event) {
    event.preventDefault();
    setSelectedOption("");
    setStartDate(null);
    setEndDate(null);
    fetchData();
  }

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.activityType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.service.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.requiredDate.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  };

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Machines / Systems",
        "Service",
        "Submitted Date",
        "Submitted Time",
        "Remarks",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.activityType,
        item.service,
        item.requiredDate,
        extractTimeFromDate(item.submittedDate),
        item.remarks,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    // Save the file
    XLSX.writeFile(wb, "RMHistory.xlsx");
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
      if (sortConfig.key) {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          // Check if values are numeric
          if (!isNaN(aValue) && !isNaN(bValue)) {
              return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
          }

          // Fallback for string comparison
          const aString = aValue?.toString().toLowerCase();
          const bString = bValue?.toString().toLowerCase();
          if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
          if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
          return 0;
      }
      return 0;
  });
  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };


  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0" id="farmOperations">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Farm Operations</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text">R&M History</h6>
                      </div>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div className="card p-3">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex">
                         

                                  <div className="col-md-3 col-12">
                                  <label className="form-label  font-weight-bold mt-1">
                                    Type Of Operation
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <MultiSelect
                                    options={ActivityData.map((item) => ({
                                      label: item.name,
                                      value: item.code,
                                    }))}
                                    value={selectedMachine}
                                    onChange={handleChange}
                                    labelledBy="Select"
                                    overrideStrings={{
                                      selectSomeItems:
                                        "Select Type Of Operations",
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected?.map(
                                          (selectedItem, index) => (
                                            <span key={index}>
                                              {selectedItem?.code}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>


                          <div className="col-md-3 col-12  mb-4">
                            <label
                              for="exampleInputPassword1"
                              //class="form-label mb-0 pb-2"
                              className="form-label  font-weight-bold mb-0 pb-2 "
                            >
                              Start Date
                            </label>
                            <div 
                            className="input-group input-group w-100"
                           

                            >
                              {/* <input type='date' className="form-control"
                                   name="selectedStartDt"
                                   value={selectedStartDate}
                                   onChange={handleChange}
                                  />  */}
                              <DatePicker
                                showIcon
                                className="form-control w-100"
                                placeholderText="Select Start Date"
                                // selected={startDate}
                                   selected={selectedStartDate}
                                onChange={(date) => setSelectedStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                customInput={<CustomDatePickerInput />}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12  mb-4">
                            <label
                              for="exampleInputPassword1"
                              //class="form-label mb-0 pb-2"
                              className="form-label  font-weight-bold mb-0 pb-2 "
                            >
                              End Date
                            </label>
                            <div>
                              {/* <input type='date' className="form-control"
                                   name="selectedEndDt"
                                   value={selectedEndDate}
                                   onChange={handleChange}
                                  />  */}
                              <DatePicker
                                showIcon
                                className="form-control w-100"
                                placeholderText="Select End Date"
                                // selected={endDate}
                                selected={selectedEndDate}
                                onChange={(date) => setSelectedEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                minDate={startDate}
                                customInput={<CustomDatePickerInput />}
                              />
                            </div>
                            <div>
                              {/* <DatePicker
                                    selected={time}
                                    onChange={handleTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly // Display only the time picker
                                    timeIntervals={15} // Set time intervals as needed
                                    dateFormat="h:mm aa"
                                    /> */}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4 mb-4">
                          <Button variant="primary" type="submit"
                           className="buttons ml-2 mr-2 "
                           >
                            Search
                          </Button>
                          <Button
                            className="buttons ml-2 mr-2 "
                            variant="danger"
                            onClick={resetData}
                          >
                            Clear All
                          </Button>
                        </div>
                      </form>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div className="card p-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <label className="mr-2">Show </label> &nbsp;&nbsp;
                          <select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            {/* Add more options as needed */}
                          </select>
                          &nbsp;&nbsp;
                          <label> entries</label>
                        </div>
                      </div>
                      <div>
                        <div className="card data_tableCard">
                          <div className="card-body">
                            <div className="my-2 d-flex w-full justify-content-end gap-4">
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: '5%' }}
                              >
                                {/* Search Input */}
                                <input
                                  type="text"
                                  placeholder="🔍 Type to Search..."
                                  value={searchTerm}
                                  onChange={(e) => handleFilter(e.target.value)}
                                  style={{ height: "36px" }}
                                  className="mr-2 border"
                                />{" "}
                                &nbsp;
                                <button
                                  className="btn btn-secondary buttons-excel buttons-html5"
                                  onClick={exportToExcel}
                                >
                                  Download Excel
                                </button>
                              </div>
                            </div>
                            <Table 
                           // className="table table-bordered Content_data_table table-striped"
                           className="table table-bordered Content_data_table table-striped"
                            >
                              <Thead>
                                <Tr>
                                  <Th className="text-center">S.No</Th>
                                  {/* <Th className="text-left">
                                    Machines / Systems
                                  </Th> */}
                                  <Th className="text-center" onClick={() => handleSort("activityType")}>
                                  Machines / Systems{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "activityType"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                  {/* <Th className="text-left">Service</Th> */}
                                  <Th className="text-center" onClick={() => handleSort("service")}>
                                   Service{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "service"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                  {/* <Th className="text-left">Submitted Date</Th> */}
                                  <Th className="text-center" onClick={() => handleSort("submittedDate")}>
                                  Submitted Date{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "submittedDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                  {/* <Th className="text-left">Submitted Time</Th> */}
                                  <Th className="text-center" onClick={() => handleSort("submittedDate")}>
                                  Submitted Time{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "submittedDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                  <Th className="text-left">View</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {currentItems !== undefined &&
                                  currentItems !== null &&
                                  sortedItems.map((item, index) => {
                                    var parsedDate = moment(item.requiredDate, "DD-MM-YYYY");
                                    var newDateFormat = parsedDate.isValid() ? parsedDate.format("DD-MMM-YYYY") : "";
                                    var submittedDate = moment( item.submittedDate ).format("DD-MMM-YYYY")
                                    return (
                                      <React.Fragment >
                                        <Tr>
                                          <Td  className="text-center" >
                                            {" "}
                                            {(currentPage - 1) * itemsPerPage + index + 1}
                                          </Td>
                                          <Td className="text-left">
                                            {" "}
                                            {item.activityType}{" "}
                                          </Td>
                                          <Td className="text-left">
                                            {" "}
                                            {item.service}{" "}
                                          </Td>
                                          <Td className="text-left">
                                            {" "}
                                            {submittedDate}{" "}
                                          </Td>
                                          <Td className="text-left">
                                            {" "}
                                            {extractTimeFromDate(
                                              item.submittedDate
                                            )}{" "}
                                          </Td>
                                          <Td
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                className="plus_icon mr-1 text-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Details"
                                                onClick={() =>
                                                  toggleDetails(index)
                                                }
                                              />
                                              {/* Add any other content or components you need here */}
                                            </div>
                                          </Td>
                                        </Tr>
                                        {selectedRowIndex === index && (
                                          <Tr>
                                            <Td colSpan="6">
                                            <div className="request-card ">
                                            <div
                                                style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex" 
                                              >
                                                <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Requester Name 
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%',display:'inline-block' }}>{item.requesterName}</span>
                                              </span>
                                                <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Requester Mobile 
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%',display:'inline-block' }}>{item.requesterMobileNumber}</span>
                                              </span>
                                                <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Request ID
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%',display:'inline-block' }}>{item.serverRequestId}</span>
                                              </span>
                                              </div>
                                              <div
                                                style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex mt-3" 
                                              >
                                                <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Location
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%',display:'inline-block' }}>{item.locationName}</span>
                                              </span>
                                                <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Cost
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%',display:'inline-block' }}>{item.cost}</span>
                                              </span>
                                                <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Spares
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%',display:'inline-block' }}>{item.spares}</span>
                                              </span>
                                              </div>
                                              <div style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                                   <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'45%',display:'inline-block',fontWeight:600}} > 
                                                 Remarks
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'55%' ,display:'inline-flex', whiteSpace:  "break-spaces", }}>{item.remarks}</span>
                                              </span>
                                              </div> 
                                              </div>
                                            </Td>
                                          </Tr>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                              </Tbody>
                              {isLoading && (
                                <Loader
                                  loading={isLoading}
                                  message={"Fetching Data. Please Wait..!"}
                                  loderimage={loaderImage}
                                />
                              )}
                            </Table>
                            {/* Pagination */}
                            <div
                              className="d-flex justify-content-between mb-10"
                              style={{ borderRadius: 5 }}
                            >
                              <div>
                              Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                         &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                          of {totalItems} entries
                              </div>
                              <div>
                                <div className="div-button text-center">
                                  <button
                                    className="borderleft-radius-5"
                                    // className='ul-pagination'
                                    onClick={() =>
                                      handlePageClick(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    Previous
                                  </button>

                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage == 1 ? "#0056b3" : "white",
                                      color:
                                        currentPage == 1 ? "white" : "black",
                                    }}
                                    key={1}
                                    onClick={() => handlePageClick(1)}
                                    disabled={currentPage === 1}
                                  >
                                    {1}
                                  </button>
                                  {pageNumbers.map((number) => (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor:
                                          currentPage == number
                                            ? "#0056b3"
                                            : "white",
                                        color:
                                          currentPage == number
                                            ? "white"
                                            : "black",
                                      }}
                                      key={number}
                                      onClick={() => handlePageClick(number)}
                                      disabled={currentPage === number}
                                    >
                                      {number}
                                    </button>
                                  ))}
                                  {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                    currentPage !==
                                      Math.ceil(totalItems / itemsPerPage) -
                                        1 &&
                                    currentPage !==
                                      Math.ceil(totalItems / itemsPerPage) && (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                      >
                                        ...
                                      </button>
                                    )}
                                  {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor:
                                          currentPage ==
                                          Math.ceil(totalItems / itemsPerPage)
                                            ? "#0056b3"
                                            : "white",
                                        color:
                                          currentPage ==
                                          Math.ceil(totalItems / itemsPerPage)
                                            ? "white"
                                            : "black",
                                      }}
                                      key={Math.ceil(totalItems / itemsPerPage)}
                                      onClick={() =>
                                        handlePageClick(
                                          Math.ceil(totalItems / itemsPerPage)
                                        )
                                      }
                                      disabled={
                                        currentPage ===
                                        Math.ceil(totalItems / itemsPerPage)
                                      }
                                    >
                                      {Math.ceil(totalItems / itemsPerPage)}
                                    </button>
                                  )}
                                  <button
                                    className="borderright-radius-5"
                                    onClick={() =>
                                      handlePageClick(currentPage + 1)
                                    }
                                    disabled={indexOfLastItem >= totalItems}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">R&M History</span>
                              </div>
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-6 col-12 mb-4">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-labelmb-0 pb-2"
                                    >
                                      Machines / Systems
                                    </label>
                                    <div className="input-group input-group w-100">
                                      <select
                                        className="form-select p-2"
                                        name="machineSelecetd"
                                        value={selectedMachine}
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          Select Machines / Systems
                                        </option>
                                        {ActivityData.length > 1 &&
                                          ActivityData.map((item) => {
                                            return (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12  mb-4">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label mb-0 pb-2"
                                    >
                                      Start Date
                                    </label>
                                    <div className="input-group input-group w-100">
                                      {/* <input type='date' className="form-control"
                                   name="selectedStartDt"
                                   value={selectedStartDate}
                                   onChange={handleChange}
                                  />  */}
                                      <DatePicker
                                        showIcon
                                        className="form-control w-100"
                                        placeholderText="Select Start Date"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 col-12  mb-4">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label mb-0 pb-2"
                                    >
                                      End Date
                                    </label>
                                    <div>
                                      {/* <input type='date' className="form-control"
                                   name="selectedEndDt"
                                   value={selectedEndDate}
                                   onChange={handleChange}
                                  />  */}
                                      <DatePicker
                                        showIcon
                                        className="form-control w-100"
                                        placeholderText="Select End Date"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={startDate}
                                      />
                                    </div>
                                    <div>
                                      {/* <DatePicker
                                    selected={time}
                                    onChange={handleTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly // Display only the time picker
                                    timeIntervals={15} // Set time intervals as needed
                                    dateFormat="h:mm aa"
                                    /> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center mt-4 mb-4">
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="d-flex justify-content-center"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </form>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div>
                      <p>Fetching data. Please wait...</p>
                      {/* <Loader type="Puff" color="#00BFFF" height={50} width={50} /> */}
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default FarmOperationHistory;
