import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import Upload_2 from "../images/Upload_2.png";
import Download_1 from "../images/Download_1.png";
import Downloadicon from "../images/Downloadicon.png";
import {
  bulkUploadExcelFileFomat,
  getUploadedExcelFileDetails,
  uploadBulkWork,
} from "../Utilities/URLCONSTANTS";
import * as XLSX from "xlsx";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import moment from "moment";
//UnitMeasureMaster
const BulkAllocation = ({ }) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [apiStatus, setApiStatus] = useState("");
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const [download, setDownload] = useState();

  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");

  const [totalItems, setTotalItems] = useState();

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.date.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const exportToExcel = () => {
    const data = [
      ["S.no", "MobileNumber", "Name", "Date", "File Path"], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.mobileNumber,
        item.name,
        item.date,

        item.filePath,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "BulkAllocation.xlsx");
  };

  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowMsg(true);
    // window.location.reload();
  };
  const handleClose = () => {
    //yes
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  // Handle the search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  // Implement a function to filter the data based on the search query
  const filterData = () => {
    if (data != null && data != undefined && data != "") {
      const filtered = data.filter((item) => {
        console.log(
          "filterdata :: ",
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        // Modify this condition to match your search criteria
        return (
          item.mobileNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
          // || item.date.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    filterData();
  }, [searchQuery, data]);

  const handleresponse = () => {
    setShowResponsePopup(false);
    window.location.reload();

  };

  const handleDownload = async () => {
    const apiUrl = bulkUploadExcelFileFomat;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    const response = await axios.post(apiUrl, { headers });
    setIsLoading(false);

    if (response.data.success.toString() === "true") {
      setDownload(response.data.filePath);

      if (
        response.data.filePath != null &&
        response.data.filePath !== undefined &&
        response.data.filePath !== ""
      ) {
        const link = document.createElement("a");
        link.href = response.data.filePath;
        link.download = response.data.filePath;
        link.click();
      }
    }
  };
  const handleFileChange = (e) => {

    setSelectedFile(e.target.files[0]);
    setTimeout(() => {
      handleUpload(e.target.files[0]);
    }, 500);
  };

  const handleUpload = async (e) => {
    //alert(e);

    if (e) {
      try {
        const formData = new FormData();
        formData.append("excelFile", e);
        const apiUrl = uploadBulkWork;
        const headers = await GetApiHeaders();
        headers["Content-Type"] = "multipart/form-data";
        //headers['Content-Type']='text/plain;charset=ISO-8859-1';
        //alert(JSON.stringify(headers));
        setIsLoading(true);
        const response = await axios.post(apiUrl, formData, {
          headers,
        });
        setIsLoading(false);
        console.log("response:: ", JSON.stringify(response));
        console.log(
          "response.data.success:: ",
          JSON.stringify(response.data.success)
        );
        if (response.data.success == "success") {
          console.log("123");
          setApiStatus(response.data.message);
          setShowResponsePopup(true);
          // Handle success
          console.log("File uploaded successfully");
        } else {
          console.log("456");
          // Handle error
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Error while uploading file", error);
      }
    } else {
      console.warn("No file selected for upload");
    }
  };

  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }
    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, []);

  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, [filteredData]); // Initialize when data changes

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const apiUrl = getUploadedExcelFileDetails;
    const headers = await GetApiHeaders();
    //Make the POST request using Axios with headers
    setIsLoading(true);
    const response = await axios.get(apiUrl, { headers });
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    if (
      response.data.statusCode == "200" &&
      response.data.response != null &&
      response.data.response != undefined
    ) {
      if (
        response.data.response.ExcelList != null &&
        response.data.response.ExcelList != undefined
      ) {
        setData(response.data.response.ExcelList);
        console.log(data);
      }
    }
  };
  const handledownloadexcel = (e) => {
    //alert(e);
    const link = document.createElement("a");
    link.href = e; ///download.filePath;
    link.download = e; // download.filePath;
    link.click();
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key]?.toString().toLowerCase();
      const bValue = b[sortConfig.key]?.toString().toLowerCase();
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };



  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">R & M Tracking</h5>
                    <div>
                      <h6 className="mb-0 Sub_role_text"> Bulk Allocation</h6>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "20px" }}
                    >
                      <span
                        tabindex="0"
                        title="download File"
                        style={{ padding: 10 }}
                      >
                        <label
                          className="uploadExcel"
                          style={{
                            padding: 10,
                            backgroundColor: "white",
                            height: 150,
                            width: 400,
                            borderColor: "#08615F",
                            borderWidth: 3,
                            borderRadius: 10,
                          }}
                        >
                          {/* <input type='file'/>  */}
                          <img
                            src={Download_1}
                            alt="downloadImg"
                            style={{
                              height: 50,
                              width: 100,
                              marginTop: 35,
                              backgroundColor: "white",
                            }}
                            onClick={handleDownload}
                          />
                        </label>
                        <div
                          style={{
                            fontWeight: 500,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          Download
                        </div>
                      </span>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <span
                        tabindex="0"
                        title="Upload File"
                        style={{ padding: 10 }}
                      >
                        <label
                          className="uploadExcel"
                          style={{
                            padding: 10,
                            backgroundColor: "white",
                            height: 150,
                            width: 400,
                            borderColor: "#fd7e14",
                            borderWidth: 3,
                            borderRadius: 10,
                          }}
                        >
                          <input type="file" onChange={handleFileChange} />
                          <img
                            src={Upload_2}
                            alt="uploadImg"
                            style={{
                              height: 50,
                              width: 100,
                              marginTop: 35,
                              backgroundColor: "white",
                            }}
                          />
                        </label>
                        <div
                          style={{
                            fontWeight: 500,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          Upload
                        </div>
                      </span>
                    </div>



                    <div className="my-2 d-flex w-full justify-content-end gap-2">
                      <button className="btn btn-secondary buttons-excel buttons-html5"
                        onClick={exportToExcel}>
                        Download Excel
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                        onClick={handlePrint}
                      >
                        Print
                      </button>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body table-responsive">


                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ paddingTop: 20, paddingBottom: 20 }}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> entries</label>
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                placeholder="🔍 Type to Search..."
                                value={searchTerm}
                                onChange={(e) => handleFilter(e.target.value)}
                                style={{
                                  height: "36px",
                                  width: "160px",
                                  paddingRight: "30px",
                                }}
                                className="mr-2"
                              />
                              {searchTerm && (
                                <button
                                  className="btn btn-link"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                    zIndex: 1,
                                  }}
                                  onClick={() => handleFilter("")}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              )}
                            </div>
                          </div>
                          <Table className="table table-bordered Content_data_table table-striped">
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.no</Th>
                                <Th className="text-center" onClick={() => handleSort("mobileNumber")}>
                                  Mobile Number{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "mobileNumber"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("name")}>
                                  Name{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "name"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("date")}>
                                  Date & Time{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "date"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center">File Path</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {currentItems != null &&
                                currentItems != undefined &&
                                sortedItems.map((item, index) => {
                                  return (
                                    <Tr>
                                      <Td className="text-left">
                                        {" "}
                                        {(currentPage - 1) * itemsPerPage + index + 1}
                                      </Td>
                                      <Td className="text-left">
                                        {item.mobileNumber}
                                      </Td>
                                      <Td className="text-left">{item.name}</Td>
                                      <Td className="text-left">
                                        {moment(item.date).format(
                                          "DD-MMM-YYYY hh:mm:ss A"
                                        )}{" "}
                                      </Td>
                                      <Td className="text-center">
                                        <img
                                          src={Downloadicon}
                                          alt="downloadImg"
                                          style={{
                                            height: "25px",
                                            marginTop: 3,
                                            backgroundColor: "white",
                                          }}
                                          onClick={() =>
                                            handledownloadexcel(
                                              item.filePath.toString()
                                            )
                                          }
                                        />{" "}
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </div>
                        {/* Pagination */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5, paddingTop: 20 }}
                        >
                          <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                            &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                            of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                                  }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button

                                className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                                  }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                    }`}

                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                    }`}

                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                  ? "active"
                                  : ""
                                  }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            className="modalBlur"
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Bulk Allocation
                                </span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close this Window
                                    ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Assign Work</span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Bulk Allocation
                                </span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Bulk Allocation</span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>{apiStatus}</p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading && (
              <Loader_Bulk
                loading={isLoading}
                message={"Fetching Data. Please Wait..!"}
                loderimage={loaderImage}
              />
            )}
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default BulkAllocation;
