import React, { useState, useEffect, useRef } from "react";
import moment from "moment"; // Date Formate Library
import axios from "axios";
import $ from "jquery";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../Pages/SideMenu";
import Header from "../../Pages/Header";
import Footer from "../../Pages/Footer";
import Logout from "../../Pages/Logout";
import UploadFile from "../../images/uploadfile.png";

//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";

//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

// Date
import "react-datepicker/dist/react-datepicker.css";
import {
  getMasters,
  openStock,
  stockInWardSubmit,
} from "../../Utilities/URLCONSTANTS";
// import { Oval as Loader } from 'react-loader-spinner';
import Loader from "../../Utilities/Loader";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
import { filterObjects } from "../../Utils";
//import Loader from '../Utilities/Loader';
import * as XLSX from "xlsx"; // Import XLSX as an object
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from 'react-router-dom';
import MassUpload from "./massUpload";



//UserMaster

const StockInWard = () => {
  const navigate = useNavigate();
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Id, setId] = useState(0);
  const [stockInwardDate, setStockInwardDate] = useState("");
  const [newStockInwardDate, setNewStockInwardDate] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCategoryID, setProductCategoryID] = useState("");
  const [productName, setProductName] = useState("");
  const [productsMaster, setProductsMaster] = useState("");
  // const [productNameID, setProductNameID] = useState(-1);
  const [productNameID, setProductNameID] = useState("");
  const [productMake, setProductMake] = useState("");
  const [productMakeID, setProductMakeID] = useState("");
  const [productInDate, setProductInDate] = useState(null);
  const [newProductInDate, setNewProductInDate] = useState("");
  const [stockInQuantity, setStockInQuantity] = useState("");
  const [unitSize_uim, setUnitSize_uim] = useState("");
  const [unitSize_uimID, setUnitSize_uimID] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [productStoreLocation, setProductStoreLocation] = useState("");
  const [productStoreLocationID, setProductStoreLocationID] = useState("");
  // const [productRackLocation, setProductRackLocation] = useState("");
  const [productPONumber, setProductPONumber] = useState("");
  const [productInvoiceNumber, setProductInvoiceNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorName1, setVendorName1] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [productManufactureDate, setProductManufactureDate] = useState("");
  const [newProductManufactureDate, setNewProductManufactureDate] =
    useState("");
  const [productExpiryDate, setProductExpiryDate] = useState("");
  const [newProductExpiryDate, setNewProductExpiryDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [submittedDate, setSubmittedDate] = useState("");
  const [newSubmittedDate, setNewSubmittedDate] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUploadShow, setImageUploadShow] = useState(null);
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);
  //const [format, setFormat] = useState("");
  const [recordExists, setRecordExists] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [getMasterVendor, setGetMasterVendor] = useState([]);
  const [dcOrInvoice, setDcOrInvoice] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [showPopup, setShowPopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  //Errors
  const [errorSubmittedDate, setErrorSubmittedDate] = useState("");
  const [errorProductInDate, setErrorProductInDate] = useState("");
  const [errorStockInwardDate, setErrorStockInwardDate] = useState("");
  const [errorProductCategory, setErrorProductCategory] = useState("");
  const [errorProductName, setErrorProductName] = useState("");
  const [errorProductMake, setErrorProductMake] = useState("");
  const [errorStockInQuantity, setErrorStockInQuantity] = useState("");
  const [errorUnitSize, setErrorUnitSize] = useState("");
  const [errorUnitPrice, setErrorUnitPrice] = useState("");
  const [errorInvoiceDc, setErrorInvoiceDc] = useState("");
  const [errorProductStoreLocation, setErrorProductStoreLocation] =
    useState("");
  // const [errorProductRackLocation, setErrorProductRackLocation] = useState("");
  const [errorProductPONumber, setErrorProductPONumber] = useState("");
  const [errorProductInvoiceNumber, setErrorProductInvoiceNumber] =
    useState("");
  const [productInvoiceOrDCno, setProductInvoiceOrDCno] = useState();
  const [errorVendorName, setErrorVendorName] = useState("");
  const [errorBatchNumber, setErrorBatchNumber] = useState("");
  const [errorProductManufactureDate, setErrorProductManufactureDate] =
    useState("");
  const [errorProductExpiryDate, setErrorProductExpiryDate] = useState("");
  const [errorRemarks, setErrorRemarks] = useState("");
  // const [base64Image, setBase64Image] = useState(null);
  const [errorImageUpload, setErrorImageUpload] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [productCategoryOptions, setProductCategoryOptions] = useState([]);
  const [unitSizeOptions, setUnitSizeOptions] = useState([]);
  const [productNameOptions, setProductNameOptions] = useState([]);
  const [productNameOptionsOriginal, setProductNameOptionsOriginal] = useState(
    []
  );
  const [storeLocationOptions, setStoreLocationOptions] = useState([]);

  const [loaderImage, setLoaderImage] = useState(
    require("../../images/cs_loader.gif")
  );
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm

  const [selectedOption, setSelectedOption] = useState("invoice"); // Initialize with 'invoice' as the default option
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [dcNumber, setDcNumber] = useState("");


  const handleFilter = (searchTerm) => {

    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.productInDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productManufactureDate.toLowerCase().includes(searchTerm.toLowerCase()) ||

          item.productExpiryDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.submittedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productCategory.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productMake.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.inWardStock.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.unitSize_uim.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productStoreLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productPONumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productInvoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.vendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.batchNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = (item) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return ''; // Return empty string for invalid dates
      }

      const day = ('0' + date.getDate()).slice(-2);
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };

    const data = [
      [
        "S.no",
        "productInDate",
        "productManufactureDate", // Custom headers
        "productExpiryDate",
        "submittedDate",
        "productCategory",
        "productName",
        "productMake",
        "inWardStock",
        "unitSize_uim",
        "unitPrice",
        "productStoreLocation",
        "productPONumber",
        "productInvoiceNumber",
        "vendorName",
        "batchNumber",
        "productInvoiceOrDC",
        "remarks",
        "imageUpload",
        // 'Status'
      ],
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        formatDate(item.productInDate),
        formatDate(item.productManufactureDate),
        formatDate(item.productExpiryDate),
        formatDate(item.submittedDate),
        item.productCategory,
        item.productName,
        item.productMake,
        item.inWardStock,
        item.unitSize_uim,
        item.unitPrice,
        item.productStoreLocation,
        item.productPONumber,
        item.productInvoiceNumber,
        item.vendorName,
        item.batchNumber,
        item.productInvoiceOrDC,
        item.remarks,
        item.imageUpload,
        // item.status === "1" ? "Active" : "InActive",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "StockInWard.xlsx");
  };

  useEffect(() => {
    console.log("datahghghg", JSON.stringify(data));
  }, [data]);

  // validations

  const handleFileChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result;
        const base64 = dataURL.split(",")[1];
        //setprofilePicUrl(base64);
        setImageUpload(base64);
        setImageUploadShow(dataURL);
        setErrorImageUpload("");
      };

      reader.onerror = () => {
        setErrorImageUpload("Error reading the selected image.");
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  const handleProductCategory = async (event) => {
    const selectedCategory = event.target.value;
    const selectedCategoryId =  event.target.options[event.target.selectedIndex].getAttribute("code"); 
    setProductCategory(selectedCategory);
    setProductCategoryID(selectedCategoryId);
    var productNameOptionsList = await filterObjects( productNameOptionsOriginal,  "productCategoryId",  selectedCategoryId  ); 
    console.log("productNameOptionsList", productNameOptionsList);
    if (productNameOptionsList.length > 0) {
      setProductNameOptions(productNameOptionsList);
    }
    console.log("=========>", productNameOptionsList);
    if (selectedCategory) {
      setErrorProductCategory("");
    }
  };

  const handleProductName = (event) => {
    const selectedValue = event.target.value;
    const selectedId =  event.target.options[event.target.selectedIndex].getAttribute("code");
    const selectedBrand =  event.target.options[event.target.selectedIndex].getAttribute("data-productMake");
    const selectedUIM =  event.target.options[event.target.selectedIndex].getAttribute("data-uim");
    const selectedUnitCost =  event.target.options[event.target.selectedIndex].getAttribute("data-unitCost");
    const selectedProductMakeId =  event.target.options[event.target.selectedIndex].getAttribute("data-productbrandId");

    setProductMake(selectedBrand)
    setUnitSize_uim(selectedUIM)
    setUnitPrice(selectedUnitCost)
    setProductMakeID(selectedProductMakeId)
 
    if (selectedValue) { 
      setProductName(selectedValue);
      setProductNameID(selectedId);
      setErrorProductName("");
    } else {
      setErrorProductName("Product name cannot exceed 255 characters.");
    }
  };
console.log(productMake,"Product Make")
  const handleProductBrand = (event) => {
    const selectedMake = event.target.value;
    const selectedMakeId =
      event.target.options[event.target.selectedIndex].getAttribute("code");
    console.log("Brand", selectedMake + "--" + selectedMakeId);
    setProductMake(selectedMake);
    setProductMakeID(selectedMakeId);
    if (selectedMake) {
      setErrorProductMake("");
    }
  };

  const handleProductInDate = (event) => {
    var date = event;
    if (!moment.isMoment(date)) {
      date = moment(date); // Convert to moment object if not already
    }
    const newDob = event.target.value; //alert(newDob);
    //alert(newDob);
    if (date.isValid()) {
      const today = moment();
      const minDate = moment().subtract(30, "days");
      if (date.isBefore(minDate)) {
        setErrorProductInDate(
          "Date should not be more than 30 days in the past."
        );
        return;
      } else if (date.isAfter(today)) {
        setErrorProductInDate("Date should not be in the future.");
        return;
      }
      setErrorProductInDate("");
      setProductInDate(date);
      const selectedDateObj = new Date(newDob);
      const yyyy = selectedDateObj.getFullYear();
      let mm = selectedDateObj.getMonth() + 1; // Months start at 0!
      let dd = selectedDateObj.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      //setDob(newDob);
      setStockInwardDate(yyyy + "-" + mm + "-" + dd);
      setNewStockInwardDate(dd + "-" + mm + "-" + yyyy);
      const formattedProductInDate = date.format("YYYY-MM-DD HH:mm:ss.SSS");
      console.log("Formatted ProductInDate:", formattedProductInDate);
    } else {
      setErrorProductInDate("Invalid date.");
      setProductInDate(null);
    }
    if (productInDate && productInDate.isValid()) {
      setErrorProductInDate("");
    }
  };

  const handleSubmittedDate = (e) => {
    setSubmittedDate(e.target.value);
    const selectedDateObj = new Date(e.target.value);
    // Format the date as DD-MM-YYYY
    const yyyy = selectedDateObj.getFullYear();
    let mm = selectedDateObj.getMonth() + 1; // Months start at 0!
    let dd = selectedDateObj.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    setNewSubmittedDate(dd + "-" + mm + "-" + yyyy);
    if (e.target.value) {
      setErrorSubmittedDate("");
      const formattedSubmittedDate = moment(submittedDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      console.log("Formatted Submitted Date:", formattedSubmittedDate);
    }
  };
 

  const handleStockInQuantity = (e) => {
    const input = e.target.value;

    // Handle empty input
    if (input === "") {
      setStockInQuantity("");
      setErrorStockInQuantity("");
      return;
    }

    // Prevent negative values
    if (input.startsWith("-")) {
      setErrorStockInQuantity("Negative values are not allowed.");
      return;
    }

    // Check if the input is a valid number with up to 5 digits
    if (/^[0-9]{1,5}$/.test(input)) {
      const Quantity = parseInt(input, 10); // Convert the input string to an integer
      if (Quantity >= 1 && Quantity <= 99999) {
        // If the input is within the valid range
        setStockInQuantity(Quantity);
        setErrorStockInQuantity("");
      } else {
        setErrorStockInQuantity("Please enter a number between 1 and 99999.");
      }
    } else {
      setErrorStockInQuantity("Should accept up to 5 digits only.");
    }
  };

  const handleUnitSize = (event) => {
    const selectedSize = event.target.value;
    const selectedSizeId =
      event.target.options[event.target.selectedIndex].getAttribute("code");
    console.log("UnitSize", selectedSize + "--" + selectedSizeId);
    setUnitSize_uim(event.target.value);
    setUnitSize_uimID(event.target.value);
    if (selectedSize) {
      setUnitSize_uim(selectedSize);
      setErrorUnitSize("");
    }
  };

  const handleUnitPrice = (e) => {
    const input = e.target.value;

    // Prevent negative values
    if (input.startsWith('-')) {
      setErrorUnitPrice("Negative values are not allowed.");
      return;
    }

    // Check if the input is a valid number with up to 5 digits before the decimal
    if (/^\d{1,5}(\.\d*)?$/.test(input)) {
      const inputValue = parseFloat(input); // Convert the input string to a floating-point number

      if (inputValue >= 1 && inputValue <= 99999) {
        // If the input is within the valid range
        setUnitPrice(inputValue);
        setErrorUnitPrice("");
      } else {
        setErrorUnitPrice("Please enter a number between 1 and 99999.");
      }
    } else {
      setErrorUnitPrice("The input should have up to 5 digits before the decimal.");
    }
  };


  const handleProductStoreLocation = (event) => {
    const selectedStoreLocation = event.target.value;
    const selectedStoreLocationId =
      event.target.options[event.target.selectedIndex].getAttribute("code");
    console.log(
      "Location",
      selectedStoreLocation + "--" + selectedStoreLocationId
    );
    setProductStoreLocation(selectedStoreLocation);
    setProductStoreLocationID(selectedStoreLocationId);
    if (selectedStoreLocation) {
      setErrorProductStoreLocation("");
    }
  };



  const handleProductPoNumber = (e) => {
    const input = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    setProductPONumber(input);
    if (input.length > 0) {
      if (input.length < 8 || input.length > 64) {
        setErrorProductPONumber(
          "Product po number should be min 8 charcaters."
        );
      } else if (!alphanumericRegex.test(input)) {
        setErrorProductPONumber("Please enter only alphanumeric characters.");
      } else {
        setErrorProductPONumber("");
      }
    } else {
      setErrorProductPONumber("Product PO Number is required.");
    }
  };

  // productInvoiceNumber
  const handleProductInvoiceNumber = (e) => {
    const invoice = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    setProductInvoiceNumber(invoice);
    if (invoice.length > 0) {
      if (invoice.length < 8 || invoice.length > 64) {
        setErrorProductInvoiceNumber("Enter valid Product Invoice No / DC No.");
      } else if (!alphanumericRegex.test(invoice)) {
        setErrorProductInvoiceNumber(
          "Please enter only alphanumeric characters."
        );
      } else {
        setErrorProductInvoiceNumber("");
      }
    } else {
      setErrorProductInvoiceNumber("Product Invoice Number is required.");
    }
  };



  const handleVendor = (e) => {
    const selectedVendor = e.target.value;
    const selectedVendorId =
      e.target.options[e.target.selectedIndex].getAttribute("code");
    console.log("Location", selectedVendor + "--" + selectedVendorId);
    setVendorName(selectedVendor);
    setVendorID(selectedVendorId);
    if (selectedVendor) {
      setErrorVendorName("");
    }
  };

  const getMasterData = async () => {
    const getData = getMasters;
    const headers = await GetApiHeaders();

    try {
      const responseGetData = await axios.post(getData, null, { headers });
      console.log("getmaster Data,", responseGetData);
      setGetMasterVendor(responseGetData.data.response.vendor);
    } catch (err) {
      console.log("getError", err);
    }
  };

  //batchNumber
  const handleBatchNumber = (e) => {
    const batchNumber = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    setBatchNumber(batchNumber);
    if (batchNumber.length < 8 || batchNumber.length > 64) {
      setErrorBatchNumber("Batch Number must be between 8 and 64 characters.");
    } else if (!alphanumericRegex.test(batchNumber)) {
      setErrorBatchNumber("Please enter only alphanumeric characters.");
    } else {
      setErrorBatchNumber("");
    }
  };
  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  //productManufactureDate
  const handleProductManufactureDate = (e) => {
    const Manufacture = e.target.value; //alert(Manufacture);
    setProductManufactureDate(Manufacture);
    const selectedDateObj = new Date(Manufacture);
    const yyyy = selectedDateObj.getFullYear();
    let mm = selectedDateObj.getMonth() + 1; // Months start at 0!
    let dd = selectedDateObj.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    setNewProductManufactureDate(dd + "-" + mm + "-" + yyyy);
    if (Manufacture) {
      setErrorProductManufactureDate("");
      // Format the date using Moment.js
      const formattedDate = moment(productManufactureDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      console.log("Formatted Date:", formattedDate);
    }
  };
  //productExpiryDate
  // const moment = require('moment'); // Import Moment.js library

  const handleProductExpiryDate = (e) => {
    const Expiry = e.target.value;

    // If a valid date is selected
    if (Expiry.length > 0) {
      const formattedExpiryDate = moment(Expiry).format("YYYY-MM-DD"); // Ensure only date (no time)

      // Check if the expiry date is before the manufacture date
      if (moment(Expiry).isBefore(productManufactureDate)) {
        setErrorProductExpiryDate(
          "Expiry date cannot be earlier than manufacturing date."
        );
      } else {
        setProductExpiryDate(formattedExpiryDate); // Store the formatted expiry date

        // Format the date for display (DD-MM-YYYY)
        const selectedDateObj = new Date(Expiry);
        let mm = selectedDateObj.getMonth() + 1; // Months start at 0!
        let dd = selectedDateObj.getDate();
        const yyyy = selectedDateObj.getFullYear();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        setNewProductExpiryDate(dd + "-" + mm + "-" + yyyy); // Display format as DD-MM-YYYY

        setErrorProductExpiryDate(""); // Clear error message
        console.log("Formatted Expiry Date:", formattedExpiryDate);
      }
    } else {
      setProductExpiryDate("");
      setErrorProductExpiryDate("");
    }
  };

  //Remarks
  const handleRemarks = (e) => {
    const inputRemarks = e.target.value;
    if (inputRemarks.length > 255) {
      setErrorRemarks("Remarks cannot exceed 255 characters.");
      return;
    } else {
      setErrorRemarks("");
    }
    setRemarks(inputRemarks);
  };

  //Status
  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (status.length > 0) {
      setErrorStatus("");
    }
  };

  const handleSubmit = () => {
    //alert('submitted')
    if (productInDate === "") {
      setErrorProductInDate("Please Enter a product-In-Date");
    }
    if (productCategory === "") {
      setErrorProductCategory("Please Select Product-Category");
    }
    if (productName === "") {
      setErrorProductName("Please Select  ProductName");
    }
    if (productMake === "") {
      setErrorProductMake("Please Select  ProductBrand");
    }
    if (stockInQuantity === "") {
      setErrorStockInQuantity("Please Enter a StockInQuantity");
    }
    if (unitSize_uim === "") {
      setErrorUnitSize("Please Select UnitSize");
    }
    if (unitPrice === "") {
      setErrorUnitPrice("Please Enter a UnitPrice");
    }
    if (productStoreLocation === "") {
      setErrorProductStoreLocation("Please Select  Product Store Location");
    }
    if (productInvoiceOrDCno === "") {
      setErrorInvoiceDc("Please Enter Invoice or Dc No");
    }

    if (productPONumber === "") {
      setErrorProductPONumber("Please Enter a Product PO Number");
    }
    if (productInvoiceNumber === "") {
      setErrorProductInvoiceNumber("Please select Invoice Number or Dc No");
    }
    if (vendorName === "") {
      setErrorVendorName("Please select VendorName");
    }
    if (batchNumber === "") {
      setErrorBatchNumber("Please Enter a BatchNumber");
    }
    if (productManufactureDate === "") {
      setErrorProductManufactureDate("Please Select Product Manufacture Date");
    }
    if (productExpiryDate === "") {
      setErrorProductExpiryDate("Please Select Product Expiry Date");
    }
    if (submittedDate === "") {
      setErrorSubmittedDate("Please Enter a SubmittedDate");
    }

    if (imageUpload === "") {
      setErrorImageUpload("Upload Image");
    }
    if (remarks === "") {
      setErrorRemarks("Please Enter a Remarks");
    }
    if (status === "") {
      setErrorStatus("Please Enter a Status");
    }

    if (recordExists) {
      setRecordExists("Record already exists.");
      return; // Stop further processing if the record exists
    }

    if (productInDate != "" && productInvoiceNumber != "") {
      if (Id == 0) {
        setIsSubmitted(true);
        handlePostData();
        setErrorProductInDate("");
        setErrorProductCategory("");
        setErrorProductName("");
        setErrorProductExpiryDate("");
        setErrorProductManufactureDate("");
        setErrorBatchNumber("");
        setErrorVendorName("");
        setErrorProductInvoiceNumber("");
        setErrorProductPONumber("");
        setErrorProductStoreLocation("");
        setErrorUnitPrice("");
        setErrorUnitSize("");
        setErrorStockInQuantity("");
        setErrorProductMake("");
        setErrorSubmittedDate("");
        setErrorImageUpload("");
        setErrorRemarks("");
        setErrorStatus("");
      } else {
        //  alert("update"+Id)
        setIsSubmitted(false);
        handlePostData();
        setIsUpdated(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
    getMasterData();
  }, []);
  const fetchData = async () => {
    const apiUrl = openStock;
    const headers = await GetApiHeaders();
    console.log("request with api call", apiUrl);
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          console.log("response", response);
          setData(response.data.response.openStock);
          setIsLoading(false);
          // setLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        });
    }, 900);
  };

  useEffect(() => {
    getmasters();
  }, []);
  const getmasters = async () => {
    const apiUrl = getMasters;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    axios
      .post(apiUrl, null, { headers })
      .then((response) => {
        setIsLoading(false);
        console.log("responseMake", response);
        setBrandOptions(response.data.response.productBrand);
        setProductCategoryOptions(response.data.response.productCategoryMaster);
        setUnitSizeOptions(response.data.response.uim);
        setProductNameOptionsOriginal(response.data.response.productsMaster);
        setStoreLocationOptions(response.data.response.stockInwardLocations);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handlePostData = async () => {

    const apiUrl = stockInWardSubmit;
    const headers = await GetApiHeaders();
    const postData = {
      productInDate: stockInwardDate + " 11:59:30.503", //"2023-09-01 11:59:30.503",//newStockInwardDate, // 2023-06-29 11:59:30.503,
      productCategoryID: parseInt(productCategoryID),
      productCategory: productCategory,
      productNameID: parseInt(productNameID),
      productName: productName,
      productMakeID: parseInt(productMakeID),
      productMake: productMake,
      stockInQuantity: stockInQuantity,
      unitSize_uim: unitSize_uim,
      unitPrice: unitPrice,
      productStoreLocationID: parseInt(productStoreLocationID),
      productStoreLocation: productStoreLocation,
      productInvoiceOrDC: productInvoiceOrDCno,
      productPONumber: productPONumber,
      productInvoiceNumber: productInvoiceNumber,
      vendorName: vendorName,
      vendorID: parseInt(vendorID),
      batchNumber: batchNumber,
      productManufactureDate: productManufactureDate + " 11:59:30.503",
      productExpiryDate: productExpiryDate + " 11:59:30.503",
      submittedDate: submittedDate + " 11:59:30.503",
      imageUpload: imageUpload,
    };

    console.log("request with api call", apiUrl);

    console.log("request with api call", JSON.stringify(postData));
    setIsLoading(true);

    axios
      .post(apiUrl, postData, { headers })
      .then((response) => {
        setsubmitResponse(response.data.message);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);
        console.log("submit Response:", response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }

    $(tableRef.current).DataTable({
      ordering: false,
    });
  }, []);

  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    // setStockInwardDate("");
    setProductInDate("");
    setProductCategory("");
    setProductName("");
    setProductMake("");
    setStockInQuantity("");
    setUnitSize_uim("");
    setUnitPrice("");
    setProductStoreLocation("");
    setProductPONumber("");
    setProductInvoiceNumber("");
    setVendorName("");
    setBatchNumber("");
    setProductManufactureDate("");
    setProductExpiryDate("");
    setRemarks("");
    setStatus("");
    setSubmittedDate("");
    setImageUpload("");
    // setId(0);
  };

  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowWarningPopup(false);
    setShowMsg(true);
  };

  const handleCancel_new = () => {
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };

  const closeWarning = () => {
    setShowWarningPopup(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  const updatedItems = (item) => {
    // setModalData(item)
    setId(item.id);
    // setStockInwardDate(item.stockInWardDate);
    setProductInDate(item.productInDate);
    setProductCategory(item.productCategory);
    setProductName(item.productName);
    setProductMake(item.productMake);
    setStockInQuantity(item.stockInQuantity);
    setUnitSize_uim(item.unitSize_uim);
    setUnitPrice(item.UnitPrice);
    setSubmittedDate(item.submittedDate);
    setProductStoreLocation(item.productStoreLocation);
    // setProductRackLocation(item.productRackLocation);
    setProductPONumber(item.productPONumber);
    setProductInvoiceNumber(item.productInvoiceNumber);
    setVendorName(item.vendorName);
    setBatchNumber(item.batchNumber);
    setProductManufactureDate(item.productManufactureDate);
    setProductExpiryDate(item.productExpiryDate);
    setImageUpload(item.imageUpload);
    setSubmittedDate(item.submittedDate);
    setRemarks(item.remarks);
    setStatus(item.status);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };


  const handleRadioChange = (value) => {
    setProductInvoiceOrDCno(value);
    if (value) {
      setErrorProductInvoiceNumber("");
    }
  };

  const handleDownload = () => {
    const data = [
      { sno: '', product: '', name: '' },
    ];
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Stock In Ward.xlsx');
  };


  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }
      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleUploadClick = () => {
    navigate("/massUpload");
  };


  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Stock In - Ward</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text">
                          Stock In - Ward Form
                        </h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add Stock In Ward"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleUploadClick}

                        >
                          <FontAwesomeIcon
                            icon={faUpload}
                            className="mr-2"
                          />
                          Upload
                        </Button>

                        {/* <Button
                          variant="primary"
                          className="buttons mx-2" onClick={handleDownload}>
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="mr-1"
                          />
                          Download
                        </Button> */}
                        &nbsp;

                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingLeft: 25, paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* Search Input */}
                        <input
                          type="text"
                          placeholder="🔍 Type to Search..."
                          value={searchTerm}
                          onChange={(e) => handleFilter(e.target.value)}
                          style={{ height: "36px", width: "160px" }}
                          className="mr-1"
                        />{" "}
                        &nbsp;
                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                          <button
                            className="btn btn-secondary buttons-excel buttons-html5"
                            onClick={exportToExcel}
                          >
                            Download Excel
                          </button>

                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div
                          className="card-body"
                          style={{ overflowX: "auto" }}
                        >
                          <Table className="table table-bordered Content_data_table table-striped">
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                <Th className="text-center" onClick={() => handleSort("newDateFormat")}>
                                  Stock In - Ward Date
                                  <i
                                    className={`fa ${sortConfig.key === "newDateFormat"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productCategory")}>
                                  Product Category
                                  <i
                                    className={`fa ${sortConfig.key === "productCategory"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productName")}>
                                  Product Name
                                  <i
                                    className={`fa ${sortConfig.key === "productName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productMake")}>
                                  Product Make(Brand)
                                  <i
                                    className={`fa ${sortConfig.key === "productMake"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("inWardStock")}>
                                  In-Ward Stock(Stock In Quantity)
                                  <i
                                    className={`fa ${sortConfig.key === "inWardStock"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("unitSize_uim")}>
                                  Unit Size(UIM)
                                  <i
                                    className={`fa ${sortConfig.key === "unitSize_uim"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("unitPrice")}>
                                  Unit Price
                                  <i
                                    className={`fa ${sortConfig.key === "unitPrice"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productStoreLocation")}>
                                  Product Store Location
                                  <i
                                    className={`fa ${sortConfig.key === "productStoreLocation"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productPONumber")}>
                                  Product PO Number
                                  <i
                                    className={`fa ${sortConfig.key === "productPONumber"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productInvoiceNumber")}>
                                  Product Invoice/DC Number
                                  <i
                                    className={`fa ${sortConfig.key === "productInvoiceNumber"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productInvoiceOrDC")}>
                                  Product Invoice / DC
                                  <i
                                    className={`fa ${sortConfig.key === "productInvoiceOrDC"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("vendorName")}>
                                  Vendor Name
                                  <i
                                    className={`fa ${sortConfig.key === "vendorName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("batchNumber")}>
                                  Batch Number
                                  <i
                                    className={`fa ${sortConfig.key === "batchNumber"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("manufactureDate")}>
                                  Product Manufacture Date
                                  <i
                                    className={`fa ${sortConfig.key === "manufactureDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("expiryDate")}>
                                  Product Expiry Date
                                  <i
                                    className={`fa ${sortConfig.key === "expiryDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("sumiteDate")}>
                                  Submitted Date & Time
                                  <i
                                    className={`fa ${sortConfig.key === "sumiteDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("imageUpload")}>
                                  Uploaded Image
                                  <i
                                    className={`fa ${sortConfig.key === "imageUpload"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("remarks")}>
                                  Remarks
                                  <i
                                    className={`fa ${sortConfig.key === "remarks"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {sortedItems.length === 0 ? ( // Check if no data is found
                                <Tr>
                                  <Td
                                    colSpan={20}
                                    className="text-center"
                                    style={{ padding: 195, fontSize: 18 }}
                                  >
                                    Data is not available
                                  </Td>
                                </Tr>
                              ) : (
                                sortedItems.map((item, index) => {
                                  var newDateFormat = moment(item.productInDate).format("DD-MMM-YYYY ");
                                  var manufactureDate = moment(item.productManufactureDate);
                                  if (manufactureDate.isValid()) {
                                    manufactureDate = manufactureDate.format("DD-MMM-YYYY ")
                                  } else {
                                    manufactureDate = ""
                                  }
                                  var expiryDate = moment(item.productExpiryDate);
                                  if (expiryDate.isValid()) {
                                    expiryDate = expiryDate.format("DD-MMM-YYYY ")
                                  } else {
                                    expiryDate = ""
                                  }
                                  var sumiteDate = moment(item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A");
                                  return (
                                    <Tr key={index}>
                                      <Td>{(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                      <Td>{newDateFormat}</Td>
                                      <Td>{item.productCategory}</Td>
                                      <Td className="text-left">
                                        {item.productName}
                                      </Td>
                                      <Td>{item.productMake}</Td>
                                      <Td className="text-right">
                                        {item.inWardStock}
                                      </Td>
                                      <Td className="text-left">
                                        {item.unitSize_uim}
                                      </Td>
                                      <Td className="text-right">
                                        {item.unitPrice}
                                      </Td>
                                      <Td>{item.productStoreLocation}</Td>
                                      <Td className="text-right">
                                        {item.productPONumber}
                                      </Td>
                                      <Td className="text-end">
                                        {item.productInvoiceNumber}
                                      </Td>
                                      <Td className="text-end">
                                        {item.productInvoiceOrDC}
                                      </Td>
                                      <Td className="text-left">
                                        {item.vendorName}
                                      </Td>
                                      <Td className="text-right">
                                        {item.batchNumber}
                                      </Td>
                                      <Td>{manufactureDate}</Td>
                                      <Td>{expiryDate}</Td>
                                      <Td>{sumiteDate}</Td>
                                      <Td>
                                        <img
                                          src={item.imageUpload}
                                          style={{
                                            width: "30px",
                                            height: "40px",
                                            cursor: "pointer",
                                            border: "1px solid black",
                                          }}
                                          onClick={() =>
                                            handleImageClick(item.imageUpload)
                                          }
                                        />
                                      </Td>
                                      <Td className="text-left">
                                        {item.remarks}
                                      </Td>
                                    </Tr>
                                  );
                                })
                              )}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                        </div>
                        {/* Pagination */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5 }}
                        >
                          <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                            to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)} of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                                  }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button
                                //  className='ul-pagination page-item'
                                //   style={{
                                //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                //     color: (currentPage == 1) ? 'white' : 'black'
                                //   }}
                                className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                                  }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                    }`}
                                  //className='ul-pagination page-item'
                                  //   style={{
                                  //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                  //     color: (currentPage == number) ? 'white' : 'black'
                                  //   }}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                    }`}
                                  // className='ul-pagination page-item'
                                  // style={{
                                  //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                  //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                  // }}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                  ? "active"
                                  : ""
                                  }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body
                              className="pl-md-5 pr-md-5 pt-0"
                              style={{ overflowY: "auto", maxHeight: "85vh" }}
                            >
                              <div className="d-flex justify-content-center mb-3 font-weight-600 ">
                                <span className="modal_title">
                                  Stock In Ward
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputDate"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Stock In - Ward Date
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      placeholder="Stock in - ward Date"
                                      selected={productInDate}
                                      onChange={handleProductInDate}
                                      value={stockInwardDate}
                                      maxDate={new Date()} // Maximum date is today
                                      minDate={moment()
                                        .subtract(30, "days")
                                        .toDate()} // Minimum date is 30 days ag
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductInDate}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12">
                                  <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product Category
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      type="text"
                                      onChange={handleProductCategory}
                                    >
                                      <option value="">
                                        Select Product Category
                                      </option>
                                      {productCategoryOptions.map((item) => (
                                        <option
                                          key={item.code}
                                          value={item.name}
                                          code={item.code}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductCategory}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    htmlFor="InputName"
                                    className="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product Name
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={productName}
                                      onChange={handleProductName}
                                    >
                                      <option value="">Select Product</option>
                                      {productNameOptions.map((item) => (
                                        <option
                                          key={item.code}
                                          value={item.name}
                                          code={item.code}
                                          data-productMake={item.productMake}
                                          data-uim={item.uim}
                                          data-unitCost={item.unitCost}
                                          data-productbrandId={item.productMakeId}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductName}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    htmlFor="InputText"
                                    className="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product Make(Brand)
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input type="text" className="form-control p-2" value={productMake} placeholder="Product Make"  />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductMake}
                                  </span>
                                </div>

                                <div className="col-md-6 mt-1">
                                  <label
                                    for="fileInput"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Stock In Quantity
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder="Stock-In-Quantity"
                                      value={stockInQuantity}
                                      onChange={handleStockInQuantity}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorStockInQuantity}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    htmlFor="InputText"
                                    className="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Unit Size(UIM)
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input type="text" className="form-control p-2" value={unitSize_uim} placeholder="UIM"  />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorUnitSize}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    htmlFor="InputNumber"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Unit Price
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input type="number" className="form-control p-2 " value={unitPrice}  />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorUnitPrice}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    htmlFor="InputText"
                                    className="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product Store Location
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100 mt-1">
                                    <select
                                      className="form-select p-2"
                                      value={productStoreLocation}
                                      onChange={handleProductStoreLocation}
                                    >
                                      <option value="">
                                        Select Product Store Location
                                      </option>
                                      {storeLocationOptions.map((item) => (
                                        <option
                                          key={item.code}
                                          value={item.name}
                                          code={item.code}
                                          style={{
                                            // overflowY: "auto", w`ssqqqqqqqqqqqqq
                                            minHeight: "50px",
                                          }}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductStoreLocation}
                                  </span>
                                </div>

                                <div className="col-md-6  mt-1">
                                  <label
                                    for="InputNumber"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product PO Number
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="PO Number"
                                      value={productPONumber}
                                      onChange={handleProductPoNumber}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductPONumber}
                                  </span>
                                </div>

                                <div className="col-md-6  mt-1">
                                  {/* <b> Core Permit required ?  <span style={{marginLeft:40}}>:&nbsp; </span> </b>{" "} */}
                                  <label className="form-label text-sm font-weight-600 mb-0">
                                    Please Select Invoice/Dc No
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div>
                                    <label className="">
                                      <input
                                        type="radio"
                                        name="productInvoiceOrDCno"
                                        value={productInvoiceOrDCno}
                                        checked={
                                          productInvoiceOrDCno === "invoice"
                                        } // Check if "Yes" is selected
                                        onChange={() =>
                                          handleRadioChange("invoice")
                                        }
                                      />{" "}
                                      Invoice No
                                    </label>{" "}
                                    &nbsp;
                                    <label>
                                      <input
                                        type="radio"
                                        checked={productInvoiceOrDCno === "dc"}
                                        name="productInvoiceOrDCno"
                                        value={productInvoiceOrDCno}
                                        onChange={() => handleRadioChange("dc")}
                                      />{" "}
                                      DC
                                    </label>{" "}
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductInvoiceNumber}
                                  </span>
                                </div>

                                <div className="col-md-6 mt-1">
                                  <label
                                    for="fileInput"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    {productInvoiceOrDCno === "invoice"
                                      ? "Product Invoice Number"
                                      : "DC Number"}
                                    {/* <i class="fas fa-file-invoice" style={{ color: 'blue' }}></i> */}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder={
                                        productInvoiceOrDCno === "invoice"
                                          ? "Enter Invoice Number"
                                          : "Enter DC Number"
                                      }
                                      onChange={handleProductInvoiceNumber}
                                    />
                                  </div>
                                  {/* <span style={{ color: 'red' }}>{errorProductInvoiceNumber}</span> */}
                                </div>

                                <div className="col-md-6  mt-1">
                                  <label
                                    for="InputText"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Vendor Name
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    {/* <input type="text" className="form-control p-2 " placeholder='Vendor Name' onChange={handleVendorName} /> */}
                                    {/* value={vendorName} onChange={handleVendorName} */}
                                    <select
                                      className="form-select p-2"
                                      onChange={handleVendor}
                                      value={vendorName}
                                    >
                                      <option value="">Select Vendor</option>
                                      {getMasterVendor.map((option) => {
                                        return (
                                          <option
                                            key={option.code}
                                            code={option.code}
                                            value={option.name}
                                          >
                                            {option.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorVendorName}
                                  </span>
                                </div>
                                <div className="col-md-6 mt-1 ">
                                  <label
                                    for="InputText"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Batch Number
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Enter Batch Number"
                                      value={batchNumber}
                                      onChange={handleBatchNumber}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorBatchNumber}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    for="InputNumber"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product Manufacture Date
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      placeholder="select Date"
                                      value={productManufactureDate}
                                      onChange={handleProductManufactureDate}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductManufactureDate}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    for="InputNumber"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Product Expiry Date
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      placeholder="select Date"
                                      value={productExpiryDate}
                                      onChange={handleProductExpiryDate}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorProductExpiryDate}
                                  </span>
                                </div>

                                <div className="col-md-6  mt-1">
                                  <label
                                    htmlFor="InputNumber"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    {" "}
                                    Submitted Date
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2 "
                                      value={submittedDate}
                                      placeholder="Submitted Date"
                                      onChange={handleSubmittedDate}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorSubmittedDate}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-1">
                                  <label
                                    for="InputNumber"
                                    class="form-label text-sm font-weight-600 mb-0"
                                  >
                                    Remarks
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <textarea
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Type here"
                                      value={remarks}
                                      onChange={handleRemarks}
                                    />
                                  </div>
                                  {/* <span style={{ color: 'red' }}>{errorRemarks}</span>
                                  
                                   */}
                                </div>

                                <div className="col-md-6 ">
                                  <label className="form-label text-sm font-weight-600 mb-0">
                                    Upload Invoice / DC
                                    <span style={{ color: "red" }}>*</span>
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept="image/*"
                                      className="mt-2"
                                    />
                                    <img
                                      src={UploadFile}
                                      style={{ height: 70 }}
                                    />
                                  </label>
                                  {imageUpload && (
                                    <div>
                                      <p>Selected Image:</p>
                                      <img
                                        src={imageUploadShow}
                                        alt="Selected"
                                        style={{
                                          maxHeight: "80px",
                                          maxWidth: "50%",
                                          border: "1px solid black",
                                        }} // You can adjust the height and width values
                                      />
                                    </div>
                                  )}
                                  <span style={{ color: "red" }}>
                                    {errorImageUpload}
                                  </span>
                                </div>

                              </div>
                              &nbsp;
                              <div className="row">
                                {isSubmitted ? (
                                  <p style={{ color: "green" }}>
                                    Record submitted.
                                  </p>
                                ) : null}
                                {isUpdated ? (
                                  <p style={{ color: "green" }}>
                                    Updated Successfully.
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center col-md-12">
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => handleSubmit({})}
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  onClick={handleCancel_new}
                                  className="btnn btn btn-danger"
                                >
                                  Cancel
                                </Button>
                              </div>
                              {recordExists && <p>{recordExists}</p>}
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Stock In Ward
                                </span>
                              </Modal.Title>

                              {/* <Button variant="link" className="close btn btn-danger btn-sm close_btn" onClick={handleCancel}>
                                X
                              </Button> */}
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Stock In Ward
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Stock In Ward
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading && (
                  <div>
                    <p>Fetching data. Please wait...</p>

                    <Loader
                      height="80"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default StockInWard;
