import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button, Modal } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import { ChemicalMasterAdd, ChemicalMasterGet, UomGetMaster,   } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { wait } from "@testing-library/user-event/dist/utils";

export const ChemicalMaster = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false) 
    const [name, setName] = useState()
    const [locationName, setLocationName] = useState() 
    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg , setResponseMsg]= useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [uomError, setUomError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)
    const [description, setDescription] = useState()
    const [uom, setUom] = useState()
    const [getUOM, setGetUOM] = useState([])

    const getData = async () => {
        const URL = ChemicalMasterGet;
        try {
            setLoader(true)
            const response = await axios.get(URL) 
            setData(response.data.response)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    }

 const getUomData = async () =>{
    const url = UomGetMaster;
    const  headers = await GetApiHeaders()
    try {
        const response = await axios.get(url, {headers})
        setGetUOM(response.data.response)
        console.log(response,'uom response');
        
    } catch (error) {
        
    }
 }

    useEffect(() => {
        getData() 
        getUomData()
    }, [])

    const headersData = [
        {
            field: "name",
            header: "Chemical Name",
        },
        {
            field: "uom",
            header: "UOM",
        },
        {
            field: "description",
            header: "Description",
        },
      
        {
            field: "active",
            header: "Status",
        },
    ];

    const onEditClicked = (data) => { 
        console.log(data);
        
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.name)
        setUom(data.uom)
        setDescription(data.description) 
        setStatus(data.active)
        setUomError('')
        setError('')
        setStatusError('')
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('') 
        setStatus('')
        setDescription('')
        setUom('')
        setUomError('')
        setError('')
        setStatusError('')
    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) =>{
        const Input = e.target.value
        const alphabeticInput = Input.replace(/[^a-zA-Z ]/g, ""); 
        setName(alphabeticInput)
        if (Input) {
            setError('')
        }
    }
    const handleUom = (e) =>{
        const Input = e.target.value
        setUom(Input)
        if (Input) {
            setUomError('')
        }
    }
 
    const handleStatus = (e) =>{
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }

    const handleDescription = (e) =>{
        const Input = e.target.value
        setDescription(Input)
        
    }

    const handleSubmitData = async () =>{ 
        const apiUrl = ChemicalMasterAdd;
        const reqData = { 
            id: saveEdit === "Submit" ? 0 : id,
            name: name,
            uom : uom, 
            description : description,
            status  :  status
         }
         const headers = await GetApiHeaders() 
         try {
            if (!status) {
                setStatusError("Select Status") 
            }
            if (!uom) {
                setUomError('Select UOM')
            }
            if (!name) {
                setError('Enter Chemical Name')   
            }
            if (name !=='' && uom !=='' && status !=='') {
                setLoader(true)
                const response = await axios.post(apiUrl,reqData, {headers} )
             if (response.data.statusCode == 200 || response.data.status == 500) {
                 setShowModal(false)
                 setMsgPopup(true)
                 setResponseMsg(response.data.message);
                 setLoader(false)
             }
            }
         } catch (error) {
            console.log(error )
         }
    }
    const handleOk = () =>{
        setMsgPopup(false)
        getData()
    }

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">My Master</h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text"> Chemical Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add Chemical Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader = {loader}
                                            FileName ={'Chemical'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'Chemical Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                            Chemical <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " maxLength={30} placeholder='Enter Chemical  Name'value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12'>
                                                             <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                UOM <span style={{ color: "red" }}>*</span>
                                                            </label> 
                                                            <select  id="dropdown"  type="dropdown"  className="form-control" value={uom}onChange={handleUom}>
                                                                <option value="" disabled>Select UOM</option> 
                                                                {
                                                                    getUOM.map((item,index)=>
                                                               
                                                                    item.status ? ( <option key={item.code} value={item.name}  >
                                                                        {item.name}
                                                                    </option> ) : null
                                                                    )
                                                                }
                                                            </select>
                                                             <span style={{ color: "red" }}> {uomError}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                    <div className='col-md-6 col-12 '>
                                                                <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                    Description  
                                                                </label>
                                                                <div className="input-group input-group w-100">
                                                                <textarea  class="form-control"  style={{ height: 115 }} id="textAreaExample1" placeholder='Enter Description'
                                                                rows="2"  value={description}  onChange={handleDescription} > 
                                                                </textarea>
                                                                </div> 
                                                            </div>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                            >Status <span style={{ color: "red" }}>*</span> </label>
                                                            <select  id="dropdown"  type="dropdown"  className="form-control p-2" value={status} onChange={handleStatus}>
                                                                <option value="" disabled>Select Status</option> 
                                                                <option value={true}>Active</option>
                                                                <option value={false}>In-Active</option>
                                                            </select>
                                                        <span style={{ color: "red" }}> {statusError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal 
                                         showModal={msgPopup}
                                        msgPopup ={msgPopup}
                                        handleOk= {handleOk}
                                        handleCancel_new={handleCancel_new}
                                        tittle={'Chemical Master'}
                                        childrens={<div className="p-4 d-flex justify-content-center" style={{fontSize:"18px", color:"green", fontWeight:500}}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}