import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logout from "../Pages/Logout";
import { ApproveRejectPermit, getAllPermits } from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import axios from "axios";
import moment from "moment";
import { USER_PROFILE } from "../Utils";
import { Button, Modal } from "react-bootstrap";
import Loader_Bulk from "../Utilities/Loader_Bulk";

const WorkPermit = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [data, setData] = useState([]);
  const [additionalPermit, setAdditionalPermit] = useState("");
  const [getLogin, setGetLogin] = useState([]);
  const [remark, setRemark] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showMsg, setShowMsg] = useState("false");
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fullData, setFullData] = useState([]);
  const [totalItems, setTotalItems] = useState();

  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
    $(tableRef.current).DataTable({
      ordering: false,
    });
    fetchdata();
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetLogin(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

  const handleModalOpen = (item, index) => {
    setShowModal(true);
    setSelectedItem(item);
    setSelectedItemIndex(index);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setShowPopup(false);
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleRemark = (e) => {
    setRemark(e.target.value);

  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchdata = async () => {
    const apiUrl = getAllPermits;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl, { headers });
      console.log(response);
      setData(response.data.response.core_permit);
      setFullData(response.data.response.core_permit);
      console.log("ttt", additionalPermit);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAccept = async (additionalPermitID, corePermitID, permitName) => {
    const { dateInputOne } = props; // Access dateInputOne from props
    if (!dateInputOne) {
      // Show an alert message if dateInputOne is empty
      alert(
        "Please fill The CorePermit Intials Details in Mobile Before Approve."
      );
    } else {
      const headers = await GetApiHeaders();
      const urlApi = ApproveRejectPermit;
      const ApproveData = {
        permitName: permitName,
        corePermitID: corePermitID.toString(),
        additionalPermitID: additionalPermitID,
        status: "Approve",
        remarks: "",
        acceptorUserId: getLogin.userId,
        deviceType: "web",
      };
      try {
        setIsLoading(true);
        const response = await axios.post(urlApi, ApproveData, { headers });
        console.log("responce", response);

        if (response.data.statusCode == "200") {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
        }
        if (response.data.statusCode == "500") {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
        }
        setIsLoading(false);
      } catch (err) {
        console(err);
      }
    }
  };

  const handleReject = async (
    // selectedItem,selectedItemIndex
    additionalPermitID,
    corePermitId,
    permitName
  ) => {
    console.log(selectedItem);
    const headers = await GetApiHeaders();
    const urlApi = ApproveRejectPermit;
    const ApproveData = {
      permitName: permitName,
      corePermitID: corePermitId.toString(),
      additionalPermitID: additionalPermitID,
      status: "Reject",
      remarks: remark,
      acceptorUserId: getLogin.userId,
      deviceType: "web",
    };

    try {
      setIsLoading(true);
      const response = await axios.post(urlApi, ApproveData, { headers });
      console.log("responce", response);
      setIsLoading(false);
      if (response.data.statusCode == "200") {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);
      }
      if (response.data.statusCode == "200") {
        setShowResponsePopup(true);
        setsubmitResponse(response.data.message);
      }
    } catch (err) {
      console(err);
    }
  };

  const handleView = (corePermitID, corePermitName, additionalPermitID) => {
    //alert(corePermitID +" : "+additionalPermitID);
    // alert(corePermitName)
    if (corePermitName == "Core permit") {
      // window.location.href = '/CorePermit_Web?corePermitID=' + corePermitID ;
      // alert(corePermitID)
      const url = "/CorePermit_Web?corePermitID=" + corePermitID;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    } else if (corePermitName == "general_hot_Work_permit") {
      //  window.location.href = '/HotWorkPermit2_Web?corePermitID=' + corePermitID ;

      const url =
        "/HotWorkPermit2_Web?corePermitID=" +
        corePermitID +
        "/" +
        additionalPermitID;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    } else if (corePermitName == "critical_elevated_permit") {
      // window.location.href = '/CriticalElevatedWork_Web?corePermitID=' + corePermitID ;

      const url =
        "/CriticalElevatedWork_Web?corePermitID=" +
        corePermitID +
        "/" +
        additionalPermitID;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    } else if (corePermitName == "excavation_permit") {
      //  window.location.href = '/ExcavationPermit_Web?corePermitID=' + corePermitID ;

      const url =
        "/ExcavationPermit_Web?corePermitID=" +
        corePermitID +
        "/" +
        additionalPermitID;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    } else if (corePermitName == "crane_permit") {
      // window.location.href = '/CraneOperationPermit_Web?corePermitID=' + corePermitID ;

      const url =
        "/CraneOperationPermit_Web?corePermitID=" +
        corePermitID +
        "/" +
        additionalPermitID;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    }
  };

  const handleFilter = (searchTerm) => {
    debugger;

    if (data.length > 0) {
      const filteredResults = data.filter(
        (item) =>
          item.primaryRequesterName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.requestId
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      searchTerm === "" ? setFullData(data) : setFullData(filteredResults);
    }
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    console.log("fullData::::", JSON.stringify(fullData));
  }, []);

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div
                    className="card-body pt-0 pl-1"
                    style={{ background: "#f8f8f8" }}
                  >
                    <h5 className="Roles_text p-2">Work Permits</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline">
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: 5 }}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> Entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              {/* Search Input */}
                              <input
                                type="text"
                                placeholder="🔍 Type to Search..."
                                value={searchTerm}
                                onChange={(e) => handleFilter(e.target.value)}
                                style={{ height: "36px", width: "160px" }}
                                className="mr-2"
                              />{" "}
                              &nbsp;

                            </div>
                          </div>
                        </div>
                        <div>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2 ">
                                  Work Permits
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {fullData.map((item, index) => {
                                var newDateFormat = moment(
                                  item.submittedDate
                                ).format("DD-MMM-YYYY hh:mm:ss A");
                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    {item.permitStatus === "Submitted" ||
                                      item.permitStatus === "Approve" ? (
                                      <Td>
                                        <Accordion
                                          defaultActiveKey="0"
                                          className="acc m-2"
                                        >
                                          <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                              <div
                                                className="d-flex ProductName"
                                                style={{
                                                  fontSize: 14,
                                                  width: "100%",
                                                }}
                                              >
                                                <div style={{ width: "26%" }}>
                                                  <b>Vendor Name : </b>
                                                  <span>
                                                    {item.primaryRequesterName}{" "}
                                                  </span>
                                                </div>
                                                <div style={{ width: "20%" }}>
                                                  <b>Work Permit ID : </b>
                                                  <span>{item.requestId} </span>
                                                </div>
                                                <div style={{ width: "36%" }}>
                                                  <b>Submitted Date : </b>
                                                  <span>{newDateFormat} </span>
                                                </div>
                                                <div style={{ width: "18%" }}>
                                                  <b>Status : </b>
                                                  <span>
                                                    {item.permitStatus}{" "}
                                                  </span>
                                                </div>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body
                                              style={{ background: "local" }}
                                            >
                                              <div style={{ fontSize: 14 }}>
                                                <div
                                                  style={{
                                                    color: "blue",
                                                    fontWeight: 400,
                                                    fontSize: 20,
                                                  }}
                                                >
                                                  {item.permitName}
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <div
                                                    className="d-flex ProductName "
                                                    style={{
                                                      font: 14,
                                                      gap: 186,
                                                    }}
                                                  >

                                                    <div
                                                      style={{ width: "49%" }}
                                                    >
                                                      <b>Submitted Date : </b>
                                                      <span>
                                                        {newDateFormat}{" "}
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{ width: "10%" }}
                                                    >
                                                      <b>Status : </b>
                                                      <span>
                                                        {item.permitStatus}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    {item.permitStatus ===
                                                      "Submitted" ? (
                                                      <div className=" d-flex justify-content-end ">
                                                        <div>
                                                          <button
                                                            className="workpermitApprove"
                                                            onClick={() =>
                                                              handleAccept(
                                                                "0",
                                                                item.corePermitId,
                                                                item.permitName
                                                              )
                                                            }
                                                          >
                                                            Accept
                                                          </button>
                                                          <span>
                                                            <button
                                                              className="workpermitReject  ml-2"
                                                              onClick={() =>
                                                                handleModalOpen(
                                                                  item,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              Reject{" "}
                                                            </button>
                                                          </span>
                                                          <span>
                                                            <button
                                                              className="workpermitView  ml-2"
                                                              onClick={() =>
                                                                handleView(
                                                                  item.corePermitId,
                                                                  item.permitName,
                                                                  item.additionalPermitID
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              View{" "}
                                                            </button>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className=" d-flex justify-content-end">
                                                        <span>
                                                          <button
                                                            className="workpermitView  ml-2"
                                                            onClick={() =>
                                                              handleView(
                                                                item.corePermitId,
                                                                item.permitName,
                                                                item.additionalPermitID
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            View{" "}
                                                          </button>
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {item.additional_permits.map(
                                                (items, itemIndex) => {
                                                  var newDateFormat = moment(
                                                    item.submittedDate
                                                  ).format(
                                                    "DD-MMM-YYYY hh:mm:ss A"
                                                  );
                                                  return (
                                                    <div
                                                      className="mt-4"
                                                      style={{ fontSize: 14 }}
                                                    >
                                                      <div
                                                        style={{
                                                          color: "blue",
                                                          fontWeight: 400,
                                                          fontSize: 20,
                                                        }}
                                                      >
                                                        {items.permitName}
                                                      </div>
                                                      <div
                                                        className="d-flex justify-content-between align-items-center "
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <div
                                                          className="d-flex ProductNameForRandM mt-1"
                                                          style={{ gap: 199 }}
                                                        >

                                                          <div>
                                                            <b>
                                                              Submitted Date :{" "}
                                                            </b>
                                                            <span>
                                                              {newDateFormat}{" "}
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={
                                                              {
                                                                // width: "10%",
                                                              }
                                                            }
                                                          >
                                                            <b>Status : </b>
                                                            <span>
                                                              {
                                                                items.permitStatus
                                                              }{" "}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {items.permitStatus ===
                                                          "Submitted" ? (
                                                          <div className=" d-flex justify-content-end">
                                                            <span>
                                                              <button
                                                                className="workpermitApprove"
                                                                onClick={() =>
                                                                  handleAccept(
                                                                    items.additionalPermitID,
                                                                    items.corePermitId,
                                                                    items.permitName
                                                                  )
                                                                }
                                                              >
                                                                Accept
                                                              </button>
                                                              <span>
                                                                <button
                                                                  className="workpermitReject  ml-2"
                                                                  onClick={() =>
                                                                    handleModalOpen(
                                                                      items,
                                                                      itemIndex
                                                                    )
                                                                  }
                                                                >
                                                                  {" "}
                                                                  Reject{" "}
                                                                </button>
                                                              </span>
                                                              <span>
                                                                <button
                                                                  className="workpermitView  ml-2"
                                                                  onClick={() =>
                                                                    handleView(
                                                                      items.corePermitId,
                                                                      items.permitName,
                                                                      items.additionalPermitID
                                                                    )
                                                                  }
                                                                >
                                                                  {" "}
                                                                  View{" "}
                                                                </button>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <div className=" d-flex justify-content-end">
                                                            <span>
                                                              <button
                                                                className="AproveBtn  ml-2"
                                                                onClick={() =>
                                                                  handleView(
                                                                    items.corePermitId,
                                                                    items.permitName,
                                                                    items.additionalPermitID
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                View{" "}
                                                              </button>
                                                            </span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Td>
                                    ) : null}
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>

                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="md"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>

                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  Reject Remarks
                                </span>
                              </div>

                              <div className="col-md-6 ">
                                <div class="mb-3">
                                  <label
                                    for="InputText"
                                    class="form-label text-sm font-weight-600 "
                                  >
                                    Remarks
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <textarea
                                    class="form-control"
                                    id="textAreaExample1"
                                    rows="2"
                                    value={remark}
                                    onChange={handleRemark}
                                  ></textarea>
                                </div>
                              </div>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() =>
                                    handleReject(
                                      "0",
                                      selectedItem.corePermitId,
                                      selectedItem.permitName
                                    )
                                  }
                                >
                                  Submit
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Request</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading && (
                  <Loader_Bulk
                    loading={isLoading}
                    message={"Fetching Data. Please Wait..!"}
                    loderimage={loaderImage}
                  />
                )}
              </div>
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default WorkPermit;
