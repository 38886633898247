import "@fortawesome/fontawesome-svg-core/styles.css";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import SideMenu from "../Pages/SideMenu";
import Loader from "../Utilities/Loader";
import "@fortawesome/fontawesome-free/css/all.min.css";

import {
  getEmployeeMasterDetails,
  rmGetmasters,
  FarmOperation_getMasters,
  saveEmployeeMaster,
  getLegalEntities,
  getEmployeeMasterDetailsById,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import axios from "axios";
import * as XLSX from "xlsx";
// import { useToast } from "react-toastify";
import moment from "moment";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

//UnitMeasureMaster
const EmployeeList = ({ }) => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [showDetails, setShowDetails] = useState({});
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState();
  const [buttonText, setButtonText] = useState("Submit");
  const [id, setId] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [errLocation, setErrLocation] = useState("");
  const [siteDropDown, setSiteDropDown] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [errSite, setErrSite] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [errMobileNumber, setErrMobileNumber] = useState("");
  const [subLocationDropDown, setSubLocationDropDorn] = useState([]);
  const [selectedSubLocation, setSelectedSubLocation] = useState();
  const [errSubLocation, setErrSubLocation] = useState("");
  const [selectedGender, setSelectedGender] = useState();
  const [errGender, setErrGender] = useState("");
  const [firstName, setFirstName] = useState();
  const [errFirstName, setErrFirstName] = useState("");
  const [lastName, setLastName] = useState();
  const [errLastName, setErrLastName] = useState("");
  const [fteOrLtc, setFteOrLtc] = useState();
  const [errFteOrLtc, setErrFteOrLtc] = useState("");
  const [stateDropdown, setStateDropdown] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [errState, setErrState] = useState("");
  const [mainFunctionDropdown, setMainFunctionDropdown] = useState([]);
  const [selectedMainFunction, setSelectedMainFunction] = useState();
  const [errMainFunction, setErrMainFunction] = useState("");
  const [subFunctionDropdown, setSubFunctionDropdown] = useState([]);
  const [selectedSubFunction, setSelectedSubFunction] = useState();
  const [errSubFunction, setErrSubFunction] = useState("");
  const [selectedLegalEntity, setSelectedLegalEntity] = useState();
  const [errLegalEntity, setErrLegalEntity] = useState("");
  const [email, setEmail] = useState();
  const [errEmail, setErrEmail] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState();
  const [errDateOfJoining, setErrDateOfJoining] = useState("");
  const [status, setStatus] = useState();
  const [errStatus, setErrStatus] = useState("");
  const [functionalLeadDropdown, setFunctionalLeadDropdown] = useState([]);
  const [selectedFunctionalLead, setSelectedFunctionalLead] = useState();
  const [errFunctionalLead, setErrFunctionalLead] = useState("");
  const [managerDropdown, setManagerDropdown] = useState([]);
  const [selectedManager, setSelectedManager] = useState();
  const [errManager, setErrManager] = useState("");
  const [teamDropdown, setTeamDropdown] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [errTeam, setErrTeam] = useState("");
  const [costCenterDropdown, setCostCenterDropdown] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState();
  const [errCostCenter, setErrCostCenter] = useState("");
  const [remarks, setRemarks] = useState();
  const [releasingDate, setReleasingDate] = useState();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [sublocationError, setSublocationError] = useState("");
  const [subLocationArray, setSubLocationArray] = useState([]);
  const [subFucntionArray, setSubFunctionArray] = useState([]);
  const [mainFunctionName, setMainFunctionName] = useState();
  const [subFunctionName, setSubFunctionName] = useState();
  const [locationName, setLocationName] = useState();
  const [subLocationName, setSubLocationName] = useState();
  const [legalEntityDropdown, setLegalEntityDropdown] = useState([]);

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filteredResults = data.filter((item) => {
        const itemGender = item.gender.toLowerCase();
        return (
          item.legalEntity.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.state.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.location.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.mainSite.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.employeeName.toLowerCase().includes(lowerCaseSearchTerm) ||
          itemGender === lowerCaseSearchTerm ||
          item.mobileNumber.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
          item.subLocation.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });
      setFilteredData(filteredResults);
      handlePageClick(1);
      console.log("fdsafaGFH", filteredData);
    }
  };


  useEffect(() => {
    if (data != null && data != undefined) {
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);
  const exportToExcel = (item) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);

      if (isNaN(date)) {
        return ""; // Return empty string for invalid dates
      }

      const day = ("0" + date.getDate()).slice(-2);
      const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
        date
      );
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };

    const data = [
      [
        "S.no",
        "EmployeeName",
        "FunctionalLead",
        "ReportingManagerName",
        "Costcenter",
        "EmailId",
        "Gender",
        "Location",
        "SubLocation",
        "MainFunction",
        "SubFunction",
        "MainSite",
        "MobileNumber",
        "RemarksOrActual",
        "State",
        "Team",
        "FteOrLtc",
        "LegalEntity",
        "DateOfJoining",
        "ReleasingDate",
        "Status",
      ], // Custom headers
      // ...currentItems.map((item) => [

      ...filteredData.map((item, index) => [

        index + 1,
        item.employeeName,
        item.functionalLead,
        item.reportingManagerName,
        item.currentCostCenterAsPerWorkDay,
        item.emailId,
        item.location,
        item.subLocation,
        item.gender,
        item.mainFunction,
        item.subFunction,
        item.mainSite,
        item.mobileNumber,
        item.remarksOrActual,
        item.state,
        item.team,
        item.fteOrLtc,
        item.legalEntity,
        formatDate(item.dateOfJoining),
        formatDate(item.releasingDate),
        item.status,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "EmployeeList.xlsx");
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  console.log(currentItems);
  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const handleCancel_new = () => {
    setShowModal(true);
    setShowPopup(true);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowMsg(true);
    // window.location.reload();
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }
    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, []);

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const toggleDetails = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };
  const handleClear = () => {
    setSearchTerm("");
  };
  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobileNumber("");
    setSelectedState("");
    setSelectedFunctionalLead("");
    setSelectedCostCenter("");
    setSelectedGender("");
    setSelectedLegalEntity("");
    setSelectedLocation("");
    setSelectedSubFunction("");
    setSelectedSite("");
    setSelectedSubFunction("");
    setSelectedMainFunction("");
    setSelectedState("");
    setSelectedTeam("");
    setRemarks("");
  };
  const handleSelectedLocation = (e) => {

    const subLocationsArray = [];
    setSelectedLocation(e.target.value);
    const selectLocationName =
      e.target.options[e.target.selectedIndex].getAttribute(
        "data-locationName"
      );
    setLocationName(selectLocationName);
    if (e.target.value) {
      subLocationDropDown.find((item) => {
        if (item.storeLocationId === parseInt(e.target.value)) {
          subLocationsArray.push(item);
        }
      });
      setSubLocationArray(subLocationsArray);
      setErrLocation("");
      console.log("subLocationsArray" + JSON.stringify(subLocationArray));
    } else {
      setErrLocation("Select Location");
      setSubLocationArray([]);
    }
    if (e.target.value) {
      setErrLocation("");
    }
  };
  const handleSubLocation = (e) => {
    setSelectedSubLocation(e.target.value);
    const selectSubLocation = e.target.options[
      e.target.selectedIndex
    ].getAttribute("data-subLocationName");
    setSubLocationName(selectSubLocation);
    if (e.target.value) {
      setErrSubLocation("");
    }
  };
  const handleSelectedSite = (e) => {
    setSelectedSite(e.target.value);
    if (e.target.value) {
      setErrSite("");
    }
  };
  const handleMobileNumber = (e) => {
    const Input = e.target.value;
    if (Input.length > 0) {
      setErrMobileNumber("");
    }
    let result = Input.replace(/[^0-9]/g, "");
    if (result.length > 10) {
      result = result.substr(0, 10);
    }
    if (Input.length < 10) {
      setErrMobileNumber("Please Enter 10 Digit Mobile Number");
    }
    if (/^[6-9]/.test(result)) {
      setMobileNumber(result);
    } else {
      setMobileNumber("");
    }
  };
  const handleGender = (e) => {
    setSelectedGender(e.target.value);
    if (e.target.value) {
      setErrGender("");
    }
  };
  const handleLegalEntity = (e) => {
    setSelectedLegalEntity(e.target.value);
    if (e.target.value) {
      setErrLegalEntity("");
    }
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value) {
      setErrStatus("");
    }
  };
  const getMasterFromFO = async () => {
    const getData = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    try {
      const responseGetData = await axios.get(getData, { headers });
      setMainFunctionDropdown(responseGetData.data.response.mainFunctionMaster);
      console.log("getmasterFO Data----------->,", mainFunctionDropdown);
      setSubFunctionDropdown(responseGetData.data.response.subFunctionMaster);
    } catch (err) {
      console.log("getError", err);
    }
  };
  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      const responseGetData = await axios.get(getData, { headers });
      console.log("getmaster Data,", responseGetData);
      setLocationDropdown(responseGetData.data.response.location);
      setSiteDropDown(responseGetData.data.response.site);
      setSubLocationDropDorn(responseGetData.data.response.subLocation);
      setStateDropdown(responseGetData.data.response.stateList);
      setManagerDropdown(responseGetData.data.response.reportingManagerList);
      setFunctionalLeadDropdown(
        responseGetData.data.response.functionalLeadsList
      );
      setTeamDropdown(responseGetData.data.response.TeamsList);
      setCostCenterDropdown(responseGetData.data.response.costCenterPerDay);
    } catch (err) {
      console.log("getError", err);
    }
  };
  const handleTeam = (e) => {
    setSelectedTeam(e.target.value);
    if (e.target.value) {
      setErrTeam("");
    }
  };
  const handleManager = (e) => {
    setSelectedManager(e.target.value);
    if (e.target.value) {
      setErrManager("");
    }
  };
  const handleFirstName = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setFirstName(result);
    if (result) {
      setErrFirstName("");
    }
  };
  const handleLastName = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setLastName(result);
    if (result) {
      setErrLastName("");
    }
  };
  const handleFteLtc = (e) => {
    setFteOrLtc(e.target.value);
    if (e.target.value) {
      setErrFteOrLtc("");
    }
  };
  const fetchData = async () => {
    const fetchApi = getEmployeeMasterDetails;
    const headers = await GetApiHeaders();

    try {
      setIsLoading(true);
      const response = await axios.get(fetchApi, { headers });
      console.log("menpower Get Data", response);
      setData(response.data.response.employeeList);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleState = (e) => {
    setSelectedState(e.target.value);
    if (e.target.value) {
      setErrState("");
    }
  };
  const handleSubFunction = (e) => {
    setSelectedSubFunction(e.target.value);
    const selectedSubFunction = e.target.options[
      e.target.selectedIndex
    ].getAttribute("data-subfunctionName");
    setSubFunctionName(selectedSubFunction);
    if (e.target.value) {
      setErrSubFunction("");
    }
  };
  const handleMainFunction = (e) => {
    const subFunction = [];
    setSelectedMainFunction(e.target.value);
    const selectedFunctionName =
      e.target.options[e.target.selectedIndex].getAttribute(
        "data-functionName"
      );
    setMainFunctionName(selectedFunctionName);
    if (e.target.value) {
      subFunctionDropdown.find((item) => {
        if (item.parentCode === parseInt(e.target.value)) {
          subFunction.push(item);
        }
      });
      setSubFunctionArray(subFunction);
      setErrMainFunction("");
    } else {
      setErrMainFunction("Please select main function");
      setSubFunctionArray([]);
    }
    if (e.target.value) {
      setErrMainFunction("");
    }
  };
  const handleEmail = (e) => {
    // setEmail(e.target.value)
    // if (e.target.value) {
    //   setErrEmail("")
    // }
    const inputEmail = e.target.value;
    if (inputEmail.length > 0) {
      setErrEmail("");
    }
    setEmail(inputEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmail)) {
      setErrEmail("Invalid email address");
    }
  };
  const handleDateOfJoining = (e) => {
    const selectedDate = e.target.value;

    if (!selectedDate) {
      setErrDateOfJoining("Please select  date");
    } else {
      setErrDateOfJoining("");
      setDateOfJoining(selectedDate);
    }
  };

  const handleFunctionalLead = (e) => {
    setSelectedFunctionalLead(e.target.value);
    if (e.target.value) {
      setErrFunctionalLead("");
    }
  };
  const handleCostCenter = (e) => {
    setSelectedCostCenter(e.target.value);
    if (e.target.value) {
      setErrCostCenter("");
    }
  };
  const handleRemarks = (e) => {
    setRemarks(e.target.value);
  };
  const handlereleasingDate = (e) => {
    setReleasingDate(e.target.value);
  };
  const fetchLegalEntity = async () => {
    const getApi = getLegalEntities;
    const headers = await GetApiHeaders();
    try {
      const respons = await axios.get(getApi, { headers });
      setLegalEntityDropdown(respons.data.response);
      console.log("response------->", respons);
      console.log("response----d--->", legalEntityDropdown);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    getMasterData();
    getMasterFromFO();
    fetchLegalEntity();
  }, []);

  const handleSubmit = async () => {
    var selectlocationName = ''
    var selectSublocationName = ''
    var selectMainFunctionName = ''
    var selectSubFunctionName = ''
    locationDropdown.map((v, i) => {
      if (v.code === parseInt(selectedLocation)) {
        selectlocationName = v.name;
      }
    });
    subLocationArray.map((v, i) => {
      if (v.code === parseInt(selectedSubLocation)) {
        selectSublocationName = v.name;
      }
    });
    mainFunctionDropdown.map((v, i) => {
      if (v.code === parseInt(selectedMainFunction)) {
        selectMainFunctionName = v.name;
      }
    });
    subFucntionArray.map((v, i) => {
      if (v.code === parseInt(selectedSubFunction)) {
        selectSubFunctionName = v.name;
      }
    });
    if (firstName === "") {
      setErrFirstName("Please Enter First Name");
    }
    if (lastName === "") {
      setErrLastName("Please Enter Last Name");
    }
    if (mobileNumber === "") {
      setErrMobileNumber("Please Enter Mobile Number");
    }
    if (selectedSite === "") {
      setErrSite("Please Select Main Site");
    }
    if (selectedLocation === "") {
      setErrLocation("Please Select Location");
    }
    if (!selectedSubLocation) {
      setErrSubLocation("Please Select Sub Location");
    }

    if (selectedState === "") {
      setErrState("Please Select State");
    }
    if (selectedLegalEntity === "") {
      setErrLegalEntity("Please Select Legal Entity");
    }
    if (selectedGender === "") {
      setErrGender("Please Select Gender");
    }
    if (email === "") {
      setErrEmail("Please Enter Email");
    }
    if (selectedTeam === "") {
      setErrTeam("Please Select Team");
    }
    if (!dateOfJoining) {
      setErrDateOfJoining("Please Select Date");
    }
    if (selectedMainFunction === "") {
      setErrMainFunction("Please Select Main Function");
    }
    if (selectedSubFunction === "") {
      setErrSubFunction("Please Select SubFunction");
    }
    if (!selectedManager) {
      setErrManager("Please Select Manager");
    }
    if (selectedFunctionalLead === "") {
      setErrFunctionalLead("Please Select Functional Lead");
    }
    if (selectedCostCenter === "") {
      setErrCostCenter("Please Select Cost Center");
    }
    if (!fteOrLtc) {
      setErrFteOrLtc("Please Select FTE/LTC");
    }
    if (!status) {
      setErrStatus("Please Select Status");
    }

    const apiUrl = saveEmployeeMaster;
    const headers = await GetApiHeaders();

    const passData = {
      serverRecordId: buttonText === "Submit" ? 0 : id.toString(),
      firstName: firstName,
      lastName: lastName,
      legalEntity: selectedLegalEntity,
      state: selectedState,
      mobileNumber: mobileNumber,
      fteOrLtc: fteOrLtc,
      currentCostCenterAsPerWorkDay: selectedCostCenter,
      functionalLead: selectedFunctionalLead,
      reportingManagerName: selectedManager,
      subFunction: buttonText === "Submit" ? subFunctionName : selectSubFunctionName,
      subFunctionId: selectedSubFunction,
      mainFunctionId: selectedMainFunction,
      mainFunction: buttonText === "Submit" ? mainFunctionName : selectMainFunctionName,
      gender: selectedGender,
      mainSite: selectedSite,
      subLocation: buttonText === "Submit" ? subLocationName : selectSublocationName,
      subLocationId: selectedSubLocation,
      team: selectedTeam,
      dateOfJoining: dateOfJoining,
      emailId: email,
      location: buttonText === "Submit" ? locationName : selectlocationName,
      locationId: selectedLocation,
      status: status,
      remarksOrActual: remarks,
      releasingDate: releasingDate,
    };
    try {
      if (
        firstName !== "" &&
        lastName !== "" &&
        mobileNumber !== "" &&
        selectedSite !== "" &&
        selectedLocation !== "" &&
        selectedSubLocation !== "" &&
        selectedState !== "" &&
        selectedLegalEntity !== "" &&
        selectedGender !== "" &&
        email !== "" &&
        selectedTeam !== "" &&
        dateOfJoining !== "" &&
        selectedMainFunction !== "" &&
        selectedSubFunction !== "" &&
        selectedManager !== "" &&
        selectedFunctionalLead !== "" &&
        selectedCostCenter !== "" &&
        fteOrLtc !== "" &&
        status !== ""
      ) {
        const response = await axios.post(apiUrl, passData, { headers });
        console.log(response);
        setsubmitResponse(response.data.message);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);
        setErrFirstName("");
        setIsSubmitted(true);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitted(false);
    }
  };
  const handleEditLocation = (id) => {
    const subLocationsArray = [];
    // console.log("cheking sub", subLocationDropDown);
    setSelectedLocation(id);
    if (id) {
      subLocationDropDown.find((item) => {
        if (item.storeLocationId === parseInt(id)) {
          subLocationsArray.push(item);
        }
      });
      setSubLocationArray(subLocationsArray);
      setErrLocation("");
      console.log("subLocationsArray" + JSON.stringify(subLocationsArray));
    } else {
      setErrLocation("Select Location");
      setSubLocationArray([]);
    }
    if (id) {
      setErrLocation("");
    }
  };
  const handleEditMainFunction = (id) => {
    const subFunction = [];
    setSelectedMainFunction(id);

    if (id) {
      subFunctionDropdown.find((item) => {
        if (item.parentCode === parseInt(id)) {
          subFunction.push(item);
        }
      });
      setSubFunctionArray(subFunction);
      setErrMainFunction("");
      console.log("function", subFunctionDropdown);
    } else {
      setErrMainFunction("Please select main function");
      setSubFunctionArray([]);
    }
    if (id) {
      setErrMainFunction("");
    }
  };
  const updatedItems = async (item) => {

    const id = item.serverRecordId;
    setId(id);
    const apiEdit = getEmployeeMasterDetailsById;
    const headers = await GetApiHeaders();

    try {
      const response = await axios.get(`${apiEdit}?id=${id}`, {
        headers,
      });
      const onEdit = response.data.response.employeeList[0];
      console.log("onEdit", onEdit);
      locationDropdown.map((v, i) => {

        if (v.code === parseInt(onEdit.locationId)) {
          setSelectedLocation(v.code);
          handleEditLocation(v.code);
          setSelectedSubLocation(onEdit.subLocationId);
          handleEditMainFunction(onEdit.mainFunctionId);
          // setSelectedMainFunction(onEdit.mainFunctionId);
          setSelectedSubFunction(onEdit.subFunctionId);
        }
      });

      siteDropDown.map((v, i) => {
        if (v.name === onEdit.mainSite) {
          setSelectedSite(onEdit.mainSite);
          setSelectedGender(onEdit.gender);
          setFirstName(onEdit.firstName);
          setLastName(onEdit.lastName);
          setMobileNumber(onEdit.mobileNumber);
          setSelectedState(onEdit.state);
          setSelectedLegalEntity(onEdit.legalEntity);
          setEmail(onEdit.emailId);
          setSelectedTeam(onEdit.team);
          setDateOfJoining(onEdit.dateOfJoining);
          setSelectedManager(onEdit.reportingManagerName);
          setSelectedFunctionalLead(onEdit.functionalLead);
          setSelectedCostCenter(onEdit.currentCostCenterAsPerWorkDay);
          setFteOrLtc(onEdit.fteOrLtc);
          setStatus(onEdit.status);
          setRemarks(onEdit.remarksOrActual);
          setReleasingDate(onEdit.releasingDate);
        }
      });

      setShowModal(true);
      setButtonText("Update");
      setIsSubmitted(false);
      setIsUpdated(true);
      setIsUpdateMode(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }
      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Man Power</h5>
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="mb-0 Sub_role_text">Employee List</h6>
                  </div>
                  <span
                    class="d-inline-block"
                    tabindex="0"
                    data-toggle="tooltip"
                    title="Add Employee"
                  >
                    <Button
                      variant="primary"
                      className="buttons mr-1"
                      onClick={handleModalOpen}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="plus_icon mr-1"
                      />
                      Add
                    </Button>
                  </span>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between"></div>

                    <div className="mcrc_hrline ">
                      <div
                        className="card flex-grow-1 "
                        style={{
                          border: "1px solid #0000001A",
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div className="card-body " style={{ padding: "10px" }}>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{}}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                              // value={itemsPerPage} onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div style={{ position: "relative" }}>
                                <input
                                  type="text"
                                  placeholder="🔍 Type to Search..."
                                  value={searchTerm}
                                  onChange={(e) => handleFilter(e.target.value)}
                                  style={{
                                    height: "36px",
                                    width: "160px",
                                    paddingRight: "30px",
                                  }}
                                  className="mr-2"
                                />
                                {searchTerm && (
                                  <button
                                    className="btn btn-link"
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                      zIndex: 1,
                                    }}
                                    onClick={() => handleFilter("")}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                )}
                              </div>
                              &nbsp;
                              {/* Add the Excel export button */}
                              <button
                                className="btn btn-secondary buttons-excel buttons-html5"
                                onClick={exportToExcel}
                              >
                                Download Excel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="card data_tableCard mr-2 ml-1">
                            <div className="card-body ">
                              <Table className="table table-bordered  table-striped Content_data_table  ">
                                {/* <Thead>
                                  <Tr>
                                    <Th className="manpowerTable text-center">
                                      S.No
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      Mobile No
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      Main Site
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      Location
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      Sub Location
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      State
                                    </Th>
                                    <Th className=" manpowerTable text-center">
                                      Legal Entity
                                    </Th>
                                    <Th className=" manpowerTable text-center">
                                      Employee Name
                                    </Th>
                                    <Th className=" manpowerTable text-center">
                                      Gender
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      Action
                                    </Th>
                                  </Tr>
                                </Thead> */}

                                <Thead>
                                  <Tr>
                                    <Th className="manpowerTable text-center">
                                      S.No
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("mobileNumber")}
                                    >
                                      Mobile No
                                      <i
                                        className={`fa ${sortConfig.key === "mobileNumber"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("mainSite")}
                                    >
                                      Main Site
                                      <i
                                        className={`fa ${sortConfig.key === "mainSite"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("location")}
                                    >
                                      Location
                                      <i
                                        className={`fa ${sortConfig.key === "location"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("subLocation")}
                                    >
                                      Sub Location
                                      <i
                                        className={`fa ${sortConfig.key === "subLocation"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("state")}
                                    >
                                      State
                                      <i
                                        className={`fa ${sortConfig.key === "state"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("legalEntity")}
                                    >
                                      Legal Entity
                                      <i
                                        className={`fa ${sortConfig.key === "legalEntity"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("employeeName")}
                                    >
                                      Employee Name
                                      <i
                                        className={`fa ${sortConfig.key === "employeeName"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th
                                      className="manpowerTable text-center"
                                      onClick={() => handleSort("gender")}
                                    >
                                      Gender
                                      <i
                                        className={`fa ${sortConfig.key === "gender"
                                          ? sortConfig.direction === "asc"
                                            ? "fa-sort-up"
                                            : "fa-sort-down"
                                          : "fa-sort"
                                          } fa-xs icon-opacity ml-2`}
                                      ></i>
                                    </Th>
                                    <Th className="manpowerTable text-center">
                                      Action
                                    </Th>
                                  </Tr>
                                </Thead>


                                {sortedItems.length === 0 ? (
                                  <Tr>
                                    <Td
                                      colSpan={9}
                                      className="text-center"
                                    >
                                      Data is not available
                                    </Td>
                                  </Tr>
                                ) : (
                                  sortedItems.map((item, index) => {
                                    var joiningDate = moment(
                                      item.dateOfJoining
                                    ).format("DD-MMM-YYYY  ");
                                    var releasingDate = moment(
                                      item.releasingDate
                                    ).format("DD-MMM-YYYY  ");
                                    return (
                                      <Tbody>
                                        <Tr key={index}>
                                          <Td className="text-left">
                                            {(currentPage - 1) * itemsPerPage + index + 1}
                                          </Td>
                                          <Td className="text-left">
                                            {item.mobileNumber}
                                          </Td>
                                          <Td className="text-left">
                                            {item.mainSite}
                                          </Td>
                                          <Td className="text-left">
                                            {item.location}
                                          </Td>
                                          <Td className="text-left">
                                            {item.subLocation}
                                          </Td>
                                          <Td className="text-left">
                                            {item.state}
                                          </Td>
                                          <Td className="text-left">
                                            {item.legalEntity}
                                          </Td>
                                          <Td className="text-left">
                                            {item.employeeName}
                                          </Td>
                                          <Td className="text-left">
                                            {item.gender}
                                          </Td>
                                          <Td>
                                            <div>
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                className="plus_icon mr-1 text-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Details"
                                                onClick={() =>
                                                  toggleDetails(index)
                                                }
                                              />
                                              <FontAwesomeIcon
                                                icon={faEdit}
                                                className="plus_icon mr-1 text-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Edit User Master"
                                                onClick={() =>
                                                  updatedItems(item)
                                                }
                                              />
                                            </div>
                                          </Td>
                                        </Tr>
                                        {selectedRowIndex === index && (
                                          <Tr>
                                            <Td
                                              style={{ padding: 24 }}
                                              colSpan="10"
                                            >
                                              <div className="request-card">
                                                <div
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductNameForRandM d-flex"
                                                >
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Email ID
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.emailId}
                                                    </span>
                                                  </span>

                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Team
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.team}
                                                    </span>
                                                  </span>
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Date Of Joining
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {joiningDate}
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductNameForRandM d-flex mt-3"
                                                >
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Main Function
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.mainFunction}
                                                    </span>
                                                  </span>

                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Sub Function
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.subFunction}
                                                    </span>
                                                  </span>
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      FTE/LTC
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.fteOrLtc}
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductNameForRandM d-flex mt-3"
                                                >
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Manager
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {
                                                        item.reportingManagerName
                                                      }
                                                    </span>
                                                  </span>

                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Functional Lead
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.functionalLead}
                                                    </span>
                                                  </span>
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Cost Center
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-flex",
                                                        whiteSpace:
                                                          "break-spaces",
                                                      }}
                                                    >
                                                      {
                                                        item.currentCostCenterAsPerWorkDay
                                                      }
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductNameForRandM d-flex mt-3"
                                                >
                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Status
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.status ===
                                                        "Active" ? (
                                                        <span
                                                          style={{
                                                            color: "#00CB0E",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          Active
                                                        </span>
                                                      ) : (
                                                        <span
                                                          style={{
                                                            color: "#D50101",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          In Active
                                                        </span>
                                                      )}
                                                    </span>
                                                  </span>
                                                  {item.releasingDate !== "" ? (
                                                    <span
                                                      style={{ width: "33.4%" }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: "40%",
                                                          display:
                                                            "inline-block",
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        Releasing Date
                                                      </span>
                                                      <span> : &nbsp;</span>
                                                      <span
                                                        style={{
                                                          width: "60%",
                                                          display:
                                                            "inline-block",
                                                        }}
                                                      >
                                                        {releasingDate}
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <span
                                                    style={{ width: "33.4%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        width: "40%",
                                                        display: "inline-block",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Remarks/Actual
                                                    </span>
                                                    <span> : &nbsp;</span>
                                                    <span
                                                      style={{
                                                        width: "60%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {item.remarksOrActual}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </Td>
                                          </Tr>
                                        )}
                                      </Tbody>
                                    );
                                  })
                                )}

                                {isLoading && (
                                  <Loader
                                    loading={isLoading}
                                    message={"Fetching Data. Please Wait..!"}
                                    loderimage={loaderImage}
                                  />
                                )}
                              </Table>
                              <div
                                className="d-flex justify-content-between mb-10"
                                style={{ borderRadius: 5, padding: 15 }}
                              >
                                <div>
                                  Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                                  to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}
                                  of {totalItems} entries
                                </div>
                                <div>


                                  <div className="div-button text-center">
                                    <button
                                      className="borderleft-radius-5"
                                      // className='ul-pagination'
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      Previous
                                    </button>

                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor:
                                          currentPage == 1
                                            ? "#0056b3"
                                            : "white",
                                        color:
                                          currentPage == 1 ? "white" : "black",
                                      }}
                                      key={1}
                                      onClick={() => handlePageClick(1)}
                                      disabled={currentPage === 1}
                                    >
                                      {1}
                                    </button>

                                    {pageNumbers.map((number) => (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor:
                                            currentPage == number
                                              ? "#0056b3"
                                              : "white",
                                          color:
                                            currentPage == number
                                              ? "white"
                                              : "black",
                                        }}
                                        key={number}
                                        onClick={() => handlePageClick(number)}
                                        disabled={currentPage === number}
                                      >
                                        {number}
                                      </button>
                                    ))}

                                    {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                      currentPage !==
                                      Math.ceil(totalItems / itemsPerPage) -
                                      1 &&
                                      currentPage !==
                                      Math.ceil(
                                        totalItems / itemsPerPage
                                      ) && (
                                        <button
                                          className="ul-pagination page-item"
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                        >
                                          ...
                                        </button>
                                      )}

                                    {Math.ceil(totalItems / itemsPerPage) >
                                      1 && (
                                        <button
                                          className="ul-pagination page-item"
                                          style={{
                                            backgroundColor:
                                              currentPage ==
                                                Math.ceil(totalItems / itemsPerPage)
                                                ? "#0056b3"
                                                : "white",
                                            color:
                                              currentPage ==
                                                Math.ceil(totalItems / itemsPerPage)
                                                ? "white"
                                                : "black",
                                          }}
                                          key={Math.ceil(
                                            totalItems / itemsPerPage
                                          )}
                                          onClick={() =>
                                            handlePageClick(
                                              Math.ceil(totalItems / itemsPerPage)
                                            )
                                          }
                                          disabled={
                                            currentPage ===
                                            Math.ceil(totalItems / itemsPerPage)
                                          }
                                        >
                                          {Math.ceil(totalItems / itemsPerPage)}
                                        </button>
                                      )}


                                    <button
                                      className="borderright-radius-5"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={indexOfLastItem >= totalItems}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Modal
                              show={showModal}
                              onHide={handleModalClose}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="xl"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title> </Modal.Title>
                                <Button
                                  variant="link"
                                  className="close btn btn-danger close_btn"
                                  onClick={handleCancel_new}
                                >
                                  X
                                </Button>
                              </Modal.Header>

                              <Modal.Body
                                className="pl-md-5 pr-md-5 pt-0"
                                style={{ overflowY: "auto", maxHeight: "85vh" }}
                              >
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  <span className="modal_title">
                                    Employee details
                                  </span>
                                </div>
                                <div className="row">
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="custom-input label-style form-label font-weight-600 mb-0 "
                                    >
                                      First name
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <input
                                        type="tel"
                                        className="form-control p-2 "
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={handleFirstName}
                                      />
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errFirstName}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Last Name
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <input
                                        type="tel"
                                        className="form-control p-2"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={handleLastName}
                                      />
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errLastName}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0"
                                    >
                                      Mobile Number
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <input
                                        type="tel"
                                        className="form-control p-2"
                                        placeholder="Mobile Number"
                                        value={mobileNumber}
                                        onChange={handleMobileNumber}
                                      />
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errMobileNumber}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Main Site
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedSite}
                                        onChange={handleSelectedSite}
                                      >
                                        <option value="" disabled selected>
                                          Select Main Site
                                        </option>
                                        {siteDropDown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errSite}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Location
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedLocation}
                                        onChange={handleSelectedLocation}
                                      >
                                        <option value="" disabled selected>
                                          Select Location
                                        </option>
                                        {locationDropdown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.code}
                                              data-locationName={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errLocation}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Sub Location
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedSubLocation}
                                        onChange={handleSubLocation}
                                      >
                                        <option value="" disabled selected>
                                          Select Sub Location
                                        </option>
                                        {subLocationArray.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.code}
                                              data-subLocationName={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errSubLocation}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      State
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        onChange={handleState}
                                        value={selectedState}
                                      >
                                        <option value="" disabled selected>
                                          Select State
                                        </option>
                                        {stateDropdown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errState}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Legal Entity
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        onChange={handleLegalEntity}
                                        value={selectedLegalEntity}
                                      >
                                        <option value="" disabled selected>
                                          Select Legal Entity
                                        </option>
                                        {legalEntityDropdown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.legalEntity}
                                            >
                                              {item.legalEntity}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errLegalEntity}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Gender
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedGender}
                                        onChange={handleGender}
                                      >
                                        <option value="" disabled selected>
                                          Select Gender
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errGender}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Email ID
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <input
                                        type="tel"
                                        className="form-control p-2"
                                        placeholder="Email ID"
                                        value={email}
                                        onChange={handleEmail}
                                      />
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errEmail}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Team
                                    </label>
                                    <div
                                      className="input-group input-group w-100 mt-2"
                                      onChange={handleTeam}
                                      value={selectedTeam}
                                    >
                                      <select className="form-select p-2">
                                        <option value="" disabled selected>
                                          {/* {selectedTeam ===""? "Select Team" : selectedTeam } */}
                                          {selectedTeam !== ""
                                            ? selectedTeam
                                            : " Select Team"}
                                        </option>
                                        {teamDropdown.map((item, i) => {
                                          return (
                                            <option value={item.name}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errTeam}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Date Of Joining
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <input
                                        type="date"
                                        className="form-control p-2"
                                        min="1000-01-01"
                                        max="9999-12-31"
                                        placeholder="Select Date"
                                        value={dateOfJoining}
                                        onChange={handleDateOfJoining}
                                      />
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errDateOfJoining}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Main Function
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedMainFunction}
                                        onChange={handleMainFunction}
                                      >
                                        <option value="" disabled selected>
                                          Select Main Function
                                        </option>
                                        {mainFunctionDropdown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.code}
                                              data-functionName={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errMainFunction}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Sub Function
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedSubFunction}
                                        onChange={handleSubFunction}
                                      >
                                        <option value="" disabled selected>
                                          Select Sub Function
                                        </option>
                                        {subFucntionArray.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.code}
                                              data-subfunctionName={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errSubFunction}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Reporting Manager Name
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        onChange={handleManager}
                                        value={selectedManager}
                                      >
                                        <option value="" disabled selected>
                                          Select Reporting Manager Name
                                        </option>
                                        {managerDropdown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errManager}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Functional Lead
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={selectedFunctionalLead}
                                        onChange={handleFunctionalLead}
                                      >
                                        <option value="" disabled selected>
                                          Select Functional Lead
                                        </option>
                                        {functionalLeadDropdown.map(
                                          (item, i) => {
                                            return (
                                              <option value={item.name}>
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errFunctionalLead}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Current Cost Center as per work day
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        onChange={handleCostCenter}
                                        value={selectedCostCenter}
                                      >
                                        <option value="" disabled selected>
                                          Select Cost Center
                                        </option>
                                        {costCenterDropdown.map((item, i) => {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errCostCenter}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      FTE/LTC
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        onChange={handleFteLtc}
                                        value={fteOrLtc}
                                      >
                                        <option value="" disabled selected>
                                          Select FTE /LTC
                                        </option>
                                        <option value="FTE">FTE</option>
                                        <option value="LTC">LTC</option>
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errFteOrLtc}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="label-style form-label font-weight-600 mb-0 "
                                    >
                                      Status
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <select
                                        className="form-select p-2"
                                        value={status}
                                        onChange={handleStatus}
                                      >
                                        <option value="" disabled selected>
                                          Select Status
                                        </option>
                                        <option value="Active">Active</option>
                                        <option value="InActive">
                                          In Active
                                        </option>
                                      </select>
                                    </div>
                                    <span style={{ color: "red" }}>
                                      {errStatus}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-12 mt-2">
                                    <label
                                      htmlFor="InputType"
                                      className="form-label font-weight-600 mb-0 "
                                    >
                                      Remarks
                                    </label>
                                    <div className="input-group input-group w-100 mt-2">
                                      <input
                                        type="text"
                                        className="form-control p-2"
                                        placeholder="Remarks"
                                        value={remarks}
                                        onChange={handleRemarks}
                                      />
                                    </div>
                                  </div>
                                  {isUpdateMode && (
                                    <div className="col-md-4 col-12 mt-2">
                                      <label
                                        htmlFor="InputType"
                                        className="form-label font-weight-600 mb-0 "
                                      >
                                        Releasing Date
                                      </label>
                                      <div className="input-group input-group w-100 mt-2">
                                        <input
                                          type="date"
                                          className="form-control p-2"
                                          placeholder="First Name"
                                          value={releasingDate}
                                          onChange={handlereleasingDate}
                                          min="1000-01-01"
                                          max="9999-12-31"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex justify-content-center col-md-12 mt-4">
                                  <Button
                                    variant="primary"
                                    className="btnn"
                                    onClick={() => handleSubmit()}
                                  >
                                    {buttonText}
                                  </Button>
                                  <div style={{ width: "20px" }}></div>
                                  <Button
                                    onClick={handleCancel_new}
                                    className="btnn btn btn-danger"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              className="modalBlur"
                              show={showPopup}
                              onHide={handleModalClose}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="ml"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title>
                                  {" "}
                                  <span className="modal_title">Employee</span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  {showMsg ? (
                                    <p style={{ color: "green" }}>
                                      Are You Sure, Do You want close this
                                      Window ?
                                    </p>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleClose}
                                  >
                                    Yes
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    variant="danger"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </Button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              show={showResponsePopup}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="ml"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title>
                                  {" "}
                                  <span className="modal_title">
                                    Employee List
                                  </span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  {showMsg ? (
                                    <p style={{ color: "green" }}>
                                      {submitResponse}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleresponse}
                                  >
                                    Okay
                                  </Button>
                                  &nbsp;&nbsp;
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div>
                      <p>Fetching data. Please wait...</p>
                      {/* <Loader type="Puff" color="#00BFFF" height={50} width={50} /> */}
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default EmployeeList;
