import React, { useState } from 'react'
import { getNewListWithModifyChecked } from "../../Utilities/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaClock } from 'react-icons/fa';
import { FaCalendar } from 'react-icons/fa';

const currentDate = new Date().toISOString().split('T')[0];
const CorePermitCloseOut = ({ closeOutData, setCloseOutData }) => {
  const handleCloseData = (key, val) => {
    setCloseOutData((prev) => ({ ...prev, [key]: val }));
  };

  const [time, setTime] = useState(new Date());
  const [SelectTime, SetSelectTime] = useState('');
  const handleTimeChange = (key) => (selectedTime) => {
    SetSelectTime(selectedTime);
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = new Date(selectedTime).toLocaleTimeString('en-US', options);   
    setCloseOutData((prev) => ({ ...prev, [key]: formattedTime }));
    setTime(selectedTime);
  };

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div>
      <input className='form-control w-full'
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={{ cursor: 'pointer', padding: '5px 10px',background: 'white' }}
      />
      <FaCalendar 
       style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
       onClick={onClick} />

    </div>
  );
//   const handleDateChange = (date) => {
//     if (date) {
//         const formattedDate = new Date(date).toLocaleDateString();
//         setCloseOutData((prev) => ({ ...prev, date: formattedDate }));
//         console.log(formattedDate);
       
//     }
// };

const handleDateChange = (date) => {
  if (date) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    setCloseOutData((prev) => ({ ...prev, date: formattedDate }));
    console.log(formattedDate);
  }
};


  const CustomTimePickerInput = ({ value, onClick }) => (
    <div>
      <input className='form-control w-full'
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={{ cursor: 'pointer', padding: '5px 10px', background: 'white' }}
      />
      <FaClock style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
       onClick={onClick} />
    </div>
  );
  const handleCloseOutData = (id, val) => {
    const updatedPeopleList = closeOutData.closeOutList.map((opItem) => ({
      ...opItem,
      checked: opItem.id == id ? val : opItem.checked,
      selectedValue:
        opItem.checked === true ? "yes" : opItem.checked === false ? "no" : "",
    }));
    handleCloseData("closeOutList", updatedPeopleList);
  };
  return (
    <div>
      <div>
        {closeOutData.closeOutList.map((data) => {
          return (
            <div class="form-group">
              <label>{data.cbTitle}</label>
              <div class="form-check">
                <input
                  checked={data.checked === 'yes'}
                  onChange={() => {
                    handleCloseOutData(
                      data.id,
                      data.checked !== 'yes' ? 'yes' : ""
                    );
                  }}
                  type="checkbox"
                  class="form-check-input"
                 
                />
                <label class="form-check-label">
                  YES
                </label>
              </div>
              <div class="form-check">
                <input
                  checked={data.checked === 'no'}
                  type="checkbox"
                  class="form-check-input"
                 
                  onChange={() => {
                    handleCloseOutData(
                      data.id,
                      data.checked !== 'no' ? 'no' : ""
                    );
                  }}
                />
                <label class="form-check-label">
                  NO
                </label>
              </div>
            </div>
          );
        })}
        <div class="form-group">
          <label>
            Special instruction, precautions, limitations, remarks:
          </label>
          <div>
            <input
              value={closeOutData.remarks}
              onChange={(e) => handleCloseData('remarks', e.target.value)}
              class="form-control col-12"
             
              placeholder=""
            />
          </div>
        </div>


        <div class="form-group">
          <label>Receiver Closeout</label>
          <div>

            <input
              class="form-control mb-1"
             
              type='text'
              placeholder=""
              value={closeOutData?.question || ''}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, '');
                handleCloseData('question', alphabetsOnly);
              }}
            />
          </div>
        </div>
        <div class="form-group">
          <label>Issuer Closeout</label>
          <div>

            <input
              class="form-control mb-1"
             
              type='text'
              placeholder=""
              value={closeOutData?.answer || ''}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, '');
                handleCloseData('answer', alphabetsOnly);
              }}
            />
          </div>
        </div>
        <div class="form-group">
          <label>Date</label>
          <div>
          
            <DatePicker   
             className="form-control col-12" 
              value={closeOutData.date}
              minDate={new Date(currentDate)}
              onChange={(date) => handleDateChange( date)}
              placeholderText="" 
              dateFormat="dd-MMM-yyyy"
              customInput={<CustomDatePickerInput />}
            />

          
          </div>
        </div>
        <div class="form-group">
          <label>Time</label>
          <div>

            <DatePicker className='form-control time-input'
           
              selected={time}
              value={(closeOutData?.time) || ''}
              onChange={handleTimeChange("time")}
              showTimeSelect
              minTime={new Date()} // Set minTime to the current time
              maxTime={new Date(new Date().setHours(23, 59))} // Set maxTime to the end of the day (11:59 PM)
              showTimeSelectOnly
              timeIntervals={1}
              dateFormat="dd-MMM-yyyy"
              customInput={<CustomTimePickerInput />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorePermitCloseOut;
