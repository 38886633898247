
import React, { useState } from 'react'
import { getNewListWithModifyChecked } from '../../Utilities/utils';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils';
import { CustomTimePickerInput } from '../../Utilities/utils';


const currentDate = new Date().toISOString().split('T')[0];

const CorePermitChange = ({ changeData, setChangeData }) => {

    const handleChangeData = (key, val) => {
        setChangeData((prev) => ({ ...prev, [key]: val }));
    };


    const handleDateChange = (date) => {
        if (date) {
          const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ];
      
          const day = date.getDate();
          const month = months[date.getMonth()];
          const year = date.getFullYear();
      
          const formattedDate = `${day}-${month}-${year}`;
          setChangeData((prev) => ({ ...prev, date: formattedDate }));
          console.log(formattedDate);
        }
      };
      
    const [time, setTime] = useState(new Date());
    const [SelectTime, SetSelectTime] = useState('');
    const handleTimeChange = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString('en-US', options); 
        setChangeData((prev) => ({ ...prev, [key]: formattedTime }));
        setTime(selectedTime);
    };



    return (
        <div>
            <div class="form-group">
                <div class="col-12">
                    {changeData.changeList.map((data, index) => (<div class="form-check form-check-inline d-flex align-content-center">
                        <input onChange={() =>
                            setChangeData({
                                ...changeData,
                                environmentList: getNewListWithModifyChecked(index, changeData.changeList),
                            })
                        }
                            checked={data.checked} class="form-check-input mt-0 change-color" type="checkbox" id="inlineCheckbox1" value="option1" />
                        <label class="form-check-label mt-0 change-color" >{data.cbTitle}</label>
                    </div>))}
                </div>
            </div>
            <div class="row">
                <h3 style={{ color: "red", fontSize: "14px" }}>STOP WORK -notify permit issuer for review!</h3>
            </div>
            <div>
                <div className="Corper-subtxt change-bg-color">
                    <h6 className="card-subheading change-bg-color">If suspended, reauthorize permit to continue work!</h6>
                </div>
            </div>

            <div class="form-group">
                <label>Issuer</label>
                <div>

                    <input
                        class="form-control mb-1"                      
                        type='text'
                        placeholder=""
                        value={changeData?.issuer || ''}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            // Use a regular expression to match only alphabets (A-Z and a-z)
                            const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, '');
                            handleChangeData('issuer', alphabetsOnly);
                        }}
                    />
                    {/* <input class="form-control" placeholder="SIGN" /> */}
                    <input value={changeData.signature} placeholder="SIGN"
                        onChange={(e) => handleChangeData('signature', e.target.value)}
                        class="form-control col-12 mb-1" />
                </div>
            </div>
            <div class="form-group">
                <label >Receiver</label>
                <div>

                    <input
                        class="form-control mb-1"
                       
                        type='text'
                        placeholder=""
                        value={changeData?.receiver || ''}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            // Use a regular expression to match only alphabets (A-Z and a-z)
                            const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, '');
                            handleChangeData('receiver', alphabetsOnly);
                        }}
                    />

                </div>
                <div>
                    {/* <input class="form-control" placeholder="SIGN" /> */}
                    <input value={changeData.sighn} placeholder="SIGN" type='text'
                        onChange={(e) => handleChangeData('sighn', e.target.value)}
                        class="form-control col-12 mb-1" />
                </div>
            </div>

            <div class="form-group">
                <label >Date</label>
                                            

                    <DatePicker
                        className="form-control col-12"
                        value={changeData.date}
                        minDate={new Date(currentDate)}
                        dateFormat="dd-MMM-yyyy"
                        onChange={(date) => handleDateChange(date)}
                        customInput={<CustomDatePickerInput />}
                    />
              
            </div>
            <div class="form-group ">
                <label >Time</label>
                

                    <DatePicker className='form-control time-input'                    
                        selected={time}
                        value={(changeData.time) || ''}                    
                        onChange={handleTimeChange("time")}
                        showTimeSelect
                        minTime={new Date()} // Set minTime to the current time
                        maxTime={new Date(new Date().setHours(23, 59))} // Set maxTime to the end of the day (11:59 PM)
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="h:mm aa"
                        customInput={<CustomTimePickerInput />}
                    />
               
            </div>
        </div>
    )
}

export default CorePermitChange;