import React from "react";
import { Button, Modal } from "react-bootstrap";

export const CustomModal = ({ buttonText, handleCancel_new, showModal, handleModalClose, tittle, childrens, handleSubmitData , msgPopup, handleOk}) => {
    return (
        <Modal show={showModal} centered backdrop="static" keyboard={false} size="lg">
            <Modal.Header className="border-0 d-flex justify-content-center">
                <Modal.Title> </Modal.Title>
                {
                     msgPopup ? "" :  <Button variant="link" className="close btn btn-danger  close_btn" onClick={handleCancel_new}>  {/*closeWarning */}
                     X
                 </Button>
                }
               
            </Modal.Header>
            <Modal.Body className='pl-md-5 pr-md-5 pt-0' >
                <div className='d-flex justify-content-center mb-3 font-weight-600'>
                    <span className='modal_title'>{tittle}</span>
                </div>
                {childrens}

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    {
                        msgPopup ? (<Button variant="primary" className='btnn' onClick={handleOk}   >
                            {"OK"}
                        </Button>) : (
                             <>
                              <Button variant="primary" className='btnn' onClick={handleSubmitData} >
                              {buttonText}
                          </Button>
                          <div style={{ width: '20px' }}></div>
                          <Button variant="danger" className='btnn' onClick={handleCancel_new} >Cancel</Button></>
                        )
                    }
                  
                </div>
            </Modal.Body>
        </Modal>
    )
}