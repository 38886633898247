import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import { StockInWardExcelFileFormat, StockInWardExcelUpload } from "../../Utilities/URLCONSTANTS";
import Loader_Bulk from '../../Utilities/Loader_Bulk';
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";

const MassUpload = () => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState({ successCount: null, failureCount: null });
    const navigate = useNavigate();

    const handleUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleDownloadExcelResponcePath = (excelPath) => {
        if (!excelPath) {
            Swal.fire({
                icon: "error",
                title: "Download Error",
                text: "No file available for download.",
                showConfirmButton: true,
            });
            return;
        }
        window.open(excelPath, "_blank");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const headers = await GetApiHeaders();
        if (!file) {
            Swal.fire({
                icon: "warning",
                title: "No File Selected",
                text: "Please select a file to upload.",
                showConfirmButton: true,
            });
            return;
        }
        const validExtensions = [".xlsx"];
        const maxFileSize = 5 * 1024 * 1024;
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        if (!validExtensions.includes(fileExtension)) {
            Swal.fire({
                icon: "error",
                title: "Invalid File Type",
                text: "Please upload a valid Excel file (.xlsx).",
                showConfirmButton: true,
            });
            return;
        }
        if (file.size === 0) {
            Swal.fire({
                icon: "error",
                title: "Empty File",
                text: "The uploaded file is empty. Please upload a valid Excel file.",
                showConfirmButton: true,
            });
            return;
        }
        if (file.size > maxFileSize) {
            Swal.fire({
                icon: "error",
                title: "File Too Large",
                text: "The file size exceeds the 5MB limit. Please upload a smaller file.",
                showConfirmButton: true,
            });
            return;
        }
        const formData = new FormData();
        formData.append("excelFile", file);
        setIsLoading(true);
        try {
            delete headers["Content-Type"];
            const response = await fetch(StockInWardExcelUpload, {
                method: "POST",
                body: formData,
                headers,
            });
            const result = await response.json();
            if (response.ok && result.statusCode == "200") {
                setUploadStatus({
                    successCount: result.response.successCount,
                    failureCount: result.response.failureCount,
                });
                Swal.fire({
                    icon: "success",
                    html: `<span style="color: green;">${result.message}</span>`,
                    showConfirmButton: true,
                }).then(() => {
                    if (result.response.excelPath) {
                        handleDownloadExcelResponcePath(result.response.excelPath);
                    }
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: result.message,
                    text: `Failures: ${result.response.failureCount}, Successes: ${result.response.successCount}`,
                    showConfirmButton: true,
                });
                setUploadStatus({
                    successCount: result.response.successCount,
                    failureCount: result.response.failureCount,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred while uploading the file.",
                showConfirmButton: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const downloadExcelTemplate = async () => {
        const url = StockInWardExcelFileFormat;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({}),
            });

            if (response.ok) {
                const result = await response.json();
                if (result.statusCode == "200" && result.response) {
                    const fileUrl = result.response;
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.download = fileUrl.split('/').pop();
                    link.click();
                } else {
                    console.error('Failed to retrieve the file URL:', result.message);
                    Swal.fire({
                        icon: 'error',
                        title: 'Download Failed',
                        text: 'Could not retrieve the template. Please try again.',
                    });
                }
            } else {
                console.error('Failed to fetch the file URL:', response.statusText);
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: 'Error fetching file. Please try again.',
                });
            }
        } catch (error) {
            console.error('Error occurred while downloading the file:', error);
            Swal.fire({
                icon: 'error',
                title: 'Download Error',
                text: 'An error occurred while downloading the file. Please try again later.',
            });
        }
    };

    return (
        <div>
            {isLoading && <Loader_Bulk />}
            <header style={{ padding: "8px", backgroundColor: "#54B4D3", textAlign: "center", color: "black" }}>
                <h5>Bulk Upload</h5>
            </header>

            <div className="container mt-5">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/StockInward" style={{ cursor: "pointer", color: "blue" }}>
                                <h5>GoTo Back</h5>
                            </Link>
                        </li>
                    </ol>
                </nav>

                <h3 className="mb-4">Bulk Upload</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="fileUpload" className="form-label">Excel File</label>
                        <div className="col-md-6">
                            <input
                                type="file"
                                id="fileUpload"
                                className="form-control-file"
                                onChange={handleUpload}
                                accept=".xlsx"
                            />
                            <label className="custom-file-label" htmlFor="fileUpload">
                                {file ? file.name : "Choose file"}
                            </label>
                        </div>
                    </div>

                    <div style={{ marginTop: "2.5rem" }}>
                        <button
                            className="btn btn-link text-primary"
                            onClick={downloadExcelTemplate}
                            type="button"
                            style={{ fontSize: "13px" }}
                        >
                            <i className="bi bi-file-earmark-arrow-down"></i>
                            <span>Download Excel Template</span>
                        </button>
                    </div>

                    <div style={{ marginBottom: '60px' }}></div>
                    <div className="d-flex justify-content-center gap-2">
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            Submit
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                setFile(null);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>

            {/* Display successCount and failureCount */}
            {uploadStatus.successCount !== null && uploadStatus.failureCount !== null && (
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <p>
                        <strong>Success Count:</strong> {uploadStatus.successCount}
                    </p>
                    <p>
                        <strong>Failure Count:</strong> {uploadStatus.failureCount}
                    </p>
                </div>
            )}
            <footer style={{
                padding: "1px",
                textAlign: "center",
                marginTop: "300px"
            }}>
                <p>&copy; Multi Crop Research Center</p>
            </footer>
        </div>
    );
};

export default MassUpload;
