import React, { useEffect, useState } from "react";
import { Chart, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2";
import { turnAroundAPI } from "../../Utilities/URLCONSTANTS";
import axios from "axios";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
const TurnaroundTimeChart = () => {

  const [turnAroundData, setTurnAroundData] = useState([]);
  const [durationLabels, setDurationLabels] = useState([]);

  const fetchData = async () => {
    const trunAroundRequest = turnAroundAPI
    try {
      // debugger;
      const response = await axios.get(trunAroundRequest)
      setTurnAroundData(response.data.response.datasets);
      setDurationLabels(response.data.response.durationDays);
    } catch (error) {
      console.log("trunAroundRequest", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
      title: {
        display: true,
        align: 'start',
      },
      datalabels: {
        color: '#000',
        font: {
          size: 10,
          weight: 'bold',
        },
        formatter: (value, context) => {
          return value && value !== 0 ? value : null;
        },
        anchor: 'end',
        align: 'top',
        offset: -8,
      },
    },
    scales: {
      x: {
        position: 'bottom',
      },
      y: {
        position: 'left',
      },
    },
  };


  // const options = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //       labels: {
  //         boxWidth: 10,
  //         padding: 10,
  //       },
  //     },
  //     title: {
  //       display: true,
  //       text: "",
  //       align: "start",
  //     },
  //     datalabels: {
  //       color: '#fff',
  //       font: {
  //         size: 12,
  //         weight: 'bold',
  //       },
  //       formatter: (value, context) => {
  //         return value && value !== 0 ? value : null;
  //       },
  //       anchor: 'end',
  //       align: 'start',
  //     },
  //   },
  // };

  // const labels = durationLabels;
  const labels = durationLabels;
  const data = {
    labels,
    datasets: turnAroundData,
  };

  return (
    <div className="card p-4 ">
      <label className="chartname">TAT(Turnaround time)</label>
      <div className="chart-container"
        // style={{ height: '230px', marginTop: '-40px' }}
        style={{
          position: "relative",
          height: "220px",
          margin: "0 auto",
        }}
      >
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};

export default TurnaroundTimeChart;
