import React, { useEffect, useState } from "react";
import { rmGetmasters, saveVendorDetails } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  FIRST_Time,
  SUBSITE_NAME,
  SUBSITE_ID,
  MAINSITE_NAME,
  MAINSITE_ID,
} from "../Utils";
import Loader_MS from "../Utilities/Loader_MS";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';




const CorePermitOne = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  sessionStorage.setItem(CorePermitM2_PERMITID, null);
  sessionStorage.setItem(FIRST_Time, null);
  sessionStorage.setItem(COREPERMIT_MOBILE_NUMBER, null);
  sessionStorage.setItem(COREPERMIT_VENDOR_NAME, null);
  sessionStorage.setItem(COREPERMIT_DATE, null);
  sessionStorage.setItem(SUBSITE_NAME, null);
  sessionStorage.setItem(SUBSITE_ID, null);
  sessionStorage.setItem(MAINSITE_NAME, null);
  sessionStorage.setItem(MAINSITE_ID, null);

  const [Name, setName] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  //Site
  const [site, setSite] = useState([]);
  const [subSite, setSubSite] = useState("");
  const [typeOfSubLocation, setTypeOfSubLocation] = useState([]);
  const [typeOfSites, setTypeOfSites] = useState([]);
  const [selectedMainSiteMaster, setSelectedMainSiteMaster] = useState(null);
  const [selectedSubSiteMaster, setSelectedSubSiteMaster] = useState(null);
  const [filteredSubsites, setFilteredSubsites] = useState([]);



  const handleMobileNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");
    if (/^[6-9]\d{0,9}$|^$/.test(inputNumber)) {
      setMobileNumber(inputNumber);
    }
    console.log(inputNumber);
    if (inputNumber.length > 0) {
      setError("");
    }
  };

  const handleNameBeChange = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, "");
    setName(alphaValue);
    console.log(alphaValue);
    if (inputValue.length > 0) {
      setError("");
    }
  };


  useEffect(() => {
    getMasterData();
  }, [])


  const getMasterData = async () => {
    const getDataEndpoint = rmGetmasters;
    try {
      const response = await axios.get(getDataEndpoint);
      const siteData = response.data.response?.site || [];
      const subSiteData = response.data.response?.subSite || [];
      setTypeOfSites(siteData);
      setTypeOfSubLocation(subSiteData);
    } catch (err) {
      console.error("Error fetching site data:", err);
    }
  };


  const handleChangeSite = (site) => {
    setSelectedMainSiteMaster(site);
    setSite(site?.label || "");
    if (site) {
      setError("");
    }
    const filteredSubSites = typeOfSubLocation.filter(
      (subSite) => subSite.siteId === site?.value
    );
    setFilteredSubsites(filteredSubSites);
  };

  const handleChangeSubsite = (subSite) => {
    setSelectedSubSiteMaster(subSite);
    setSubSite(subSite?.label || "");
    if (subSite) {
      setError("");
    }
  };



  const handleSubmit = async () => {
    console.log("Regular Expressions Start");

    // Define regex patterns
    const mobileNumberPattern = /^[6-9]\d{9}$/;
    const nameRegex = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;

    // Validate Name
    if (!Name.trim()) {
      setError("Please Enter Name");
      return;
    }

    if (!nameRegex.test(Name)) {
      setError("Name must contain only alphabets and spaces");
      return;
    }

    // Validate Mobile Number
    if (!MobileNumber.trim()) {
      setError("Please Enter Mobile Number");
      return;
    }

    if (MobileNumber.length !== 10) {
      setError("Mobile Number should be 10 digits");
      return;
    }

    if (!mobileNumberPattern.test(MobileNumber)) {
      setError("Please Enter a Valid Mobile Number");
      return;
    }

    // Validate Site Selection
    if (!selectedMainSiteMaster) {
      setError("Please Select a Site");
      return;
    }

    // Validate Sub-Site Selection
    if (!selectedSubSiteMaster) {
      setError("Please Select a Sub-Site");
      return;
    }

    // Reset error if everything is valid
    setError("");

    console.log("Regular Expressions End");

    // Proceed with API submission
    const dataToSend = {
      name: Name,
      mobileNumber: MobileNumber,
      siteId: selectedMainSiteMaster.value,
      subSiteId: selectedSubSiteMaster.value,
    };

    console.log("Data to Send:", dataToSend);

    const URL = saveVendorDetails;
    setIsLoading(true);

    try {
      const response = await axios.post(URL, dataToSend);
      setIsLoading(false);
      console.log("saveVendorDetails :", response.data.response);

      if (
        response.data &&
        response.data.response &&
        response.data.response.vendorDetails.length > 0
      ) {
        const vendorDetails = response.data.response.vendorDetails[0];
        sessionStorage.setItem(COREPERMIT_MOBILE_NUMBER, vendorDetails.mobileNumber);
        sessionStorage.setItem(COREPERMIT_VENDOR_NAME, vendorDetails.vendorName);
        sessionStorage.setItem(COREPERMIT_DATE, vendorDetails.vendorEntryDate);
        sessionStorage.setItem(FIRST_Time, vendorDetails.firstTime);
        sessionStorage.setItem(MAINSITE_NAME, vendorDetails.mainSiteId.siteName);
        sessionStorage.setItem(MAINSITE_ID, vendorDetails.mainSiteId.id);
        sessionStorage.setItem(SUBSITE_NAME, vendorDetails.subSiteId.subSiteName);
        sessionStorage.setItem(SUBSITE_ID, vendorDetails.subSiteId.id);
        console.log("vendorDetails.subSiteId.subSiteName", vendorDetails.subSiteId.subSiteName);
        console.log(" vendorDetails.mainSiteId.siteName", vendorDetails.mainSiteId.siteName,);
        sessionStorage.setItem(SUBSITE_ID, vendorDetails.subSiteId.id);
        navigate("/AdditionalPermitOptions");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Network Error:", error);

      if (error.response?.status === 500 || error.response?.status === 502) {
        setError("Something went wrong, please try again later.");
      } else {
        setError("Unable to process your request at this time.");
      }
    }
  };


  return (
    <body id="page-top" style={{ paddingTop: "10px", margin: "10px" }}>
      <div id="wrapper">
        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          <div id="content">
            <div>
              <div className="container-fluid">
                <div className="d-flex justify-content-between"></div>
                <div>
                  {/* <form> */}
                  <div className="row">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="col-md-6 col-12">
                      <label
                        for="exampleInputPassword1"
                        className="form-label text-sm font-weight-600 mt-2"
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="input-group input-group w-100">
                        <input
                          type="text"
                          className="form-control p-2"
                          placeholder="Enter Name"
                          value={Name}
                          onChange={handleNameBeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <label
                        for="exampleInputPassword1"
                        className="form-label text-sm font-weight-600 mt-2"
                      >
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="input-group input-group w-100">
                        <input
                          type="number"
                          className="form-control p-2"
                          placeholder="Enter Mobile Number"
                          value={MobileNumber}
                          onChange={handleMobileNumberChange}
                        />
                      </div>
                    </div>



                    <div className="col-md-6 col-12">
                      <label className="form-label font-weight-600 mt-1">
                        Site <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={typeOfSites.map((item) => ({
                          label: item.name,
                          value: item.code,
                        }))}
                        value={selectedMainSiteMaster}
                        onChange={handleChangeSite}
                        placeholder="Select Site"
                      />
                    </div>

                    <div className="col-md-6 col-12">
                      <label className="form-label font-weight-600 mt-1">
                        Sub Site <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={filteredSubsites.map((item) => ({
                          label: item.name,
                          value: item.code,
                        }))}
                        value={selectedSubSiteMaster}
                        onChange={handleChangeSubsite}
                        placeholder="Select Sub Site"
                      />
                    </div>



                  </div>
                  <br />
                  <br />
                  {/* <button
                    type="submit"
                    className="btn btn-primary col-12 mb-3"
                    onClick={handleSubmit}
                    style={{ marginBottom: "20px" }}
                  >
                    Submit
                  </button> */}

                  <button
                    type="submit"
                    className="btn btn-primary col-12 mb-3"
                    onClick={handleSubmit}
                    style={{ marginBottom: "20px", marginTop: "70px" }} // Add margin-top
                  >
                    Submit
                  </button>


                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
      {isLoading && (
        <Loader_MS
          loading={isLoading}
          message={"Please Wait..!"}
        />
      )}
    </body>
  );
};
export default CorePermitOne;
