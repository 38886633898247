import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Header from "./Header";
import Logout from "./Logout";
import { strings } from "../Utilities/strings";
import Loader from "../Utilities/Loader";
import * as XLSX from "xlsx";
// for Sidebar And Navbar
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Login from "../Login";
import {
  deleteActivity,
  FarmOperation_getMasters,
  rmActivity,
  RmhistoryGet,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import closedProducts from "../images/closed-products.png";
import user from "../images/user.png";
import productrequestImage from "../images/product_request.png";
import issuedProducts from "../images/issued-products.png";
import PendingProducts from "../images/Pending_products.png";
import Delete from "../images/ic_delete.png";
import { date } from "yup";
import moment from "moment";

//ProductMaster

const FarmOperationsGenaralMaintanance = ({ }) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  //  const  categories = CategoryMaster;
  const [id, setId] = useState("");
  // const [location, setlocation] = useState("");
  // const [locationName, setlocationName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({
    code: "",
    name: "",
  });
  const [locationDropDownData, setlocationDropDownData] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [date, setDate] = useState("");
  const [material, setMaterial] = useState("");
  const [repaire, setRepaire] = useState("");
  const [cost, setCost] = useState("");
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState("");
  const [errorService, setErrorService] = useState("");
  const [Errorlocation, setErrorlocation] = useState("");
  const [ErrorMaterial, setErrorMaterial] = useState("");
  const [Errorrepaire, setErrorRepaire] = useState("");
  const [errorcost, setErrorCost] = useState("");
  const [errorremarks, setErrorRemarks] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close General Maintanance"
  );
  const [getActivityType, setGetActivityType] = useState([]);
  const [showMsg, setShowMsg] = useState("false");
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    activityType: "",
    requiredDate: "",
    locationId: "",
    locationName: "",
    service: "",
    material: "",
    repair: "",
    cost: "",
    remarks: "",
    submittedDate: "",
  });

  const [data, setData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState("");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const handleCancel_new = () => {
    setShowModal(true);
    setShowPopup(true);
  };
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) => {
          return (
            item.submittedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.service.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.material.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.repair.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.cost
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.remarks.toLowerCase().includes(searchTerm.toLowerCase())
          )
        });
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Submitted Date",
        "Location Name",
        "Service",
        "Material",
        "Repair",
        "Cost",
        "Remarks",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.submittedDate,
        item.locationName,
        item.service,
        item.material,
        item.repair,
        item.cost,
        item.remarks,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    // Save the file
    XLSX.writeFile(wb, "GeneralMaintanance.xlsx");
  };

  const handleCancel = () => {
    //no
    setShowModal(true);
    setShowPopup(false);
  };
  const handleClose = () => {
    //yes
    setShowPopup(false);
    setShowModal(false);
  };

  // ...more items

  function handlelocationChange(e) {
    const selectedValue = e.target.value;
    const selectedLocationData = locationDropDownData.find(
      (item) => item.code.toString() === selectedValue
    );
    console.log(JSON.stringify(locationDropDownData));
    if (selectedLocationData) {
      setSelectedLocation({
        code: selectedLocationData.code,
        name: selectedLocationData.name,
      });
    } else {
      setSelectedLocation({
        code: "",
        name: "",
      });
    }
  }
  const handleServiceChange = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setServiceId(result);
  };
  const handleMaterialChange = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setMaterial(result);
  };
  const handledateChange = (e) => {
    // const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS"); // Current date
    const selectedDate = e.target.value;
    setDate(selectedDate);
  };

  const handleRepaireChange = (e) => {
    const inputRepaire = e.target.value;
    setRepaire(inputRepaire);
  };

  const handleCostChange = (e) => {
    const inputunitCost = e.target.value;
    if (!isNaN(inputunitCost)) {
      setCost(inputunitCost);
    }
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleSubmit = async (modalData) => {
    //alert(date);
    if (date === "" || date === null) {
      alert("Please Enter Date");
      return;
    }
    if (selectedLocation.code === "" || selectedLocation.code === null) {
      alert("Please Select Location");
      return;
    }
    if (serviceId === "") {
      alert("Please Enter Service");
      return;
    }
    if (material === "") {
      alert("Please Enter Material");
      return;
    }


    if (repaire === "") {
      alert("Please Enter Repair");
      return;
    }
    if (cost === "") {
      alert("Please Enter Cost");
      return;
    }
    try {
      console.log(JSON.stringify(modalData));
      console.log(
        cost +
        " : " +
        repaire +
        " : " +
        remarks +
        " : " +
        material +
        " : " +
        date +
        " : " +
        serviceId +
        " : " +
        selectedLocation
      );
      // Check if required fields are not empty
      if (
        cost !== "" &&
        repaire !== "" &&
        material !== "" &&
        date !== "" &&
        serviceId !== "" &&
        selectedLocation.code !== ""
      ) {
        const apiUrl = rmActivity;
        const headers = await GetApiHeaders();

        const dataToSend = {
          serverRecordId: { buttonText } == "Submit" ? 0 : parseInt(id),
          activityType: getActivityType[6].name,
          requiredDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss.SSS"), //moment(date, "YYYY-MM-DD HH:mm:ss.SSS") ,//date,
          locationId: selectedLocation.code,
          locationName: selectedLocation.name,
          service: serviceId,
          material: material,
          repair: repaire,
          cost: cost,
          remarks: remarks,
          submittedDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss.SSS"), //moment(date, "YYYY-MM-DD HH:mm:ss.SSS")  //date
        };
        // Make the POST request using Axios with headers
        setIsLoading(true);
        const response = await axios.post(apiUrl, dataToSend, { headers });
        console.log("response submit:: ", response);
        setsubmitResponse(response.data.message);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);

        // Proceed with data insertion if the API call was successful
        const newId = String(Date.now());
        const newItem = {
          id: newId,
          activityType: "generalMaintenance",
          requiredDate: date,
          locationId: selectedLocation.code,
          locationName: selectedLocation.name,
          service: serviceId,
          material: material,
          repair: repaire,
          cost: cost,
          remarks: "",
          submittedDate: "",
          serverRecordId: "",
        };
        setErrorCost("");
        setErrorDate("");
        setErrorMaterial("");
        setErrorRemarks("");
        setErrorRepaire("");
        setErrorService("");
        setErrorlocation("");
        setIsSubmitted(true);
        //setData(prevData => [...prevData, newItem]);
      } else {
        setIsSubmitted(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitted(false);
      //  handleUpdate();
    }
  };

  const fetchData = async () => {
    const apiUrl = RmhistoryGet;
    const headers = await GetApiHeaders();
    const getDataObject = {
      activityType: "General Maintenance",
      endDate: "",
      mobileNumber: headers.mobileNumber,
      startDate: "",
      userId: headers.userId,
    };

    setIsLoading(true);
    axios
      .post(apiUrl, getDataObject, { headers })
      .then((response) => {
        console.log(
          "main table response ::;;",
          JSON.stringify(response.data.response.rmHistory)
        );
        setData(response.data.response.rmHistory);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
    fetchLocationDropdownData();
  }, [modalData]);

  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setSelectedLocation({ code: "", name: "" });
    setServiceId("");
    setMaterial("");
    setRepaire("");
    setCost("");
    setRemarks("");
    setDate("");
    setModalData({
      id: "",
      activityType: "",
      requiredDate: "",
      locationId: "",
      locationName: "",
      service: "",
      material: "",
      repair: "",
      cost: "",
      remarks: "",
      submittedDate: "",
      serverRecordId: "",
    });
  };

  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);

    setShowWarningPopup(false);
    window.location.reload();
  };
  const fetchLocationDropdownData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("response", response);
        setlocationDropDownData(response.data.response.subSiteMaster);
        setGetActivityType(response.data.response.rmActivitiesMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const updatedItems = (item) => {
    setModalData(item);
    setId(item.serverRecordId);
    setDate(
      item.submittedDate !== "" && item.submittedDate !== null
        ? item.submittedDate.toString().split(" ")[0]
        : ""
    );
    setSelectedLocation({ code: item.locationId, name: item.locationName });
    setServiceId(item.service);
    setMaterial(item.material);
    setRepaire(item.repair);
    setCost(item.cost);
    setRemarks(item.remarks);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const deleteItem = async (item) => {
    // alert(JSON.stringify(item));
    const apiUrl = deleteActivity;
    const headers = await GetApiHeaders();

    const dataToSend = {
      id: item.serverRecordId,
    };
    // alert(JSON.stringify(dataToSend));
    // Make the POST request using Axios with headers
    setIsLoading(true);
    const response = await axios.post(apiUrl, dataToSend, { headers });
    console.log("response submit:: ", response);
    setsubmitResponse(response.data.message);
    setShowResponsePopup(true);
    setIsLoading(false);
  };

  const setMinDateToToday = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };


  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Farm Operations</h5>

                    <div className="d-flex justify-content-between mt-4">
                      <div>
                        <h6 className="mb-0 Sub_role_text">
                          General Maintanance
                        </h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add General Maintanance"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingLeft: 25, paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="🔍 Type to Search..."
                            value={searchTerm}
                            onChange={(e) => handleFilter(e.target.value)}
                            style={{
                              height: "36px",
                              width: "160px",
                              paddingRight: "30px",
                            }}
                            className="mr-2"
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                              }}
                              onClick={() => handleFilter("")}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                        &nbsp;
                        {/* Add the Excel export button */}
                        <button
                          className="btn btn-secondary buttons-excel buttons-html5"
                          onClick={exportToExcel}
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body  ">
                          <Table className="table table-bordered  table-striped">
                            {" "}
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                {/* <Th className="text-center">Date</Th> */}
                                <Th className="text-center" onClick={() => handleSort("newDateFormat")}>
                                  Date{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "newDateFormat"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                {/* <Th className="text-center">Location</Th> */}
                                <Th className="text-center" onClick={() => handleSort("locationName")}>
                                  Location{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "locationName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                {/* <Th className="text-center">Service</Th> */}
                                <Th className="text-center" onClick={() => handleSort("service")}>
                                  Service{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "service"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                {/* <Th className="text-center">Material</Th> */}
                                <Th className="text-center" onClick={() => handleSort("material")}>
                                  Material{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "material"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                {/* <Th className="text-center">Repair</Th> */}
                                <Th className="text-center" onClick={() => handleSort("repair")}>
                                  Repair{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "repair"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                {/* <Th className="text-center">Cost</Th> */}
                                <Th className="text-center" onClick={() => handleSort("cost")}>
                                  Cost{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "cost"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                {/* <Th className="text-center">Remarks</Th> */}
                                <Th className="text-center" onClick={() => handleSort("remarks")}>
                                  Remarks{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "remarks"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center">Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {/* {data.map((item) => ( */}
                              {sortedItems.length === 0 ? ( // Check if no data is found
                                <Tr>
                                  <Td
                                    colSpan={10}
                                    className="text-center"
                                    style={{ padding: 195 }}
                                  >
                                    Data is not available
                                  </Td>
                                </Tr>
                              ) : (
                                currentItems != undefined &&
                                currentItems != null &&
                                sortedItems.map((item, index) => {
                                  var newDateFormat = moment(
                                    item.submittedDate
                                  ).format("DD-MMM-YYYY hh:mm:ss A");
                                  return (
                                    <Tr>
                                      <Td className="text-left"> {(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                      <Td className="text-left">
                                        {newDateFormat}
                                      </Td>
                                      <Td className="text-left">
                                        {item.locationName}
                                      </Td>
                                      <Td className="text-left">
                                        {item.service}
                                      </Td>
                                      <Td className="text-left">
                                        {item.material}
                                      </Td>
                                      <Td className="text-left">
                                        {item.repair}
                                      </Td>
                                      <Td className="text-left">{item.cost}</Td>
                                      <Td className="text-right">
                                        {item.remarks}
                                      </Td>
                                      <Td className="text-center">
                                        <div
                                          style={{
                                            width: 20,
                                            height: 20,
                                            flexDirection: "row",
                                          }}
                                          onClick={() => updatedItems(item)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faEdit}
                                            className="plus_icon mr-1 text-primary text-center"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit"
                                          />
                                          <img
                                            src={Delete}
                                            alt="downloadImg"
                                            style={{
                                              height: "15px",
                                              marginTop: -5,
                                              marginLeft: 3,
                                            }}
                                            onClick={() => deleteItem(item)}
                                          />
                                          {/* <img icon={faDeleteLeft} className="plus_icon mr-1 text-primary text-center" data-toggle="tooltip" data-placement="top" title="delete" />
                                           */}
                                          {/* Add button content here */}
                                        </div>
                                      </Td>
                                    </Tr>
                                  );
                                })
                              )}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                        </div>
                        {/* Pagination */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5 }}
                        >
                          <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                            &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                            of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                                  }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button
                                //  className='ul-pagination page-item'
                                //   style={{
                                //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                //     color: (currentPage == 1) ? 'white' : 'black'
                                //   }}
                                className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                                  }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                    }`}
                                  //className='ul-pagination page-item'
                                  //   style={{
                                  //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                  //     color: (currentPage == number) ? 'white' : 'black'
                                  //   }}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                    }`}
                                  // className='ul-pagination page-item'
                                  // style={{
                                  //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                  //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                  // }}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                  ? "active"
                                  : ""
                                  }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger  close_btn"
                                onClick={handleCancel_new}
                              >
                                {" "}
                                {/*closeWarning */}X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  General Maintanance
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Date <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      placeholder="Select Date"
                                      selected={date}
                                      onChange={handledateChange}
                                      value={date}
                                      min={setMinDateToToday()} // Set the minimum date to today
                                    //  maxDate={new Date()} // Maximum date is today
                                    //  minDate={moment().subtract(30, 'days').toDate()} // Minimum date is 30 days ag
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Location{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-control p-2"
                                    value={selectedLocation.code}
                                    onChange={handlelocationChange}
                                  >
                                    <option value="">
                                      Select Location Name
                                    </option>
                                    {locationDropDownData.map((item) => (
                                      <option key={item.code} value={item.code}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Service{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Service"
                                      value={serviceId}
                                      onChange={handleServiceChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Material{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Material"
                                      value={material}
                                      onChange={handleMaterialChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Repair{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Repair"
                                      value={repaire}
                                      onChange={handleRepaireChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Cost <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder="Cost"
                                      value={cost}
                                      onChange={handleCostChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Remarks{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Remarks"
                                      value={remarks}
                                      inputMode="text"
                                      onChange={handleRemarksChange}
                                    />
                                  </div>
                                </div>
                                {error && (
                                  <span
                                    style={{ color: "red" }}
                                    className="error-message"
                                  >
                                    {error}
                                  </span>
                                )}
                              </div>{" "}
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => {
                                    setModalData({
                                      id: id,
                                      requiredDate: date,
                                      locationId: selectedLocation.code,
                                      locationName: selectedLocation.name,
                                      service: serviceId,
                                      material: material,
                                      repair: repaire,
                                      cost: cost,
                                      remark: remarks,
                                      submittedDate: data,
                                      serverRecordId: "",
                                    });
                                    handleSubmit(modalData);
                                  }}
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  General Maintanance
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  General Maintanance{" "}
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}
          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      {/*  <!-- Logout Modal--> */}
      <Logout />
      {/* {!isUserLoggedIn && <Login />} */}
    </body>
  );
};

export default FarmOperationsGenaralMaintanance;
