import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { FarmOperationgetRequestedandDeliveredCount } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Chart.register(...registerables);

const FarmOperationRequestComplete = () => {
  const [requestedCount, setRequestedCount] = useState([]);
  const [RequestDelvLabels, setRequestDelvLabels] = useState([]);
  const [selectedValue, setSelectedValue] = useState("All");
  const [hoveredPercentage, setHoveredPercentage] = useState(null);
  const [allCount, setAllCount] = useState();
  const [completedCount, setCompletedCount] = useState();
  const [inProgressCount, setInProgressCount] = useState();
  const [pendingCount, setPendingCount] = useState();
  const [openCount, setOpenCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  var cumulativePercentage = "";

  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;
    const RequestDeliverdRequest = FarmOperationgetRequestedandDeliveredCount;
    const postData = {
      type: (startDate && endDate) ? "" : selectedValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    try {
      const response = await axios.post(RequestDeliverdRequest, postData);

      if (type === "All") {
        setAllCount(response.data.datasets.data[0]);
        setCompletedCount(response.data.datasets.data[1]);
        setPendingCount(response.data.datasets.data[2]);
        setOpenCount(response.data.datasets.data[3]);
      }
      setRequestedCount(response.data.datasets);
      setRequestDelvLabels(response.data.labels);
    } catch (error) {
      console.log("RequestCount", error);
    }
  };

  useEffect(() => {
    const shouldFetchData = selectedValue === "DTD" && startDate && endDate;
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate);
    }
  }, [startDate, endDate, selectedValue]);

  const data = {
    labels: RequestDelvLabels,
    datasets: [requestedCount],
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
        align: "start",
        labels: {
          boxWidth: 10, // Set box width for each legend item
          padding: 10, // Set padding between legend items
        },
      },
      title: {
        display: true,
        text: "",
        align: "start",
      },
      datalabels: {
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value, context) => {
          return value && value !== 0 ? value : null;
        },
        anchor: 'end',
        align: 'start',
      },
      tooltip: {
        callbacks: {
          label: (context) => { },
        },
      },
    },
  };

  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="card p-4 ">
        <label className="chartname">Requested & Completed</label>


        <div className="d-flex" id="filtersInputs">
          <select
            className="form-control w-100"
            value={selectedValue}
            onChange={handleSelect}
          >
            <option value="All">All</option>
            <option value="WTD">Current Week</option>
            <option value="MTD">Current Month</option>
            <option value="YTD">Yearly</option>
            <option value="DTD">Custom selection</option>
          </select>
        </div>
        <div>        {selectedValue === "DTD" && (
          <div className="d-flex">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="form-control  custom-datepicker"
            />
            <DatePicker
              style={{ marginLeft: "130px " }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="form-control  custom-date"
            />
          </div>
        )}
        </div>


        <div className="d-flex gap-4 justify-content-between align-items-start">

          <ul>
            <li className="countStyle">
              Pending<span style={{ marginLeft: 20 }}>:</span> {pendingCount}
            </li>
            <li className="countStyle">
              Inprogress <span style={{ marginLeft: 2 }}>:</span> {allCount}
            </li>
            <li className="countStyle">
              Closed <span style={{ marginLeft: 26 }}>:</span> {completedCount}
            </li>
            <li className="countStyle">
              Open <span style={{ marginLeft: 34 }}>:</span> {openCount}
            </li>
          </ul>

          <div
            className="chart-container"
            style={{
              // position: "relative",
              width: "300px",
              height: "233px",
              // marginLeft: 250,
              marginTop: "-39px",
            }}
          >
            <Doughnut data={data} options={options} />
          </div>

        </div>


      </div>
    </>
  );
};

export default FarmOperationRequestComplete;
