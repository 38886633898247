import React, { useState, useEffect, useRef } from "react";
//import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
//import Accordion from "react-bootstrap/Accordion";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  BasicDetailsConstant,
  PermitIssuerConst,
  PermitReceiverConst,
  EmergencyConst,
  EqipmentInformationConst,
  ImportantConst,
  LiftConditionsConst,
  NoteConstant,
} from "../Utilities/cranePermit-JSON";
//Crane
import CorePermitInputFields from "../components/cranePermit/corePermitInputFields";
import PermitIssuer from "../components/cranePermit/PermitIssuer";
import PermitReceiver from "../components/cranePermit/PermitReceiver";
import Emergency from "../components/cranePermit/Emergency";
import BasicDetails from "../components/cranePermit/BasicDetails";
import Equipment from "../components/cranePermit/Equipment";
import Important from "../components/cranePermit/Important";
import LiftConditions from "../components/cranePermit/LiftConditions";
import Note from "../components/cranePermit/Note";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  MOBILE_NUMBER,
  USER_PROFILE,
  retrieveData,
  ADDITIONAL_PERMITID,
  PERMIT_NAME,
  checkForEmptyValue,
} from "../Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";

import Accordion from "../Accordation/Accordian";
import axios from "axios";

//Approve
import Loader_MS from "../Utilities/Loader_MS";
import Approved from "../images/Approved.png";
import Reject from "../images/Reject.png";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { faTruckMedical } from "@fortawesome/free-solid-svg-icons";
import Loader_Bulk from "../Utilities/Loader_Bulk";

// import Loader from "../Utilities/Loader";

const CranePermit2Additional = (props) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [permitIssuer, setPermitIssuer] = useState(PermitIssuerConst);
  const [permitReceiver, setPermitReceiver] = useState(PermitReceiverConst);
  const [emergency, setEmergency] = useState(EmergencyConst);
  const [basicDetails, setBasicDetails] = useState(BasicDetailsConstant);
  const [eqipmentInformation, setEquipmentInf] = useState(
    EqipmentInformationConst
  );
  const [important, setImportant] = useState(ImportantConst);
  const [liftConditions, setLiftConditions] = useState(LiftConditionsConst);
  const [note, setNote] = useState(NoteConstant);
  const [activeIndex, setActiveIndex] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);

  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  //Reject
  const [showNewModal, setShowNewModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [serverRecordId, setServerRecordId] = useState(null);
  const PermitUserIdfromURL = searchParams.get("acceptorUserId");
  const IdFromURL = searchParams.get("corePermitID");
  const PermitNamefromURL = searchParams.get("permitName");
  const AditionpermitIDFromURL = searchParams.get("additionalPermitID");
  const mobileNumberfromURL = searchParams.get("mb");
  const [permitStatus, setPermitStatus] = useState("");
  const [permitObject, setPermitObject] = useState(null);

  useEffect(() => {
    console.log(liftConditions, "eq");
  }, [liftConditions]);
  const toggleItem = (index) => {
    // debugger;
    setActiveIndex(index === activeIndex ? null : index);
  };
  const resetForm = () => {
    setPermitIssuer(PermitIssuerConst);
    setPermitReceiver(PermitReceiverConst);
    setEmergency(EmergencyConst);
    setBasicDetails(BasicDetailsConstant);
    setEquipmentInf(EqipmentInformationConst);
    setImportant(ImportantConst);
    setLiftConditions(LiftConditionsConst);
    setNote(NoteConstant);
    // setFinalSubmit(true);
  };
  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    //const IdFromURLwithEDit = searchParams.get('corePermitID');

    var IdFromURL;
    var isEdit = "true";
    setIsEdit(isEdit);
    // alert(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id) => {
    try {
      const dataFromStore = await retrieveData(USER_PROFILE);
      const headers = await GetApiHeaders();
      const dataToSend = {
        mobileNumber: headers.mobileNumber == null ? "" : headers.mobileNumber,
        name: "",
        PermitID: IdFromURL,
      };
      setIsLoading(true);
      const response = await axios.post(
        getAllPermitsByCorePermitID,
        dataToSend
      );
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);

      if (response.data && response.data.response) {
        const elevated = response?.data?.response?.crane_permit || [];
        console.log("elevated :: ", elevated);
        const dataLast = elevated[elevated.length - 1]?.permitObject || null;
        console.log("filteredArray checking", JSON.stringify(dataLast));
        if (
          response.data.response.crane_permit != null &&
          response.data.response.crane_permit.length > 0
        ) {

          setPermitStatus(
            response.data.response.crane_permit.find(
              (item) => item.permitID === AditionpermitIDFromURL
            )?.permitStatus
          );
        }
        if (dataLast) {
          setPermitObject(dataLast);
          console.log("dataLast", dataLast);
          console.log("ssn id" + response.serverRecordId);
          setPermitInitials(
            dataLast?.permitInitials ? dataLast?.permitInitials : permitInitials
          );
          setPermitIssuer(
            dataLast?.permitIssuer ? dataLast?.permitIssuer : permitIssuer
          );
          setPermitReceiver(
            dataLast?.permitReceiver ? dataLast?.permitReceiver : permitReceiver
          );
          setEmergency(dataLast?.emergency ? dataLast?.emergency : emergency);
          setBasicDetails(
            dataLast?.basicDetails ? dataLast?.basicDetails : basicDetails
          );
          setEquipmentInf(
            dataLast?.eqipmentInformation
              ? dataLast.eqipmentInformation
              : eqipmentInformation
          );
          setImportant(dataLast.important ? dataLast.important : important);
          setLiftConditions(
            dataLast.liftConditions ? dataLast.liftConditions : liftConditions
          );
          setNote(dataLast.note ? dataLast.note : note);
          setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleAprrovedOk = async (e) => {
    e.preventDefault();
    setStatus("");
    setIsLoading(false);
    setShowApproved(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleApproved = async (e) => {
    const checkedNoteListyes = note.noteList.filter(
      (item) => item.cbYes === "true"
    );
    const checkedNoteListNa = note.noteList.filter(
      (item) => item.cbNA === "true"
    );

    const isNoteListCheckedyes =
      checkedNoteListyes.length >= 1
        ? "true"
        : checkedNoteListyes.length === 0 && checkedNoteListNa.length === 0
          ? ""
          : "false";

    const isNoteListCheckedNa =
      checkedNoteListNa.length >= 1
        ? "true"
        : checkedNoteListyes.length === 0 && checkedNoteListNa.length === 0
          ? ""
          : "false";
    const checkedImportantListyes = important.importantList.filter(
      (item) => item.cbYes === "true"
    );
    const checkedImportantListNa = important.importantList.filter(
      (item) => item.cbNA === "true"
    );

    const isImportantListCheckedyes =
      checkedImportantListyes.length >= 1
        ? "true"
        : checkedImportantListyes.length === 0 &&
          checkedImportantListNa.length === 0
          ? ""
          : "false";

    const isImportantListCheckedNa =
      checkedImportantListNa.length >= 1
        ? "true"
        : checkedImportantListyes.length === 0 &&
          checkedImportantListNa.length === 0
          ? ""
          : "false";

    const valuesToCheck = [
      {
        permitInitials: {
          dateInputOne: permitInitials.dateInputOne,
        },
        emergency: {
          phoneNumber: emergency.phoneNumber,
          assemblyLocation: emergency.assemblyLocation,
        },
        basicDetails: {
          company: basicDetails.company,
        },
        eqipmentInformation: {
          size_or_capacity_in_tons:
            eqipmentInformation.size_or_capacity_in_tons,
        },
        important: {
          importantcbyes: isImportantListCheckedyes,
          importantcbNa: isImportantListCheckedNa,
        },
        liftConditions: {
          r: liftConditions.r,
          h: liftConditions.h,
          l: liftConditions.l,
          w: liftConditions.w,
        },
        note: {
          //"noteList": note.noteList,
          noteListcbyes: isNoteListCheckedyes,
          noteListcbna: isNoteListCheckedNa,
        },
        permitIssuer: {
          permitissuer: permitIssuer.permitissuer,
          permitissuerdate: permitIssuer.date,
        },
        permitReceiver: {
          permitreceiver: permitReceiver.permitreceiver,
          permitreceiverdate: permitReceiver.date,
        },
      },
    ];

    console.log("valuesToCheck" + JSON.stringify(valuesToCheck));
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {
        case "permitInitials":
          alert("Please Select Date");
          return;
        case "emergency":
          alert("Fill the Emergency Data");
          allCasesValid = false;
          break;
        case "basicDetails":
          alert("Please Fill The BasicDetails Data");
          allCasesValid = false;
          break;

        case "eqipmentInformation":
          alert("Please Fill The EqipmentInformation Data");
          allCasesValid = false;
          break;

        case "important":
          alert("Please Fill The Important Data");
          allCasesValid = false;
          break;

        case "liftConditions":
          alert("Please Fill The LiftConditions Data");
          allCasesValid = false;
          break;

        case "note":
          alert("Please Fill The Note Data");
          allCasesValid = false;
          break;

        case "permitIssuer":
          alert("Please Fill The PermitIssuer Data");
          allCasesValid = false;
          break;

        case "permitReceiver":
          alert("Please Fill The PermitReceiver Data");
          allCasesValid = false;
          break;
      }
    }

    // alert(JSON.stringify(e))
    if (e == "Submitted") {
      console.log(e, "handleOk");
      if (allCasesValid) {
        const userProfileData = {
          id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0",
          mobileNumber: mobileNumberfromURL,
          name: "",
          corePermitID: IdFromURL ? IdFromURL : 0,
          additionalPermitName: "crane_permit",
        };
        const finalJson = {
          request: {
            permitInitials,
            emergency,
            basicDetails,
            eqipmentInformation,
            important,
            liftConditions,
            note,
            permitIssuer,
            permitReceiver,
          },
          ...userProfileData,
        };
        console.log(finalJson, "finalJson***");
        setIsLoading(true);
        const response1 = await axios.post(saveAdditionalPermit, finalJson);
        setIsLoading(false);
        if (response1.data.statusCode == 200) {
          const ApproveData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL,
            status: "Approve",
            deviceType: "iOS, Android",
            remarks: "",
          };

          setIsLoading(true);
          const response = await axios.post(ApproveRejectPermit, ApproveData);
          console.log("responce", response);

          setTimeout(() => {
            setIsLoading(false);
            setApiStatus(response.data.message);
            setShowApproved(true);
          }, 3000);
        }
      }
    } else if (e == "Approve") {
      if (allCasesValid) {
        handleSubmit();
      }
    }
  };
  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRemarks("");
    setShowReject(true);
    setIsLoading(false);
  };

  const handleConfirmationOk = () => {
    setShowReject(false);
    setShowConfirmationPopup(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleNewModalOk = () => {
    setShowNewModal(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleRejectOk = async () => {
    console.log("handleRejectOk");

    const RejectData = {
      acceptorUserId: PermitUserIdfromURL,
      permitName: PermitNamefromURL,
      corePermitID: IdFromURL,
      additionalPermitID: AditionpermitIDFromURL || "0",
      status: "Reject",
      deviceType: "iOS, Android",
      remarks: remarks,
    };

    try {
      setIsLoading(true);

      const response = await axios.post(ApproveRejectPermit, RejectData);
      console.log("response", response);

      if (response.data.statusCode !== "200") {
        // Handle error scenario
        setShowConfirmationPopup(true);
        setApiStatus(response.data.message);
        console.log("API throwing error");
      } else {
        // Handle success scenario
        setShowConfirmationPopup(true); // Show success message
        setApiStatus("Rejection successful!"); // Set success message
      }

      setIsLoading(false);
      setShowReject(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleSubmit = async () => {
    // event.preventDefault();
    const userProfileData = {
      id: AditionpermitIDFromURL ? AditionpermitIDFromURL : "0", //serverRecordId ? serverRecordId : "0",
      mobileNumber: mobileNumberfromURL,
      name: "",
      corePermitID: IdFromURL ? IdFromURL : 0,
      additionalPermitName: "crane_permit",
    };
    const finalJson = {
      request: {
        permitInitials,
        emergency,
        basicDetails,
        eqipmentInformation,
        important,
        liftConditions,
        note,
        permitIssuer,
        permitReceiver,
      },
      ...userProfileData,
    };
    console.log(finalJson, "finalJson***");
    setIsLoading(true);
    const response = await axios.post(saveAdditionalPermit, finalJson);
    console.log(response.status, "sdmnhba");
    console.log("response", response);
    setIsLoading(false);
    if (response.data.statusCode == 200) {
      setApiStatus(response.data.message);
      setShowApproved(true);
    }
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}
        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          {finalSubmit ? (
            <>
              <h2>Form Submitted Successfully!!!</h2>
            </>
          ) : (
            <div id="content">
              {/* 
                        <Header /> */}
              <div
                className="row"
                style={{ backgroundColor: "blue", padding: "7px" }}
              >
                <div className="col-md-3 col-12"></div>
              </div>
              <div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between"></div>
                  <div>
                    <form className="crane_permit-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        setPermitInitials={setPermitInitials}
                        // editable={true}
                        onDataChange={(data) => {
                          setPermitInitials(data);
                        }}
                      />
                      <Accordion
                        title="EMERGENCY"
                        bgColor="#DF3A1C"
                        content={
                          <Emergency
                            data={emergency}
                            setEmergency={setEmergency}
                            onDataChange={(data) => {
                              setEmergency(data);
                            }}
                          />
                        }
                        bordercolor="#DF3A1C"
                      />
                      <Accordion
                        title="BASIC DETAILS"
                        bgColor="#2C53A5"
                        content={
                          <BasicDetails
                            data={basicDetails}
                            setBasicDetails={setBasicDetails}
                            onDataChange={(data) => {
                              setBasicDetails(data);
                            }}
                          />
                        }
                        bordercolor="#2C53A5"
                      />
                      <Accordion
                        title="EQUIPMENT INFORMATION"
                        bgColor="#F58355"
                        content={
                          <Equipment
                            data={eqipmentInformation}
                            setEquipmentInf={setEquipmentInf}
                            onDataChange={(data) => {
                              setEquipmentInf(data);
                            }}
                          />
                        }
                        bordercolor="#F58355"
                      />
                      <Accordion
                        title="IMPORTANT"
                        bgColor="#2C53A5"
                        content={
                          <Important
                            data={important}
                            setImportant={setImportant}
                            onDataChange={(data) => {
                              setImportant(data);
                            }}
                          />
                        }
                        bordercolor="#2C53A5"
                      />
                      <Accordion
                        title="LIFT CONDITIONS"
                        bgColor="#5F92CD"
                        content={
                          <LiftConditions
                            data={liftConditions}
                            setLiftConditions={setLiftConditions}
                            onDataChange={(data) => {
                              setLiftConditions(data);
                            }}
                          />
                        }
                        bordercolor="#5F92CD"
                      />
                      <Accordion
                        title="NOTE"
                        bgColor="#2B2B2B"
                        content={
                          <Note
                            data={note}
                            setNote={setNote}
                            onDataChange={(data) => {
                              setNote(data);
                            }}
                          />
                        }
                        bordercolor="#2B2B2B"
                      />


                      <Accordion
                        title="Permit Issuer"
                        bgColor="#CB3A27"
                        content={
                          <PermitIssuer
                            data={permitIssuer}
                            setPermitIssuer={setPermitIssuer}
                            onDataChange={(data) => {
                              setPermitIssuer(data);
                            }}
                          />
                        }
                        bordercolor="#CB3A27"
                      />
                      <Accordion
                        title="Permit Receiver"
                        bgColor="#CB3A27"
                        content={
                          <PermitReceiver
                            data={permitReceiver}
                            setPermitReceiver={setPermitReceiver}
                            onDataChange={(data) => {
                              setPermitReceiver(data);
                            }}
                          />
                        }
                        bordercolor="#CB3A27"
                      />

                      <div style={{ marginTop: "20px" }}>

                        {permitStatus !== "" && (
                          <button
                            type="button"
                            onClick={() => {
                              handleApproved(permitStatus);
                            }}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#007AFF",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            {permitStatus == "Submitted" ? "Approve" : "Update"}
                          </button>
                        )}
                        {permitStatus == "Submitted" && (
                          <button
                            type="button"
                            onClick={handleReject}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#FC2727",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            Reject
                          </button>
                        )}
                      </div>
                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Approved}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>{apiStatus}</h5>
                                  {/* Crane Permit approved successfully */}
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      height: "43px",
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={handleAprrovedOk}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </Modal.Body>

                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Reject}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>Are you sure want to Reject?</h5>
                                </p>
                              </div>

                            </div>

                            <div className="form-group">
                              <label htmlFor="name">
                                <h6>Reason:</h6>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </div>

                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "8vh",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                  style={{
                                    height: "43px",
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={handleRejectOk}
                                  disabled={remarks.trim() === ""}
                                >
                                  Ok
                                </Button>
                              </p>
                            </div>

                            <Modal
                              show={showNewModal}
                              onHide={() => setShowNewModal(false)}
                              size="sm"
                              id="newModal"
                              className="ppopup"
                            >
                              <Modal.Body>
                                <div className="row">
                                  <div>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "15vh",
                                      }}
                                    >
                                      <img
                                        src={Approved}
                                        alt="save"
                                        className="popupicons"
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "3vh",
                                      }}
                                    >
                                      <h5>CranePermit Rejected Successfully</h5>
                                    </p>
                                  </div>

                                </div>

                                <Button
                                  variant="primary"
                                  onClick={handleNewModalOk}
                                >
                                  Ok
                                </Button>
                              </Modal.Body>
                            </Modal>
                          </Modal.Body>

                        </Modal>

                        {/* Confirmation Popup */}
                        <Modal
                          show={showConfirmationPopup}
                          onHide={() => setShowConfirmationPopup(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <span
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "13vh",
                                }}
                              >
                                <img
                                  src={Reject}
                                  alt="save"
                                  className="popupicons"
                                />
                              </span>
                            </div>

                            <div className="form-group">
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                  color: "green",
                                }}
                              >
                                <h5>CranePermit Rejected Successfully</h5>
                              </p>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary"
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                style={{
                                  height: "43px",
                                  backgroundColor: "#007AFF",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={handleConfirmationOk}
                              >
                                Ok
                              </Button>
                            </div>

                          </Modal.Body>
                        </Modal>
                      </div>
                    </form>
                    {/* </div>
                                            </div> */}
                    {/* </div>
                                    </div> */}
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          )}
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div>
      {/* {isLoading && <Loader loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />} */}
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CranePermit2Additional;
